export const NavigationTheme = {
  dark: false,
  colors: {
    primary: '#FFFC10',
    background: '#FFFFFF',
    card: '#FFFFFF',
    text: '#000000',
    border: 'transparent',
    notification: 'rgb(255, 69, 58)',
  },
}
