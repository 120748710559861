import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'

import { VerificationForm } from '~components'

const enterPhone = `podaj numer telefonu
aby otrzymac kod 
weryfikacyjny
`

export const FormVerificationScreen = () => {
  const { navigate } = useNavigation()
  const goToEnterVerificationCode = useCallback(() => {
    navigate('EnterVerificationCode')
  }, [navigate])

  return (
    <VerificationForm
      description={enterPhone}
      placeholder="Wpisz nr telefonu"
      textButton="wyślij kod"
      onSubmit={goToEnterVerificationCode}
    />
  )
}
