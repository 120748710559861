import React, { FunctionComponent } from 'react'
import { Image, View, LayoutAnimation } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { isWeb } from '~helpers'

import { Pressable } from './Pressable'
import { Text } from './Text'

type CheckboxProps = {
  style?: string[]
  onPressElement: () => void
  label: string
  index: number
  checkedElements: number[]
}
export const Checkbox: FunctionComponent<CheckboxProps> = ({
  style = [],
  onPressElement,
  label,
  index,
  checkedElements,
}) => {
  const handleAnimation = () => {
    onPressElement()
    LayoutAnimation.configureNext({
      duration: 150,
      create: { type: 'linear', property: 'opacity', delay: 20 },
      update: { type: 'spring', springDamping: 0.5 },
      delete: { type: 'linear', property: 'opacity' },
    })
  }
  return (
    <Pressable
      onPress={handleAnimation}
      style={[{ flexDirection: 'row', alignItems: 'center' }, tw.mB4]}
    >
      <View
        style={[
          { paddingVertical: 15 },
          ...styles.container,
          !checkedElements.includes(index) ? tw.bgMain : tw.bgBlack,
          ...style,
        ]}
      >
        <Text variant="small" style={[checkedElements.includes(index) && tw.textWhite]}>
          {label}
        </Text>
      </View>
    </Pressable>
  )
}

const styles = {
  container: [
    tw.roundedLg,
    tw.flexRow,
    tw.flexRow,
    { borderRadius: 10 },
    { height: 60 },
    tw.justifyCenter,
    tw.itemsCenter,
    tw.pX5,
    { flex: 1 },
  ],
}
