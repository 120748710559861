import React from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { LabelAndValue, Text, Button } from '~components'

const data = [
  {
    label: 'telefon:',
    value: '+48 123 456 789',
    isEditIcon: true,
  },
  {
    label: 'adres e-mail:',
    value: 'adre.email@gmail.com',
    isEditIcon: true,
  },
  {
    label: 'data rejestracji:',
    value: '21.09.2021',
  },
]

export const AccountMainScreen = () => {
  return (
    <View style={styles.mainContainer}>
      <View style={[tw.flex1]}>
        <Text variant="button" style={[]}>
          Moje dane:
        </Text>
        {data?.map(item => {
          return (
            <LabelAndValue
              key={item.label}
              label={item.label}
              value={item.value}
              isEditIcon={item.isEditIcon}
            />
          )
        })}
        <Button
          label="Wyloguj się"
          onPress={() => {}}
          big
          full
          style={[tw.mT12, { borderRadius: 4 }]}
        />
      </View>
      <View style={[tw.pX3]} />
      <View style={[tw.flex1]}>
        <Text variant="button">ustawienia:</Text>
      </View>
    </View>
  )
}

const styles = {
  mainContainer: [tw.flexRow, tw.flex1, tw.pX8, tw.pT24],
}
