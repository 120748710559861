import { NavigationContainer } from '@react-navigation/native'
import * as React from 'react'

import { NavigationService } from '~services'

import { LinkingConfiguration } from './LinkingConfiguration'
import { NavigationTheme } from './NavigationTheme'
import { RootStack } from './RootStack'

export * from './types'
export * from './RouteParamsMap'

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export function Navigation() {
  const navigationRef = NavigationService.getTopLevelNavigatorRef()
  return (
    <NavigationContainer linking={LinkingConfiguration} theme={NavigationTheme} ref={navigationRef}>
      <RootStack />
    </NavigationContainer>
  )
}
