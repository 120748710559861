import React, { FunctionComponent } from 'react'
import { PressableProps, Dimensions } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { isWeb } from '~helpers'

import { Pressable } from './Pressable'
import { Text, Variant } from './Text'

const { width, height } = Dimensions.get('window')

type BigCircleButtonProps = PressableProps & {
  label: string
  style?: Array<any>
  variant?: Variant
}

export const BigCircleButton: FunctionComponent<BigCircleButtonProps> = ({
  label,
  style = [],
  variant = 'button',
  ...rest
}) => {
  return (
    <Pressable style={[...styles.container, ...style]} {...rest}>
      <Text style={styles.text} variant={variant}>
        {label}
      </Text>
    </Pressable>
  )
}

// 12
const styles = {
  container: [
    {
      borderWidth: 10,
      width: isWeb ? 300 : (width * 60) / 100,
      height: isWeb ? 300 : (width * 60) / 100,
    },
    tw.borderWhite,
    tw.bgMain,
    tw.roundedFull,
    tw.justifyCenter,
    tw.itemsCenter,
    tw.pX5,
  ],
  text: [tw.textCenter],
}
