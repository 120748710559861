import { openURL } from 'expo-linking'
import React, { useCallback } from 'react'
import { View, Modal, Dimensions } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { useDemoApp } from '~hooks'

import { Button } from './Button'
import { Text } from './Text'

export const DemoModal = () => {
  const os = useDemoApp()
  const onPress = useCallback(() => {
    if (os === 'Android') {
      openURL('https://appforshare.io/i/AcuI41')
    } else if (os === 'iOS') {
      openURL('https://animalhelper.pl')
    }
  }, [os])

  const renderAndroid = () => {
    return (
      <>
        <View style={styles.spacer}>
          <Text variant="small">Kliknij tutaj, aby pobrac aplikacje</Text>
        </View>
        <View style={styles.spacer}>
          <Button label={'Pobierz aplikacje'} full onPress={onPress} />
        </View>
      </>
    )
  }

  const renderIOS = () => {
    return (
      <Text variant="small" style={[tw.mT4]}>
        Bardzo nam przykro, ale aplikacja nie jest jeszcze dostępna na telefonach z systemem
        operacyjnym iOS. Jeżeli chcesz zobaczyć, jak działa aplikacja na tym systemie, podejdź do
        naszego stoiska.
      </Text>
    )
  }

  if (os === 'Other' || !os) {
    return null
  }

  return (
    <Modal
      visible={true}
      style={[
        {
          ...Dimensions.get('window'),
        },
        tw.justifyCenter,
        tw.itemsCenter,
        tw.border0,
      ]}
    >
      <View style={styles.container}>
        <Text>Wykryliśmy, ze korzystasz z platformy: {os}</Text>
        {os === 'Android' ? renderAndroid() : renderIOS()}
      </View>
    </Modal>
  )
}

const styles = {
  container: [tw.m4],
  spacer: [{ marginTop: 10 }],
}
