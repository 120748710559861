import { StackHeaderProps } from '@react-navigation/stack'
import React, { FC } from 'react'
import { Image, SafeAreaView, View, Platform } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { isSmall, isWeb } from '~helpers'

import { CircleButton } from './CircleButton'
import { Pressable } from './Pressable'
import { Text } from './Text'

const headerHeight = 80

type IconName = 'account' | 'history' | 'info' | 'raiseIssue'

type HeaderProps = StackHeaderProps & {
  iconName: IconName
}

type BackProps = { onPress: () => void }

const Back: FC<BackProps> = ({ onPress }) => {
  return (
    <Pressable onPress={onPress} style={[...styles.back, isSmall ? tw.mR3 : tw.mR4]}>
      <Image
        source={require('~assets/icons/back.png')}
        style={[isWeb && tw.w8, isWeb && tw.h6, { tintColor: 'black' }]}
      />
    </Pressable>
  )
}

export const Header = (props: HeaderProps) => {
  const title = props?.scene?.descriptor?.options?.title
  const name = props?.scene?.route?.name
  const headerRight = props?.scene?.descriptor?.options?.headerRight
  const previous = props?.previous ?? null
  const goBack = props?.navigation?.goBack

  return (
    <SafeAreaView>
      <View style={styles.container}>
        {previous ? (
          <Back onPress={goBack} />
        ) : (
          <CircleButton style={[isSmall ? tw.mR3 : tw.mR4]} iconName={props.iconName} />
        )}
        <Text style={styles.header} variant="header">
          {title || name}
        </Text>
        {headerRight?.({ tintColor: undefined })}
      </View>
    </SafeAreaView>
  )
}

const styles = {
  container: [
    { marginTop: Platform.OS === 'android' ? 40 : 0, height: headerHeight },
    Platform.OS === 'android' ? tw.pX3 : tw.pX4,
    tw.flexRow,
    tw.itemsCenter,
    tw.bgWhite,
  ],
  header: [tw.flex1, tw.flexWrap, { lineHeight: headerHeight / 2 }],
  back: [{ width: 54, height: 54 }, tw.bgMain, tw.roundedFull, tw.justifyCenter, tw.itemsCenter],
}
