import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SafeAreaView, View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { ScrollView, Input, DetailsForm, Button } from '~components'
import { isWeb } from '~helpers'

export const SummaryInformationScreen = ({ route }) => {
  const { navigate } = useNavigation()
  const [animalType, setAnimalType] = useState('')
  const [quantity, setQuantity] = useState('')
  const [description, setDescription] = useState('')
  const [isClickable, setIsClickable] = useState(true)
  const routeName = route.name
  const condition = routeName === 'EnterInformation'

  useEffect(() => {
    if (routeName === 'HistorySummaryInformation') {
      setAnimalType('pies')
      setQuantity('1')
      setDescription('Zgłaszający podczas jazdy potrącił psa.')
    }
  }, [])

  const goToSummary = useCallback(() => {
    navigate('Summary')
  }, [navigate])

  useEffect(() => {
    if (animalType.length > 0 && quantity.length > 0 && description.length > 0) {
      setIsClickable(false)
    } else {
      setIsClickable(true)
    }
  }, [animalType, quantity, description])

  const renderButtons = useMemo(() => {
    if (condition) {
      return (
        <View style={[tw.mB4]}>
          <Button label="dalej" style={[tw.mB4]} onPress={goToSummary} disabled={isClickable} />
        </View>
      )
    }
  }, [condition, isClickable])

  return (
    <SafeAreaView style={[tw.flex1, tw.mX4]}>
      {isWeb ? (
        <DetailsForm />
      ) : (
        <>
          <ScrollView contentContainerStyle={styles.contentContainerStyle}>
            <Input
              placeholder={'wpisz gatunek'}
              style={[tw.mB4]}
              value={animalType}
              onChangeText={setAnimalType}
              editable={!condition ? false : true}
            />
            <Input
              placeholder={'liczba zwierząt'}
              keyboardType="number-pad"
              style={[tw.mB4]}
              value={quantity}
              onChangeText={setQuantity}
              editable={!condition ? false : true}
            />
            <Input
              placeholder={'opis zdarzenia'}
              multiline
              style={[tw.mB4, ...styles.heightInput]}
              textAlignVertical="top"
              value={description}
              onChangeText={setDescription}
              editable={!condition ? false : true}
            />
          </ScrollView>
          {renderButtons}
        </>
      )}
    </SafeAreaView>
  )
}

const styles = {
  contentContainerStyle: [tw.itemsCenter, tw.pY8],
  heightInput: [{ height: 165 }],
}
