export enum AbuseTypes {
  BEATING = 'bicie',
  STARVATION_OR_DEHYTRATION = 'głodzenie lub odwodnienie',
  MUTILATION = 'okalecznie',
  DEAD_ANIMAL = 'zabite zwierze',
  UNTREATED_DESEASE = 'nieleczona choroba',
  POOR_LIVING_CONDITIONS = 'złe warunki bytowe',
  ABANDONMENT = 'porzucenie',
  OTHER = 'inne',
}
