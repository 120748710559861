import React, { createContext, useState, useContext } from 'react'

const PhotosContext = createContext({})

export const PhotosProvider: React.FC = ({ children }) => {
  const [photos, setPhotos] = useState<Array<string>>([])

  const onAddPhoto = (newPhoto: string) => {
    setPhotos([...photos, newPhoto])
  }
  const onDeletePhoto = (deletePhoto: any) => {
    setPhotos(photos.filter(item => deletePhoto !== item))
  }

  return (
    <PhotosContext.Provider value={{ photos, onAddPhoto, onDeletePhoto }}>
      {children}
    </PhotosContext.Provider>
  )
}

export const usePhotos = () => useContext(PhotosContext)
