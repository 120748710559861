import React, { FunctionComponent } from 'react'
import { Image, View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { isSmall } from '~helpers'

import { Pressable } from './Pressable'

type CardProps = {
  style?: string[]
  onPress?: () => void
}

export const Card: FunctionComponent<CardProps> = ({ style = [], onPress, children }) => {
  return (
    <Pressable style={[...styles.container, ...style]} onPress={onPress}>
      <View style={styles.childContainer}>{children}</View>
      {onPress !== undefined && (
        <View style={styles.arrow}>
          <Image source={require('~assets/icons/forward.png')} resizeMode="contain" />
        </View>
      )}
    </Pressable>
  )
}

const styles = {
  container: [tw.bgGray, tw.roundedLg, tw.flexRow],
  childContainer: [tw.flex1],
  arrow: [isSmall ? tw.w12 : tw.w16, tw.justifyCenter, tw.itemsCenter, tw.roundedRLg, tw.bgMain],
}
