import React, { forwardRef } from 'react'
import { TextInput, TextInputProps } from 'react-native'
import { tw, colors } from 'react-native-tailwindcss'

type InputProps = TextInputProps & {
  style?: string[]
}

export const Input = forwardRef<TextInput, InputProps>(({ style = [], ...props }, ref) => {
  return (
    <TextInput
      ref={ref}
      {...props}
      placeholderTextColor="#808080"
      selectionColor={colors.main}
      style={[...styles.input, ...style]}
    />
  )
})

const styles = {
  input: [
    tw.pX3,
    tw.textBlack,
    { backgroundColor: '#E5E5E5', borderRadius: 10 },
    tw.wFull,
    tw.fontDisplay,
    tw.textXl,
    tw.textBlack,
    { height: 60, lineHeight: 30 },
  ],
}
