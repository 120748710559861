import React, { FC } from 'react'
import { Image, View } from 'react-native'
import { Button, Text } from '~components'
import { tw } from 'react-native-tailwindcss'

const accidentTypeText = `Zgłoszenie przez formularz 
01.01.2022
`

const localizationText = `Miejscowość
ul. Nazwa ulicy 55
02-000
`

type TextBoxProps = {
  title: string
  text: string
  style?: Array<any>
  isBig?: boolean
}

const TextBox: FC<TextBoxProps> = ({ title, text, style = [], isBig }) => {
  return (
    <View style={[isBig && tw.flex1]}>
      <Text variant="small" style={[tw.pB3, tw.pT2]}>
        {title}:
      </Text>
      <View style={[...styles.greyContainer, ...style]}>
        <Text variant="small">{text}</Text>
      </View>
    </View>
  )
}

export const ReportSummaryScreen = () => {
  return (
    <View style={styles.mainContainer}>
      <Text style={[tw.pB4]}>podsumowanie zgłoszenia</Text>
      <View style={styles.greyContainer}>
        <Text variant="small">{accidentTypeText}</Text>
      </View>

      <View style={styles.container}>
        <View style={styles.leftSide}>
          <View style={[tw.w1_2]}>
            <Text variant="small" style={[tw.pT2]}>
              Zdjęcia:
            </Text>
            <View style={styles.imagesContainer}>
              <Image source={require('~assets/images/examplePhoto1.png')} style={styles.image} />
              <Image source={require('~assets/images/examplePhoto1.png')} style={styles.image} />
              <Image source={require('~assets/images/examplePhoto1.png')} style={styles.image} />
              <Image source={require('~assets/images/examplePhoto1.png')} style={styles.image} />
            </View>
          </View>
          <View style={[tw.w1_2, tw.pL3]}>
            <TextBox title="Lokalizacja" text={localizationText} style={[tw.h32]} />
            <TextBox title="Rodzaj zdarzenia" text="Znęcanie się" />
            <TextBox
              title="Szczegóły"
              text="bicie,okaleczanie, głodzenie"
              isBig
              style={[tw.flex1]}
            />
          </View>
        </View>

        <View style={styles.rightSide}>
          <View style={styles.wrapper}>
            <View style={{ width: '48%' }}>
              <TextBox title="Gatunek" text="Gatunek" />
            </View>
            <View style={{ width: '48%' }}>
              <TextBox title="Liczba zwierząt" text="2" />
            </View>
          </View>

          <TextBox
            title="Opis"
            text="Opis zdarzenia wprowadzony przez użytkownika aplikacji."
            style={[tw.flex1]}
            isBig
          />
        </View>
      </View>

      <Button label="Zgłoś" onPress={() => {}} style={[tw.bgLink, tw.mT5]} secondary />
    </View>
  )
}

const styles = {
  mainContainer: [tw.pX6, tw.pT10, tw.flex1, tw.pB5],
  container: [tw.flexRow, tw.flex1],
  greyContainer: [tw.bgGray, tw.p2, { borderRadius: 4 }],
  leftSide: [tw.w1_2, tw.flexRow],
  rightSide: [tw.w1_2, tw.flex1, tw.pL3],
  imagesContainer: [tw.flexRow, tw.justifyBetween, tw.flexWrap],
  image: { width: 126, height: 126, borderRadius: 4, marginTop: 12 },
  wrapper: [tw.flexRow, tw.justifyBetween],
}
