import { Configuration } from "./generated-api";
import {
    AdminApiImplApiFactory,
    AnonymousApiImplApiFactory,
    AuthApiImplApiFactory, AuthenticatedApiImplApiFactory, FetchAPI,
    IncidentAnonymousApiImplApiFactory,
    IncidentOperatorApiImplApiFactory, IncidentSuperOperatorApiImplApiFactory, IncidentUserApiImplApiFactory, LoginResp, SuperOperatorApiImplApiFactory
} from "./generated-api/api";

export const APIKeyHolder: { authData?: LoginResp } = {
    authData: undefined,
}

const apiConfig: Configuration = {
    apiKey: (name: string) => APIKeyHolder.authData?.token
        ? `Bearer ${APIKeyHolder.authData?.token}`
        : undefined as any,
    debug: { apiDelay: 0 }
}



const ServerApi = (fetch?: FetchAPI, basePath?: string) => {
    return {
        anonymousApi: AnonymousApiImplApiFactory(apiConfig, fetch, basePath),
        authApi: AuthApiImplApiFactory(undefined, fetch, basePath),
        authenticatedApi: AuthenticatedApiImplApiFactory(apiConfig, fetch, basePath),
        incidentAnonymousApi: IncidentAnonymousApiImplApiFactory(apiConfig, fetch, basePath),
        incidentOperatorApi: IncidentOperatorApiImplApiFactory(apiConfig, fetch, basePath),
        incidentSuperOperatorApi: IncidentSuperOperatorApiImplApiFactory(apiConfig, fetch, basePath),
        incidentUserApi: IncidentUserApiImplApiFactory(apiConfig, fetch, basePath),
        superOperatorApi: SuperOperatorApiImplApiFactory(apiConfig, fetch, basePath),
        adminApi: AdminApiImplApiFactory(apiConfig, fetch, basePath),
    }
}

export default ServerApi;
