import React from 'react'
import { Image, TextInput, View } from 'react-native'
import { tw, colors } from 'react-native-tailwindcss'
import { isWeb } from '~helpers'

import { Text } from './Text'

export const LabelAndValue = (props: { label: string; value: string; isEditIcon?: boolean }) => {
  return (
    <View>
      {isWeb ? (
        <Text variant="button" style={[tw.mB3, tw.mT4, tw.textLg]}>
          {props.label}
        </Text>
      ) : (
        <Text variant="small" style={[tw.mB1]} color="darkerGray">
          {props.label}
        </Text>
      )}
      {isWeb ? (
        <View style={[tw.flexRow]}>
          <TextInput
            placeholderTextColor="#808080"
            selectionColor={colors.main}
            style={[...styles.input]}
          ></TextInput>
          {props.isEditIcon && (
            <View style={styles.imageContainer}>
              <Image source={require('~assets/icons/pen.png')} style={styles.image} />
            </View>
          )}
        </View>
      ) : (
        <Text variant="text" style={[tw.mB6]}>
          {props.value}
        </Text>
      )}
    </View>
  )
}

const styles = {
  input: [
    tw.pX3,
    tw.textBlack,
    { backgroundColor: '#E6E6E6', borderRadius: 4 },
    tw.wFull,
    tw.fontDisplay,
    tw.textXl,
    tw.textBlack,
    { height: 48, lineHeight: 30 },
  ],
  imageContainer: [
    tw.bgMain,
    tw.justifyCenter,
    tw.itemsCenter,
    tw.p3,
    { width: 48, height: 48, borderRadius: 4 },
    tw.absolute,
    tw.right0,
  ],
  image: [{ width: 30, height: 30 }],
}
