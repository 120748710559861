import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, CallButton, Text } from '~components'

export const MakeCallOtherScreen = () => {
  const { navigate } = useNavigation()
  const goToFeedback = useCallback(() => navigate('Feedback'), [navigate])

  return (
    <View style={styles.container}>
      <View style={styles.containerBox}>
        <Text variant="big" style={[tw.mB4]}>
          Zadzwoń do innej instytucji
        </Text>
        <Text variant="small">
          Instytucje publiczne bądź organizacje charytatywne właściwe dla Twojego regionu.
        </Text>
        <View style={[tw.mT10, tw.w1_2]}>
          <CallButton
            style={[tw.mB4]}
            nameInstitution="OTOZ ANIMALS"
            phoneNumber="+48 541 244 422"
            onPress={goToFeedback}
          />
          <CallButton
            style={[tw.mB4]}
            nameInstitution="POLICJA"
            phoneNumber="+48 541 244 422"
            onPress={() => null}
          />
          <CallButton
            nameInstitution="STRAŻ GMINNA"
            phoneNumber="+48 541 244 422"
            onPress={() => null}
          />
        </View>
      </View>
    </View>
  )
}

const styles = {
  container: [tw.itemsCenter, tw.justifyCenter, tw.flex1],
  containerBox: [tw.w8_12, tw.itemsCenter],
  space: [tw.mB4],
}
