import React, { FunctionComponent } from 'react'
import { PressableProps, Image, ColorValue } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { isWeb } from '~helpers'

import { Pressable } from './Pressable'

type IconName =
  | 'account'
  | 'history'
  | 'info'
  | 'raiseIssue'
  | 'check'
  | 'phone'
  | 'arrowsRight'
  | 'arrowsLeft'
  | 'close'

type CircleButtonProps = PressableProps & {
  iconName: IconName
  tintColor?: ColorValue | undefined
  style?: Array<any>
}

const icons = {
  account: require('~assets/icons/account.png'),
  history: require('~assets/icons/history.png'),
  info: require('~assets/icons/info.png'),
  raiseIssue: require('~assets/icons/raiseIssue.png'),
  check: require('~assets/icons/check.png'),
  phone: require('~assets/icons/phone.png'),
  arrowsRight: require('~assets/icons/arrowsRight.png'),
  arrowsLeft: require('~assets/icons/arrowsLeft.png'),
  close: require('~assets/icons/close.png'),
}

export const CircleButton: FunctionComponent<CircleButtonProps> = ({
  iconName = 'account',
  style = [],
  tintColor = 'white',
  ...rest
}) => {
  const source = icons[iconName]

  return (
    <Pressable style={[...styles.container, ...style]} {...rest}>
      <Image
        source={source}
        style={[{ tintColor }, isWeb && [tw.w6, tw.h6]]}
        resizeMode={isWeb ? 'contain' : 'cover'}
      />
    </Pressable>
  )
}

// 12
const styles = {
  container: [
    { width: 54, height: 54 },
    tw.bgBlack,
    tw.roundedFull,
    tw.justifyCenter,
    tw.itemsCenter,
  ],
}
