import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import { Header } from '~components'
import { isWeb } from '~helpers'
import {
  SummaryInformationScreen,
  Step2Screen,
  Step3Screen,
  ChooseIncidentsScreen,
  SummaryGalleryScreen,
  ResultInspectionScreen,
  RaiseIssueSummaryScreen,
} from '~screens'

import { RouteParamsMap } from './RouteParamsMap'
import { NavigatorParams, Routes } from './types'
import { HistoryStackRoutes } from './HistoryStack'

type SummaryStackRoutes =
  | HistoryStackRoutes
  | Routes.RaiseIssueSummary
  | Routes.SummaryGallery
  | Routes.SummaryKindIncident
  | Routes.SummaryInformation
  | Routes.SummaryLocation

type SummaryStackParamsMap = Pick<RouteParamsMap, SummaryStackRoutes>
export type SummaryStackNavParams = NavigatorParams<SummaryStackParamsMap, SummaryStackRoutes>

const Stack = createStackNavigator<SummaryStackParamsMap>()

export const SummaryStack = () => {
  return (
    <Stack.Navigator
      initialRouteName={Routes.RaiseIssueSummary}
      screenOptions={{
        header: props => <Header {...props} iconName="raiseIssue" />,
      }}
    >
      <Stack.Screen
        name={Routes.RaiseIssueSummary}
        component={RaiseIssueSummaryScreen}
        options={{
          title: 'podsumowanie zgłoszenia',
        }}
      />
      <Stack.Screen
        name={Routes.SummaryLocation}
        component={Step2Screen}
        options={{
          title: 'lokalizacja zdarzenia',
        }}
      />
      <Stack.Screen
        name={Routes.SummaryKindIncident}
        component={Step3Screen}
        options={{
          title: 'Wybierz rodzaj zdarzenia',
        }}
      />
      <Stack.Screen
        name={Routes.SummaryInformation}
        component={SummaryInformationScreen}
        options={{
          title: 'wpisz informacje',
          headerShown: !isWeb,
        }}
      />
      <Stack.Screen
        name={Routes.HistoryChooseIncident}
        component={ChooseIncidentsScreen}
        options={{
          title: 'Zaznacz rodzaj zdarzenia',
        }}
      />
      <Stack.Screen
        name={Routes.SummaryGallery}
        component={SummaryGalleryScreen}
        options={{
          title: 'zdjęcia i filmy',
          headerShown: !isWeb,
        }}
      />
      <Stack.Screen
        name={Routes.HistoryResultInspection}
        component={ResultInspectionScreen}
        options={{
          title: 'wynik inspekcji',
        }}
      />
    </Stack.Navigator>
  )
}
