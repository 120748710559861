import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useRef, MutableRefObject } from 'react'
import { View, TextInput } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, Field, ScrollView, Text } from '~components'
import { isWeb, isAlert } from '~helpers'
import { useSignInForm } from '~hooks'

export const SignInScreen = () => {
  const { control, submit, formState } = useSignInForm()
  const emailOrPhoneRef = useRef<TextInput>() as MutableRefObject<TextInput>
  const passwordRef = useRef<TextInput>() as MutableRefObject<TextInput>
  const { navigate } = useNavigation()
  const goToSignUpScreen = useCallback(() => navigate('AccountSignUp'), [navigate])
  //TODO: delete this navigation later
  const goToProfileScreen = useCallback(() => navigate('AccountMain'), [navigate])
  const onLoginButton = (app: string) => {
    return isAlert(
      `Przechodzisz do logowania przez aplikacje ${app} , brak tej mozliwosci w wersji pokazowej.`,
    )
  }

  const content = useCallback(() => {
    return (
      <>
        <Field.EmailOrPhone
          control={control}
          name={'username'}
          returnKeyType="next"
          ref={emailOrPhoneRef}
          onSubmitEditing={() => passwordRef?.current?.focus()}
        />
        <Field.Password
          control={control}
          name={'password'}
          returnKeyType="done"
          ref={passwordRef}
          onSubmitEditing={submit}
        />
        <Button label="zaloguj się" full onPress={submit} disabled={!formState.isValid} />
        <View style={styles.textContainer}>
          <Text variant="small">lub</Text>
        </View>
        <Button.Social
          typeSocial="facebook"
          style={styles.space}
          onPress={() => onLoginButton('Facebook')}
        />
        <Button.Social
          typeSocial="google"
          style={styles.space}
          onPress={() => onLoginButton('Google')}
        />
        <Button.Social
          typeSocial="apple"
          style={styles.space}
          onPress={() => onLoginButton('Apple')}
        />
        {!isWeb && (
          <View style={styles.textContainer}>
            <Text variant="small" style={[tw.textCenter]}>
              Nie masz jeszcze konta?{'\n'}
              <Text variant="small" style={[tw.textLink]} onPress={goToSignUpScreen}>
                Zarejestruj się!
              </Text>
            </Text>
          </View>
        )}
      </>
    )
  }, [])
  return isWeb ? <View>{content()}</View> : <ScrollView>{content()}</ScrollView>
}

const styles = {
  space: [tw.mB4],
  textContainer: [tw.mY5, tw.itemsCenter],
}
