import { useNavigation } from '@react-navigation/native'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, Checkbox, Input, ScrollView, Stepper, Text } from '~components'

const incidents = [
  'bicie',
  'głodzenie lub odwodnienie',
  'okaleczenie',
  'zabite zwierze',
  'nieleczona choroba',
  'złe warunki bytowe',
  'porzucenie',
  'inne',
]

const accidentType = [
  { accident: 'zdarzenie drogowe' },
  { bullying: 'znęcanie się' },
  { homeless: 'bezpańskie zwierze' },
  { other: 'inne' },
]

type FormProps = {
  isBullying?: boolean
}

const Form: FC<FormProps> = ({ isBullying }) => {
  return (
    <View style={[tw.flexRow, tw.pX4, tw.mT4, tw.flex1]}>
      <View style={[isBullying ? tw.w1_2 : tw.w1_3]}>
        <Text style={[tw.pB4]}>Wpisz liczbę zwierząt</Text>
        <Input />
        <Text style={[tw.pY4]}>Wpisz gatunek zwierzęcia</Text>
        <Input />
      </View>
      <View style={[tw.mL4, isBullying ? tw.w1_2 : tw.w2_3]}>
        <Text style={[tw.pB4]}>Opis zdarzenia</Text>
        <Input style={[tw.h64]} />
      </View>
    </View>
  )
}

export const Step3Screen = () => {
  const { navigate } = useNavigation()
  const [checkedElements, setCheckedElements] = useState([] as number[])
  const [formState, setFormState] = useState('')
  const goToSummary = useCallback(() => navigate('Summary'), [navigate])

  function checkListElement(index: number): void {
    const joined = checkedElements.concat(index)
    const removed = checkedElements.filter((el: number) => el !== index)
    if (checkedElements.includes(index)) {
      setCheckedElements(removed)
    } else {
      setCheckedElements(joined)
    }
  }

  const switchStates = useCallback(() => {
    switch (formState) {
      case 'accident':
        return <Form />
      case 'bullying':
        return checkedElements.length > 0 && <Form isBullying />
      case 'homeless':
        return <Form />
      case 'other':
        return <Form />
      default:
        console.log('error')
    }
  }, [formState, checkedElements])

  const onHandleChangeState = (accidentType: string) => {
    setFormState(accidentType)
  }

  const renderAccidentButtons = useMemo(() => {
    return accidentType.map(item => {
      for (const [key, value] of Object.entries(item)) {
        return (
          <Button
            full
            style={[tw.mB4, tw.textCenter, formState === key ? tw.bgBlack : tw.bgMain]}
            secondary={formState === key ? true : false}
            label={value}
            onPress={() => onHandleChangeState(key)}
          />
        )
      }
    })
  }, [formState])

  return (
    <>
      <Stepper activeIndex={2} />
      <ScrollView contentContainerStyle={styles.contentContainerStyle} style={[tw.mB4]}>
        <View style={[tw.w1_4]}>
          <Text style={[tw.mY4]}>Wybierz rodzaj zdarzenia</Text>
          {renderAccidentButtons}
        </View>
        {formState === 'bullying' && (
          <View style={[tw.w1_4, tw.mL4]}>
            <Text style={[tw.mY4]}>Wybierz szczegóły zdarzenia</Text>
            {incidents.map((incident, index) => {
              return (
                <Checkbox
                  label={incident}
                  index={index}
                  checkedElements={checkedElements}
                  onPressElement={() => checkListElement(index)}
                  style={[tw.textCenter]}
                />
              )
            })}
          </View>
        )}
        {switchStates()}
      </ScrollView>
      <View style={styles.bottomButtons}>
        <Button label="pomiń" style={[tw.bgGray, tw.flex1]} onPress={() => goToSummary()} />
        <View style={[tw.mX1]} />
        <Button label="dalej" style={[tw.bgMain, tw.flex1]} onPress={() => goToSummary()} />
      </View>
    </>
  )
}

const styles = {
  contentContainerStyle: [tw.flex1, tw.pX4, tw.flexRow],
  bottomButtons: [tw.mY6, tw.flexRow, tw.wFull, tw.pX4],
}
