import { useNavigation } from '@react-navigation/native'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Image, TouchableOpacity, View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, Stepper, Text } from '~components'

type ButtonProps = {
  onPress: () => void
}

const AddButton: FC<ButtonProps> = ({ onPress }) => {
  return (
    <TouchableOpacity style={[tw.bgMain, styles.button, styles.boxSize]} onPress={onPress}>
      <Image source={require('~assets/icons/raiseIssue.png')} style={styles.plus} />
      <Text variant="small" style={styles.text}>
        dodaj zdjęcia lub filmy do zgłoszenia
      </Text>
    </TouchableOpacity>
  )
}

export const Step1Screen = ({ route }) => {
  const capturedPhoto = route.params?.capturedPhoto
  const fileObj: Array<string> = []
  const fileArray: Array<any> = []
  const [gallery, setGallery] = useState<Array<never[]>>([])
  const [isClicked, setIsClicked] = useState(false)
  const { navigate } = useNavigation()
  const goToCameraScreen = useCallback(() => navigate('TakePhoto'), [navigate])
  const [images, setImages] = useState<Array<string>>([])
  const goToStep2 = useCallback(() => navigate('Step2'), [navigate])

  const uploadMultipleFiles = e => {
    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0]?.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
    }
    setGallery([...gallery, ...fileArray])
  }

  useEffect(() => {
    if (capturedPhoto) {
      setImages([...images, capturedPhoto])
    }
    setIsClicked(false)
  }, [capturedPhoto, gallery])

  const generateOptions = useCallback(() => {
    if (isClicked) {
      return (
        <View style={[styles.boxSize, tw.justifyBetween, tw.mR4]}>
          <Button label="aparat" big onPress={() => goToCameraScreen()} />
          <label>
            <input
              type="file"
              accept="image/*"
              name="name"
              multiple
              onChange={e => uploadMultipleFiles(e)}
              style={styles.input}
            />
            <Button label="galeria" big />
          </label>
        </View>
      )
    } else {
      return <AddButton onPress={() => setIsClicked(!isClicked)} />
    }
  }, [isClicked])

  return (
    <View style={styles.container}>
      <Stepper activeIndex={0} />
      <View style={styles.contentContainer}>
        <View style={[tw.flexRow]}>
          {images?.map(image => {
            return <Image source={{ uri: image }} style={[styles.boxSize, tw.mR4]} />
          })}
          {gallery?.map(url => (
            <Image source={{ uri: url }} style={[styles.boxSize, tw.mR4, { borderRadius: 4 }]} />
          ))}
        </View>
        {generateOptions()}
      </View>
      <View style={styles.bottomButtons}>
        <Button label="pomiń" style={[tw.bgGray, tw.flex1]} onPress={() => goToStep2()} />
        <View style={[tw.mX1]} />
        <Button label="dalej" style={[tw.bgMain, tw.flex1]} onPress={() => goToStep2()} />
      </View>
    </View>
  )
}

const styles = {
  container: [tw.flex1],
  contentContainer: [tw.mL5, tw.mT6, tw.flexRow, tw.flex1, tw.flexWrap],
  button: [
    tw.itemsCenter,
    tw.justifyCenter,
    { paddingTop: 55, paddingBottom: 32, borderRadius: 4 },
    tw.mR4,
  ],
  plus: {
    width: 32,
    height: 32,
    marginHorizontal: 76,
  },
  boxSize: { width: 180, height: 200 },
  text: [tw.mT8, tw.pX2, tw.textCenter],
  bottomButtons: [tw.pX5, tw.mY6, tw.flexRow, tw.wFull],
  input: {
    display: 'none',
  },
}
