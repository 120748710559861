import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'

import { VerificationForm } from '~components'

const enterCode = `wpisz otrzymany kod
aby potwierdzić
zgłoszenie
`

export const EnterCodeScreen = () => {
  const { navigate } = useNavigation()

  const goToFeedback = useCallback(() => {
    navigate('Feedback')
  }, [navigate])

  return (
    <VerificationForm
      description={enterCode}
      placeholder="Wpisz nr telefonu"
      textButton="dalej"
      onSubmit={goToFeedback}
    />
  )
}
