import React from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button } from './Button'
import { Text } from './Text'

interface IncidentSummaryTileProps {
  style?: any[]
  action?: any
  horizontal?: boolean
  textCentered?: boolean
  withBackground?: boolean
  tileTitle?: string
  buttonLabel?: string
}

export const IncidentSummaryTile: React.FunctionComponent<IncidentSummaryTileProps> = ({
  style = [],
  action,
  horizontal,
  textCentered = true,
  tileTitle,
  buttonLabel = 'Edytuj',
  withBackground = true,
  children,
}) => {
  return (
    <React.Fragment>
      {tileTitle ? (
        <Text variant="small" style={styles.label}>
          {tileTitle}
        </Text>
      ) : null}
      <View
        style={[
          styles.container,
          horizontal ? tw.flexRow : tw.flexCol,
          withBackground ? tw.bgGray : '',
          ...style,
        ]}
      >
        <View style={[tw.flex1, tw.alignCenter, textCentered ? tw.justifyCenter : '']}>
          {children}
        </View>
        {action ? (
          <Button
            onPress={action}
            label={buttonLabel}
            style={[tw.rounded, horizontal ? tw.hFull : tw.h16, horizontal ? tw.w24 : '']}
          />
        ) : null}
      </View>
    </React.Fragment>
  )
}

const styles = {
  container: [tw.rounded, tw.wFull],
  label: [tw.pY2],
}
