import React, { FunctionComponent } from 'react'
import { Image } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { Hoverable } from 'react-native-web-hooks'

import { Button, Pressable } from '~components'

interface ImageWrapperProps {
  id?: number | string
  action?: any
  actionLabel: string
  imageStyles?: string[]
}

export const ImageWrapper: FunctionComponent<ImageWrapperProps> = ({
  id,
  action,
  actionLabel,
  imageStyles = [],
}) => {
  return (
    <Hoverable>
      {isHovered =>
        action ? (
          <Pressable>
            <Image
              source={require('~assets/images/examplePhoto.png')}
              style={[tw.w48, tw.h48, ...imageStyles]}
              resizeMode={'cover'}
            />
            {isHovered || actionLabel === 'Edytuj' ? (
              <Button
                onPress={() => action(id)}
                label={actionLabel}
                style={[
                  tw.rounded,
                  tw.h16,
                  tw.absolute,
                  tw.bottom0,
                  tw.wFull,
                  isHovered ? styles.pointer : '',
                ]}
              />
            ) : null}
          </Pressable>
        ) : (
          <Image
            source={require('~assets/images/examplePhoto.png')}
            style={[tw.w48, tw.h48, ...imageStyles]}
            resizeMode={'cover'}
          />
        )
      }
    </Hoverable>
  )
}

const styles = {
  pointer: { cursor: 'pointer' },
}
