import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import { Header } from '~components'
import {
  FeedbackScreen,
  MakeCallOtherScreen,
  MissedCallScreen,
  RaiseIssueMainScreen,
  Step1Screen,
  Step2Screen,
  Step3Screen,
  TakePhotoScreen,
  HistoryAccess,
  ReportSummaryScreen,
} from '~screens'

import { RouteParamsMap } from './RouteParamsMap'
import { NavigatorParams, Routes } from './types'

type RaiseIssueStackRoutes =
  | Routes.RaiseIssueMain
  | Routes.Step1
  | Routes.TakePhoto
  | Routes.Step2
  | Routes.Step3
  | Routes.RaiseIssuePhoneMakeCall
  | Routes.RaiseIssuePhoneMakeCallOther
  | Routes.Feedback
  | Routes.HistoryAccess
  | Routes.Summary

type RaiseIssueStackParamsMap = Pick<RouteParamsMap, RaiseIssueStackRoutes>
export type RaiseIssueStackNavParams = NavigatorParams<
  RaiseIssueStackParamsMap,
  RaiseIssueStackRoutes
>

const Stack = createStackNavigator<RaiseIssueStackParamsMap>()

export const RaiseIssueStack = () => (
  <Stack.Navigator
    initialRouteName={Routes.RaiseIssueMain}
    screenOptions={{
      header: props => <Header {...props} iconName="info" />,
    }}
  >
    <Stack.Screen
      name={Routes.RaiseIssueMain}
      component={RaiseIssueMainScreen}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Step1}
      component={Step1Screen}
      options={{
        title: 'powrót do wyboru sposobu zgłoszenia',
      }}
    />
    <Stack.Screen
      name={Routes.TakePhoto}
      component={TakePhotoScreen}
      options={{
        title: ' ',
      }}
    />
    <Stack.Screen
      name={Routes.Step2}
      component={Step2Screen}
      options={{
        title: 'lokalizacja zdarzenia',
      }}
    />
    <Stack.Screen
      name={Routes.Step3}
      component={Step3Screen}
      options={{
        title: 'powrót do lokalizacji zdarzenia',
      }}
    />
    <Stack.Screen
      name={Routes.RaiseIssuePhoneMakeCall}
      component={MissedCallScreen}
      options={{
        headerShown: false,
      }}
    />
    <Stack.Screen
      name={Routes.RaiseIssuePhoneMakeCallOther}
      component={MakeCallOtherScreen}
      options={{
        headerShown: false,
      }}
    />
    <Stack.Screen
      name={Routes.Feedback}
      component={FeedbackScreen}
      options={{
        headerShown: false,
      }}
    />
    <Stack.Screen
      name={Routes.HistoryAccess}
      component={HistoryAccess}
      options={{
        headerShown: false,
      }}
    />
    <Stack.Screen
      name={Routes.Summary}
      component={ReportSummaryScreen}
      options={{
        headerShown: false,
      }}
    />
  </Stack.Navigator>
)
