import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { View, SafeAreaView, Image, Dimensions, FlatList } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, Text } from '~components'
import { isWeb } from '~helpers'

import { ImageWrapper } from '~components/ImageWrapper'
import { SquareButton } from '~components/SquareButton'
import { Routes } from '~navigation/types'

const DATA = [
  require('~assets/images/examplePhoto3.jpeg'),
  require('~assets/images/examplePhoto.jpeg'),
  require('~assets/images/examplePhoto1.jpg'),
]

const ListEmptyComponent = () => {
  return (
    <View style={styles.container}>
      <Text variant={'emptyState'} style={[tw.textCenter]}>
        aktualnie nie posiadasz żadnych zdjęć
      </Text>
    </View>
  )
}

interface AddImageButtonProps {
  onPress: () => void
}

const AddImageButton = ({ onPress }: AddImageButtonProps) => (
  <SquareButton onPress={onPress}>
    <Image source={require('~assets/icons/raiseIssue.png')} style={[tw.h8, tw.w8, tw.mB6]} />
    <Text style={[{ fontSize: 16 }, tw.textCenter, tw.p2]}>
      dodaj zdjęcia lub filmy do zgłoszenia
    </Text>
  </SquareButton>
)

// TODO: fetch them from backend/context and update handleDelete
const fetchedImages = [1, 2, 3, 4, 5, 6, 7, 8]

export const SummaryGalleryScreen = () => {
  const [images, setImages] = useState<any[]>([])
  const { navigate } = useNavigation()
  const goToHistorySummary = useCallback(() => navigate(Routes.HistorySummary), [navigate])

  const { width } = Dimensions.get('window')

  const handleDelete = useCallback(
    id => {
      const filterdImages = images.filter(image => image !== id)
      setImages(filterdImages)
    },
    [images],
  )

  useEffect(() => {
    setImages([...fetchedImages, { addImage: true }])
  }, [])

  const keyExtractor = useCallback((item, index) => `item-${index}`, [])
  const renderItem = useCallback(
    ({ item }) => {
      return (
        <View style={[!isWeb ? [tw.flex1, tw.pX2] : []]}>
          {isWeb ? (
            <View style={[styles.wrapper]}>
              {item.addImage ? (
                <AddImageButton onPress={() => alert('dodaj zdjęcia')} />
              ) : (
                <ImageWrapper
                  action={handleDelete}
                  id={item}
                  imageStyles={[tw.rounded]}
                  actionLabel="Usuń"
                />
              )}
            </View>
          ) : (
            <Image
              style={[{ borderRadius: 10, width: '100%', height: 250, marginBottom: 10 }]}
              source={item}
              resizeMode={'cover'}
            />
          )}
        </View>
      )
    },
    [handleDelete, width],
  )

  return (
    <SafeAreaView style={[tw.flex1]}>
      {isWeb ? (
        <View style={[tw.hFull, tw.pB4, tw.flex, tw.justifyBetween, tw.pT20]}>
          <View style={[tw.p4, tw.flex, tw.flexWrap]}>
            <FlatList
              data={images}
              ListEmptyComponent={ListEmptyComponent}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
              numColumns={Math.floor((width - 320) / 200)}
            />
          </View>
          <Button
            label="Wróć do podsumowania zgłoszenia"
            style={[tw.m4, tw.rounded]}
            onPress={goToHistorySummary}
          />
        </View>
      ) : (
        <React.Fragment>
          <FlatList
            data={DATA}
            ListEmptyComponent={ListEmptyComponent}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            showsVerticalScrollIndicator={false}
          />
          {/* {renderButtons()} */}
        </React.Fragment>
      )}
    </SafeAreaView>
  )
}

const styles = {
  container: [tw.flex1, tw.justifyCenter, tw.itemsCenter],
  wrapper: [tw.rounded, tw.m1],
}
