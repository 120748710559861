import React, { useCallback, useState } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, Input, Text } from '~components'

const info = `podaj kod SMS
lub potwierdź rejestrację
przez e-mail, który wysłaliśmy
 na podany adres`

export const VerificationScreen = () => {
  const submit = useCallback(() => console.log('submitting'), [])
  const resendVerificationCode = useCallback(() => console.log('resending verification code'), [])
  const [text, setText] = useState('')

  return (
    <View style={styles.container}>
      <View style={styles.form}>
        <Text style={[...styles.textCenter, tw.mB4]}>Weryfikacja</Text>
        <Text style={styles.textCenter} variant="small">
          {info}
        </Text>
        <Input
          style={[tw.mT8, tw.mB4]}
          placeholder="Kod weryfikacyjny"
          textAlign="center"
          textContentType="oneTimeCode"
          value={text}
          onChangeText={setText}
        />
        <Button style={[tw.mB4]} label="dalej" full onPress={submit} disabled={!text} />
        <Button label="wyślij kod ponownie" full onPress={resendVerificationCode} />
      </View>
    </View>
  )
}

const styles = {
  container: [tw.itemsCenter, tw.justifyCenter, tw.flex1],
  form: [tw.w4_12],
  textCenter: [tw.textCenter],
}
