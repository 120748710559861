import startCase from 'lodash/startCase'
import React, { FunctionComponent } from 'react'
import { Text as RNText, TextProps } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { isSmall } from '~helpers'

export type Variant =
  | 'button'
  | 'big'
  | 'small'
  | 'input'
  | 'header'
  | 'text'
  | 'emptyState'
  | 'note'
type Color =
  | 'transparent'
  | 'main'
  | 'black'
  | 'white'
  | 'link'
  | 'lightGray'
  | 'gray'
  | 'darkGray'
  | 'darkerGray'
  | 'green'

type Props = TextProps & {
  variant?: Variant
  color?: Color
  style?: Array<string>
}

export const Text: FunctionComponent<Props> = ({
  variant = 'button',
  color = 'black',
  style = [],
  ...props
}) => {
  const textColor = `text${startCase(color).replace(/\s/g, '')}`

  return (
    <RNText
      allowFontScaling={false}
      {...props}
      style={[...styles.textVariants[variant], tw[textColor], ...style]}
    />
  )
}

const styles = {
  textVariants: {
    button: [
      tw.textBlack,
      tw.fontDisplayBold,
      tw.fontSemibold,
      isSmall ? tw.textXl : tw.text2xl,
      // { lineHeight: 28 },
    ],
    big: [
      tw.textBlack,
      tw.fontDisplayBold,
      tw.fontSemibold,
      isSmall ? tw.text4xl : tw.text5xl,
      { lineHeight: 55 },
    ],
    small: [
      tw.textBlack,
      tw.fontDisplay,
      tw.fontNormal,
      isSmall ? tw.textLg : tw.textXl,
      { lineHeight: 23 },
    ],
    input: [
      tw.textBlack,
      tw.fontDisplay,
      tw.fontNormal,
      isSmall ? tw.textLg : tw.textXl,
      { lineHeight: 23 },
    ],
    header: [
      tw.textBlack,
      tw.fontDisplay,
      tw.fontNormal,
      isSmall ? tw.text2xl : tw.text3xl,
      { lineHeight: isSmall ? 23 : 34 },
    ],
    text: [
      tw.textBlack,
      tw.fontDisplay,
      tw.fontNormal,
      isSmall ? tw.textXl : tw.text2xl,
      { lineHeight: 28 },
    ],
    emptyState: [
      tw.textBlack,
      tw.fontDisplay,
      tw.fontNormal,
      isSmall ? tw.text2xl : tw.text3xl,
      { lineHeight: 34 },
    ],
    note: [
      tw.textBlack,
      tw.fontNote,
      tw.fontNormal,
      isSmall ? tw.text4xl : tw.text5xl,
      { lineHeight: 55 },
    ],
  },
}
