import React, { useCallback, useMemo, useState } from 'react'
import { tw } from 'react-native-tailwindcss'
import { isSmall, isWeb } from '~helpers'

import { Button, CircleButton, Logo, ScrollView, Text } from '~components'
import { useNavigation } from '@react-navigation/native'

const title = `Dziękujemy Ci
za zgłoszenie!`
const titleWeb = 'Dziękujemy Ci za zgłoszenie!'

const content = `Zostanie ono niezwłocznie
przekazane odpowiednim
służbom bądź organizacjom
charytatywnym.`
const contentWithGPS = `Zdjecie i koordynaty GPS zostały właśnie dodane do mapy zagubionych zwierząt. Może dzięki temu właściciel będzie mógł odnaleźć zagubionego pupila. Jeżeli jesteś pewien, że zwierzę jest bezpańskie możesz też zadzwonić do najbliższego schroniska:`
const contentWeb = `Zostanie ono niezwłocznie przekazane
odpowiednim służbom bądź organizacjom charytatywnym.`
const phoneNumber = `+48 508 805 481`

export const FeedbackScreen = () => {
  const { navigate } = useNavigation()
  const goToScreen = useCallback(screenName => navigate(screenName), [navigate])
  const [isHomelessAnimal, setIsHomelessAnimal] = useState(true)

  const checkContent = useCallback(() => {
    if (isWeb) {
      return contentWeb
    } else {
      if (isHomelessAnimal) {
        return contentWithGPS
      } else {
        return content
      }
    }
  }, [isWeb, isHomelessAnimal])

  const renderPhoneNumber = useMemo(() => {
    return (
      isHomelessAnimal && (
        <Text style={[tw.textCenter, tw.mT4]} variant="small">
          {phoneNumber}
        </Text>
      )
    )
  }, [isHomelessAnimal])

  return (
    <ScrollView contentContainerStyle={styles.contentContainerStyle}>
      {!isWeb && <Logo style={[isSmall ? tw.mY10 : tw.mY16]} />}
      <CircleButton style={[tw.bgMain]} tintColor="black" iconName="check" />
      <Text style={[tw.textCenter, isSmall ? tw.mT8 : tw.mT12]} variant="button">
        {isWeb ? titleWeb : title}
      </Text>
      <Text style={[tw.textCenter, tw.mT6]} variant="small">
        {checkContent()}
      </Text>
      {renderPhoneNumber}
      <Button
        big
        full
        label="wróć do strony głównej"
        style={[tw.mY6]}
        onPress={() => goToScreen('RaiseIssueStack')}
      />
      <Button
        big
        full
        label="dostęp do historii zgłoszeń"
        style={[tw.mB10]}
        onPress={() => goToScreen('HistoryAccess')}
      />
    </ScrollView>
  )
}

const styles = {
  contentContainerStyle: [tw.justifyCenter, tw.itemsCenter, tw.pX4, isWeb && tw.flex1],
}
