import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import { Header } from '~components'
import {
  ApplicationCreatorsScreen,
  InformationAdvicesScreen,
  InformationFirstAidScreen,
  InformationLegalScreen,
  InformationMainScreen,
  InformationPetScreen,
  PetHelpScreen,
} from '~screens'

import { RouteParamsMap } from './RouteParamsMap'
import { NavigatorParams, Routes } from './types'
import { isWeb } from '~helpers'

type InformationStackRoutes =
  | Routes.InformationMain
  | Routes.InformationAdvices
  | Routes.InformationLegal
  | Routes.PetHelpScreen
  | Routes.InformationFirstAid
  | Routes.InformationPet
  | Routes.ApplicationCreators
export type InformationStackParamsMap = Pick<RouteParamsMap, InformationStackRoutes>
export type InformationStackNavParams = NavigatorParams<
  InformationStackParamsMap,
  InformationStackRoutes
>

const Stack = createStackNavigator<InformationStackParamsMap>()

export const InformationStack = ({ route }) => {
  return (
    <Stack.Navigator
      initialRouteName={Routes.InformationMain}
      screenOptions={{
        header: props => <Header {...props} iconName="info" />,
      }}
    >
      <Stack.Screen
        name={Routes.InformationMain}
        component={InformationMainScreen}
        options={{
          title: 'informacje',
          headerShown: isWeb ? false : true,
        }}
      />
      <Stack.Screen
        name={Routes.InformationAdvices}
        component={InformationAdvicesScreen}
        options={{
          title: isWeb ? 'powrót do informacji' : 'porady',
        }}
      />
      <Stack.Screen
        name={Routes.InformationLegal}
        component={InformationLegalScreen}
        options={{
          title: 'informacje prawne',
        }}
      />
      <Stack.Screen
        name={Routes.InformationFirstAid}
        component={InformationFirstAidScreen}
        options={{
          title: 'pierwsza pomoc',
        }}
      />
      <Stack.Screen name={Routes.PetHelpScreen} component={PetHelpScreen} />
      <Stack.Screen
        name={Routes.InformationPet}
        component={InformationPetScreen}
        options={{
          title: 'obserwuj swoje zwierzę',
        }}
      />
      <Stack.Screen
        name={Routes.ApplicationCreators}
        component={ApplicationCreatorsScreen}
        options={{ title: 'sponsorzy' }}
      />
    </Stack.Navigator>
  )
}
