import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { WebCard } from '~components'

const formLabel = `Zgłoś
anonimowo
przez formularz`

const phoneLabel = `Zgłoś przez
telefon!`

export const RaiseIssueMainScreen = () => {
  const { navigate } = useNavigation()
  const goToRaiseIssue = useCallback(() => navigate('Step1'), [navigate])
  const goToRaiseIssuePhone = useCallback(() => navigate('RaiseIssuePhoneMakeCall'), [navigate])

  return (
    <View style={styles.container}>
      <View style={styles.containerBox}>
        <WebCard
          title={formLabel}
          description={'W kilku prostych krokach zgłoś zdarzenie.'}
          label="dalej"
          onPress={goToRaiseIssue}
        />
        <WebCard
          title={phoneLabel}
          description={'Połącz sie z operatorem i zgłoś zdarzenie.'}
          label="dzwoń"
          onPress={goToRaiseIssuePhone}
        />
      </View>
    </View>
  )
}

const styles = {
  container: [tw.flex1, tw.itemsCenter, tw.justifyCenter],
  containerBox: [tw.flexRow, tw.justifyAround, tw.w8_12],
}
