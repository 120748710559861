import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useCallback, useState } from 'react'
import { SafeAreaView, View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, ScrollView, Stepper, Checkbox } from '~components'
import { isSmall } from '~helpers'

const incidents = [
  'bicie',
  'głodzenie lub odwodnienie',
  'okaleczenie',
  'zabite zwierze',
  'nieleczona choroba',
]

export const ChooseIncidentsScreen = () => {
  const route = useRoute()
  const { navigate } = useNavigation()
  const condition = route.name !== 'HistoryChooseIncident'
  const [checkedElements, setCheckedElements] = useState([] as number[])

  function checkListElement(index: number): void {
    const joined = checkedElements.concat(index)
    const removed = checkedElements.filter((el: number) => el !== index)
    if (checkedElements.includes(index)) {
      setCheckedElements(removed)
    } else {
      setCheckedElements(joined)
    }
  }

  const goToSummary = useCallback(() => {
    navigate('Summary')
  }, [navigate])

  return (
    <SafeAreaView style={[tw.flex1]}>
      {condition ? <Stepper activeIndex={2} /> : false}
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <View style={[tw.wFull, tw.justifyAround]}>
          {incidents.map((incident, index) => {
            return (
              <Checkbox
                label={incident}
                index={index}
                checkedElements={checkedElements}
                onPressElement={() => checkListElement(index)}
                key={index}
              />
            )
          })}
        </View>
      </ScrollView>
      {condition ? (
        <View style={[tw.pX4, isSmall && tw.mB4]}>
          <Button
            disabled={!checkedElements.length}
            full
            big={isSmall ? false : true}
            label="dalej"
            style={[tw.mB4]}
            onPress={goToSummary}
          />
        </View>
      ) : (
        false
      )}
    </SafeAreaView>
  )
}

const styles = {
  contentContainerStyle: [tw.p4],
}
