import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import { Header } from '~components'
import { isWeb } from '~helpers'
import {
  HistoryMainScreen,
  SummaryScreen,
  SummaryInformationScreen,
  Step2Screen,
  Step3Screen,
  ChooseIncidentsScreen,
  SummaryGalleryScreen,
  ResultInspectionScreen,
} from '~screens'

import { RouteParamsMap } from './RouteParamsMap'
import { NavigatorParams, Routes } from './types'

export type HistoryStackRoutes =
  | Routes.HistoryMain
  | Routes.HistorySummary
  | Routes.HistorySummaryLocation
  | Routes.HistoryEditLocation
  | Routes.HistorySummaryKindIncident
  | Routes.HistorySummaryInformation
  | Routes.HistoryChooseIncident
  | Routes.HistorySummaryGallery
  | Routes.HistoryResultInspection

type HistoryStackParamsMap = Pick<RouteParamsMap, HistoryStackRoutes>
export type HistoryStackNavParams = NavigatorParams<HistoryStackParamsMap, HistoryStackRoutes>

const Stack = createStackNavigator<HistoryStackParamsMap>()

export const HistoryStack = () => (
  <Stack.Navigator
    initialRouteName={Routes.HistoryMain}
    screenOptions={{
      header: props => <Header {...props} iconName="history" />,
    }}
  >
    <Stack.Screen
      name={Routes.HistoryMain}
      component={HistoryMainScreen}
      options={{
        title: 'historia zgłoszeń',
        headerShown: isWeb ? false : true,
      }}
    />
    <Stack.Screen
      name={Routes.HistorySummary}
      component={SummaryScreen}
      options={{
        title: 'podsumowanie zgłoszenia',
      }}
    />
    <Stack.Screen
      name={Routes.HistorySummaryLocation}
      component={Step2Screen}
      options={{
        title: 'lokalizacja zdarzenia',
      }}
    />

    <Stack.Screen
      name={Routes.HistoryEditLocation}
      component={Step2Screen}
      options={{ headerShown: false, title: 'Edytuj lokalizację zdarzenia' }}
    />

    <Stack.Screen
      name={Routes.HistorySummaryKindIncident}
      component={Step3Screen}
      options={{
        title: 'Wybierz rodzaj zdarzenia',
      }}
    />
    <Stack.Screen
      name={Routes.HistorySummaryInformation}
      component={SummaryInformationScreen}
      options={{
        title: 'wpisz informacje',
        headerShown: !isWeb,
      }}
    />
    <Stack.Screen
      name={Routes.HistoryChooseIncident}
      component={ChooseIncidentsScreen}
      options={{
        title: 'Zaznacz rodzaj zdarzenia',
      }}
    />
    <Stack.Screen
      name={Routes.HistorySummaryGallery}
      component={SummaryGalleryScreen}
      options={{
        title: 'zdjęcia i filmy',
        headerShown: !isWeb,
      }}
    />
    <Stack.Screen
      name={Routes.HistoryResultInspection}
      component={ResultInspectionScreen}
      options={{
        title: 'wynik inspekcji',
      }}
    />
  </Stack.Navigator>
)
