export enum Routes {
  // BOTTOM TAB NAVIGATOR
  AccountStack = 'AccountStack',
  InformationStack = 'InformationStack',
  HistoryStack = 'HistoryStack',
  RaiseIssueStack = 'RaiseIssueStack',
  // ACCOUNT STACK
  AccountMain = 'AccountMain',
  AccountSignIn = 'AccountSignIn',
  AccountSignUp = 'AccountSignUp',
  AccountVerification = 'AccountVerification',
  // INFORMATION  STACK
  InformationMain = 'InformationMain',
  ApplicationCreators = 'ApplicationCreators',
  InformationAdvices = 'InformationAdvices',
  InformationLegal = 'InformationLegal',
  PetHelpScreen = 'PetHelpScreen',
  InformationFirstAid = 'InformationFirstAid',
  InformationFirstAidBleedingOne = 'InformationFirstAidBleedingOne',
  InformationFirstAidBleedingTwo = 'InformationFirstAidBleedingTwo',
  InformationFirstAidBleedingThree = 'InformationFirstAidBleedingThree',
  InformationFirstAidHeatOne = 'InformationFirstAidHeatOne',
  InformationFirstAidHeatAttencionOne = 'InformationFirstAidHeatAttencionOne',
  InformationFirstAidHeatAttencionTwo = 'InformationFirstAidHeatAttencionTwo',
  InformationFirstAidHeatTwo = 'InformationFirstAidHeatTwo',
  InformationFirstAidBurns = 'InformationFirstAidBurns',
  InformationFirstAidFrostbite = 'InformationFirstAidFrostbite',
  InformationFirstAidStrokeOne = 'InformationFirstAidStrokeOne',
  InformationFirstAidStrokeTwo = 'InformationFirstAidStrokeTwo',
  InformationFirstAidStrokeThree = 'InformationFirstAidStrokeThree',
  InformationPet = 'InformationPet',
  // HISTORY STACK
  HistoryMain = 'HistoryMain',
  HistorySummary = 'HistorySummary',
  HistorySummaryLocation = 'HistorySummaryLocation',
  HistoryEditLocation = 'HistoryEditLocation',
  HistorySummaryKindIncident = 'HistorySummaryKindIncident',
  HistorySummaryInformation = 'HistorySummaryInformation',
  HistoryChooseIncident = 'HistoryChooseIncident',
  HistorySummaryGallery = 'HistorySummaryGallery',
  HistoryResultInspection = 'HistoryResultInspection',
  // RAISE ISSUE STACK
  RaiseIssueMain = 'RaiseIssueMain',
  Feedback = 'Feedback',
  RaiseIssuePhoneMakeCall = 'RaiseIssuePhoneMakeCall',
  RaiseIssuePhoneMissedCall = 'RaiseIssuePhoneMissedCall',
  RaiseIssuePhoneMakeCallOther = 'RaiseIssuePhoneMakeCallOther',
  RaiseIssueReport = 'RaiseIssueReport',
  Step1 = 'Step1',
  TakePhoto = 'TakePhoto',
  Step2 = 'Step2',
  Step3 = 'Step3',
  HistoryAccess = 'HistoryAccess',
  Summary = 'Summary',
  SendVerification = 'SendVerification',
  EnterVerificationCode = 'EnterVerificationCode',
  RaiseIssueSummary = 'RaiseIssueSummary',
  SummaryGallery = 'SummaryGallery',
  SummaryKindIncident = 'SummaryKindIncident',
  SummaryInformation = 'SummaryInformation',
  SummaryLocation = 'SummaryLocation',
}

export type Route = keyof typeof Routes

export type NavigatorParams<
  NavigatorRouteParams,
  NavigatorRoute extends keyof NavigatorRouteParams
> =
  | {
      screen: NavigatorRoute
      params?: NavigatorRouteParams[NavigatorRoute]
    }
  | undefined
