import React from 'react'
import { tw } from 'react-native-tailwindcss'
import { isSmall } from '~helpers'
import { isAlert } from '~helpers'

import { ScrollView, Text, Button } from '~components'

export const ResultInspectionScreen = () => {
  return (
    <>
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <Text variant="text">
          Operator dokonał inspekcji na miejscu. Jeżeli jesteś zaintere- sowany jej wynikiem zadzwoń
          do organizacji. Pamiętaj jednak, że nasz czas i zasoby ludzkie są mocno ograniczone.
          Zwierzęta w potrzebie będą Ci wdzięczne, jeżeli pozwolisz nam w tym czasie działać nad
          kolejnymi zgłoszeniami.
        </Text>
        <Button
          full
          big={!isSmall && true}
          label="DZWOŃ"
          style={[tw.mT4, { backgroundColor: '#00C2FF' }]}
          secondary
          onPress={() =>
            isAlert(
              'Dzwonisz do AnimalHelper. Nie będziemy w tym momencie zajmować lini, jest to wersja pokazowa.',
            )
          }
        />
      </ScrollView>
    </>
  )
}

const styles = {
  contentContainerStyle: [
    tw.pX4,
    tw.itemsCenter,
    tw.justifyBetween,
    isSmall ? tw.pT1 : tw.pT10,
    tw.flexGrow,
  ],
}
