import { useNavigation } from '@react-navigation/native'
import * as WebBrowser from 'expo-web-browser'
import React, { useRef, useCallback, MutableRefObject } from 'react'
import { View, TextInput } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, Field, Text } from '~components'
import { useSignUpForm } from '~hooks'

export const SignUpScreen = () => {
  const path = 'https://www.animalhelper.pl/polityka-prywatnosci/'
  const emailRef = useRef<TextInput>() as MutableRefObject<TextInput>
  const passwordRef = useRef<TextInput>() as MutableRefObject<TextInput>

  const { control, submit, formState } = useSignUpForm()
  const { navigate } = useNavigation()
  const goToSignInScreen = useCallback(() => navigate('AccountSignIn'), [navigate])
  const goToTermsAndConditions = useCallback(
    () => alert('regulamin aplikacji nie jest jeszcze gotowy'),
    [],
  )
  const goToPrivacyPolicy = useCallback(() => WebBrowser.openBrowserAsync(path), [])

  return (
    <View style={styles.container}>
      <View style={styles.form}>
        <Text style={styles.space}>Zarejestruj się!</Text>
        <Field.Phone
          control={control}
          name={'phone'}
          returnKeyType="next"
          onSubmitEditing={() => emailRef?.current?.focus()}
        />
        <Field.Email
          control={control}
          name={'email'}
          returnKeyType="next"
          ref={emailRef}
          onSubmitEditing={() => passwordRef?.current?.focus()}
        />
        <Field.Password
          control={control}
          name={'password'}
          returnKeyType="done"
          ref={passwordRef}
          onSubmitEditing={submit}
        />
        <Button label="zarejestruj się" full onPress={submit} disabled={!formState.isValid} />
        <View style={styles.textContainer}>
          <Text variant="small" style={[tw.textCenter]}>
            rejestrując się akceptujesz{' '}
            <Text variant="small" style={[tw.textLink]} onPress={goToPrivacyPolicy}>
              politkę prywatności
            </Text>{' '}
            i{' '}
            <Text variant="small" style={[tw.textLink]} onPress={goToTermsAndConditions}>
              regulamin
            </Text>
          </Text>
        </View>
        <View style={styles.textContainer}>
          <Text variant="small" style={[tw.textCenter]}>
            Masz już konto?{'\n'}
            <Text variant="small" style={[tw.textLink]} onPress={goToSignInScreen}>
              Zaloguj się!
            </Text>
          </Text>
        </View>
      </View>
    </View>
  )
}

const styles = {
  container: [tw.itemsCenter, tw.justifyCenter, tw.flex1],
  form: [tw.w4_12],
  space: [tw.mB4],
  textContainer: [tw.mY5, tw.itemsCenter],
}
