import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { View, Dimensions } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { ScrollView, Logo, Text, BigCircleButton } from '~components'

const { width } = Dimensions.get('window')

export const MakeCallScreen = () => {
  const { navigate, goBack } = useNavigation()
  const goToMissedCallScreen = useCallback(() => {
    navigate('RaiseIssuePhoneMissedCall')
  }, [navigate])

  return (
    <>
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <Logo style={[tw.mT16]} />
        <View style={styles.viewStyle}>
          <Text style={styles.text} variant="button">
            czy połączyć z operatorem animal helper?
          </Text>
          <Text style={[tw.textCenter, tw.mT4]} variant="small">
            połączenie wg stawki operatora
          </Text>
        </View>
        <BigCircleButton label={`DZWOŃ`} style={styles.bigCircle} onPress={goToMissedCallScreen} />
        <BigCircleButton
          label={`anuluj`}
          style={styles.smallCircle}
          variant="button"
          onPress={goBack}
        />
      </ScrollView>
    </>
  )
}

const styles = {
  contentContainerStyle: [tw.flex1, tw.pX4, tw.itemsCenter, tw.justifyCenter],
  viewStyle: [{ width: (width * 60) / 100 }, tw.mT4, tw._mB6],
  text: [tw.textCenter],
  bigCircle: [{ top: 30 }, { width: width / 1.6, height: width / 1.6 }],
  smallCircle: [
    { left: 80, bottom: 30 },
    { width: 140, height: 140 },
  ],
}
