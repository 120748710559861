import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Image, PressableProps, View } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { Hoverable } from 'react-native-web-hooks'

import { isSmall, isWeb } from '~helpers'

import { CircleButton } from './CircleButton'
import { Pressable } from './Pressable'
import { Text } from './Text'

type ButtonComposition = {
  Social: typeof SocialButton
}

type ButtonProps = PressableProps & {
  style?: Array<any>
  label?: string
  phoneNumber?: string
  nameInstitution?: string
  full?: boolean
  big?: boolean
  secondary?: boolean
  feedback?: boolean
}

export const Button: FunctionComponent<ButtonProps> & ButtonComposition = ({
  style = [],
  label,
  full = false,
  big = false,
  secondary = false,
  disabled,
  feedback = false,
  ...rest
}) => {
  return (
    <React.Fragment>
      {isWeb ? (
        <Hoverable>
          {isHovered => (
            <Pressable
              style={[
                ...styles.button,
                full ? tw.wFull : tw.wAuto,
                big ? styles.bigButton : {},
                disabled ? styles.disabled : {},
                secondary ? styles.secondary : {},
                feedback ? styles.feedback : {},
                isHovered ? styles.pointer : {},
                ...style,
              ]}
              disabled={disabled}
              {...rest}
            >
              <Text variant="button" color={disabled || secondary || feedback ? 'white' : 'black'}>
                {label}
              </Text>
            </Pressable>
          )}
        </Hoverable>
      ) : (
        <Pressable
          style={[
            ...styles.button,
            full ? tw.wFull : tw.wAuto,
            big ? styles.bigButton : {},
            disabled ? styles.disabled : {},
            secondary ? styles.secondary : {},
            feedback ? styles.feedback : {},
            ...style,
          ]}
          disabled={disabled}
          {...rest}
        >
          <Text
            variant="button"
            color={disabled || secondary || feedback ? 'white' : 'black'}
            style={[tw.textCenter]}
          >
            {label}
          </Text>
        </Pressable>
      )}
    </React.Fragment>
  )
}

type TypeSocial = 'google' | 'facebook' | 'apple'

type SocialProps = PressableProps & {
  style?: Array<any>
  typeSocial: TypeSocial
}

export const SocialButton: FunctionComponent<SocialProps> = ({
  style = [],
  typeSocial,
  ...rest
}) => {
  const typeOfSocial = useMemo(() => {
    const texts = {
      google: 'google',
      facebook: 'facebook',
      apple: 'apple',
    }

    const style = {
      google: styles.google,
      facebook: styles.facebook,
      apple: styles.apple,
    }

    const images = {
      google: require('~assets/icons/google.png'),
      facebook: require('~assets/icons/facebook.png'),
      apple: require('~assets/icons/apple.png'),
    }

    return [texts[typeSocial], style[typeSocial], images[typeSocial]]
  }, [typeSocial])

  return (
    <Pressable style={[...styles.buttonWithIcon, ...typeOfSocial[1], ...style]} {...rest}>
      <Image
        source={typeOfSocial[2]}
        style={{ width: 40, height: 40 }}
        resizeMethod="scale"
        resizeMode="contain"
      />
      <Text
        style={[tw.mL3, { lineHeight: 30 }]}
        variant="small"
        color={typeSocial !== 'google' ? 'white' : 'black'}
      >
        kontynuuj z {typeOfSocial[0]}
      </Text>
    </Pressable>
  )
}

export const CallButton: FunctionComponent<ButtonProps> = ({
  style = [],
  phoneNumber,
  nameInstitution,
  ...rest
}) => {
  return (
    <Pressable
      style={[styles.button, styles.bigButton, style, tw.flexRow, tw.justifyBetween]}
      {...rest}
    >
      <View>
        <Text variant="button">{nameInstitution}</Text>
        <Text variant="small">{phoneNumber}</Text>
      </View>
      <CircleButton disabled style={[tw.mR2]} iconName="phone" tintColor="yellow" />
    </Pressable>
  )
}

const styles = {
  button: [{ height: 60, borderRadius: 10 }, tw.justifyCenter, tw.itemsCenter, tw.bgMain, tw.pX5],
  buttonWithIcon: [
    { height: 60, borderRadius: 10 },
    tw.wFull,
    tw.itemsCenter,
    tw.flexRow,
    tw.pX4,
    tw.justifyStart,
  ],
  google: [tw.border, tw.bgWhite, tw.borderBlack],
  facebook: [{ backgroundColor: '#1977F3' }],
  apple: [tw.bgBlack],
  bigButton: [{ height: isSmall ? 80 : 90, borderRadius: 16 }],
  disabled: [tw.bgGray],
  secondary: [tw.bgDarkGray],
  pointer: [{ cursor: 'pointer' }],
  feedback: [tw.bgLink],
}

Button.Social = SocialButton
