import { impactAsync, ImpactFeedbackStyle } from 'expo-haptics'
import React, { useCallback } from 'react'
import {
  GestureResponderEvent,
  Platform,
  Pressable as RNPressable,
  PressableProps,
} from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'

const AnimatedPressable = Animated.createAnimatedComponent(RNPressable)

type MyPressableProps = PressableProps & { style?: string[] }

export const Pressable = (props: MyPressableProps) => {
  const scale = useSharedValue(1)
  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scale.value }],
    }
  })
  const onPressIn = useCallback(
    (event: GestureResponderEvent) => {
      scale.value = withSpring(1.05)
      props.onPressIn?.(event)
    },
    [props, scale],
  )
  const onPressOut = useCallback(
    (event: GestureResponderEvent) => {
      scale.value = withSpring(1.0)
      props.onPressOut?.(event)
    },
    [props, scale],
  )
  const onPress = useCallback(
    (event: GestureResponderEvent) => {
      if (Platform.OS !== 'web') {
        impactAsync(ImpactFeedbackStyle.Light)
      }
      props.onPress?.(event)
    },
    [props],
  )

  return (
    <AnimatedPressable
      {...props}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onPress={onPress}
      style={[animatedStyles, ...(props.style || [])]}
    />
  )
}
