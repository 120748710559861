import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { Image, View } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import GoogleMapReact from 'google-map-react'

import { Button, Text } from '~components'

export const Step2Screen = () => {
  const { navigate, goBack } = useNavigation()
  const route = useRoute()
  const goToStep3 = useCallback(() => navigate('Step3'), [navigate])

  const defaultProps = {
    center: { lat: 54.5188898, lng: 18.5305409 },
    zoom: 11,
  }

  const backToSummary = useCallback(() => {
    return route.name === 'HistoryEditLocation' ? (
      <Button label="wróć do podsumowania zgłoszenia" onPress={() => goBack()} full />
    ) : (
      <>
        <Button label="pomiń" style={[tw.bgGray, tw.flex1]} onPress={() => goToStep3()} />
        <View style={[tw.mX1]} />
        <Button label="dalej" style={[tw.bgMain, tw.flex1]} onPress={() => goToStep3()} />
      </>
    )
  }, [route.name])

  return (
    <View style={styles.contentContainerStyle}>
      <View style={[tw.pX5, tw.flex1, tw.wFull]}>
        <Text style={[tw.pB4]}>Przesuń mapę aby skorygować położenie</Text>
        <GoogleMapReact defaultCenter={defaultProps.center} defaultZoom={defaultProps.zoom}>
          <Image
            source={require('~assets/icons/marker.png')}
            style={styles.marker}
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
          />
        </GoogleMapReact>
        {}
        <View style={styles.bottomButtons}>{backToSummary()}</View>
      </View>
    </View>
  )
}

const styles = {
  contentContainerStyle: [tw.flex1, tw.itemsCenter, tw.justifyCenter],
  bottomButtons: [tw.mY6, tw.flexRow, tw.wFull],
  marker: { width: 80, height: 80 },
}
