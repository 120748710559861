import 'portable-fetch'

import { Poppins_600SemiBold, Poppins_400Regular } from '@expo-google-fonts/poppins'
import { RobotoMono_400Regular } from '@expo-google-fonts/roboto-mono'
import { polyfill } from 'es6-promise'
import AppLoading from 'expo-app-loading'
import { useFonts } from 'expo-font'
import { StatusBar } from 'expo-status-bar'
import React from 'react'
import { UIManager, Platform, Dimensions } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Label } from './components/react-native-label/components/Label'
import Orientation from './components/react-native-label/constants/Orientation'
import { DemoModal } from './components/DemoModal'

polyfill()

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true)
  }
}

import { Navigation } from './navigation'
const queryClient = new QueryClient()
// eslint-disable-next-line import/no-default-export
export default function App() {
  const [fontsLoaded] = useFonts({
    Poppins_400Regular,
    Poppins_600SemiBold,
    RobotoMono_400Regular,
  })

  if (!fontsLoaded) {
    return <AppLoading />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SafeAreaProvider style={{ overflow: 'hidden' }}>
        <Label
          title="demo"
          orientation={Orientation.TOP_RIGHT}
          containerStyle={Dimensions.get('window')}
          color="#00C2FF"
          distance={100}
          style={{
            fontSize: 24,
          }}
        >
          <Navigation />
          <DemoModal />
        </Label>
        <StatusBar style="dark" />
      </SafeAreaProvider>
    </QueryClientProvider>
  )
}
