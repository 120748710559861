import React from 'react'
import { ScrollView, Image, Pressable } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { openURL, canOpenURL } from 'expo-linking'
import { isWeb } from '~helpers'

import { Text } from '~components'

export const ApplicationCreatorsScreen = () => {
  const goTo = (link: string) => async () => {
    if (await canOpenURL(link)) {
      openURL(link)
    }
  }

  return (
    <ScrollView contentContainerStyle={styles.contentContainerStyle}>
      <Text style={[tw.mY8]} variant="text">
        partnerzy technologiczni:
      </Text>
      <Pressable style={[tw.mB8]} onPress={goTo('https://binarapps.com/')}>
        <Image
          source={require('~assets/images/binarApps.png')}
          style={styles.img}
          resizeMode="contain"
        />
      </Pressable>
      <Pressable onPress={goTo('https://www.porta.com.pl/')}>
        <Image
          source={require('~assets/images/portaDrzwi.png')}
          style={styles.img}
          resizeMode="contain"
        />
      </Pressable>
      <Text variant="text" style={[tw.mY8]}>
        sponsor techniczny:
      </Text>
      <Pressable onPress={goTo('https://kavaha.pl/')}>
        <Image
          source={require('~assets/images/kavaha.png')}
          style={styles.img}
          resizeMode="contain"
        />
      </Pressable>
      <Text variant="text" style={[tw.mY8]}>
        o nas:
      </Text>
      <Pressable onPress={goTo('https://fundacjapsiakrew.pl/')}>
        <Image
          source={require('~assets/images/fundacja-psia-krew.jpg')}
          style={styles.img}
          resizeMode="contain"
        />
      </Pressable>
      <Pressable>
        <Image
          source={require('~assets/images/show_world.png')}
          style={styles.img}
          resizeMode="contain"
        />
      </Pressable>
      <Pressable style={[tw.mB10]} onPress={goTo('https://www.stageproduction.pl/')}>
        <Image
          source={require('~assets/images/sprod.png')}
          style={styles.img}
          resizeMode="contain"
        />
      </Pressable>
    </ScrollView>
  )
}

const styles = {
  contentContainerStyle: [tw.flexGrow, tw.justifyAround, tw.itemsCenter, tw.pX4],
  img: isWeb ? { height: 100, width: 250 } : { maxWidth: 200, maxHeight: 200 },
}
