import { brand, modelName, osName, osVersion } from 'expo-device'
import * as MailComposer from 'expo-mail-composer'
import { Platform } from 'react-native'

export const sendFeedback = async () => {
  if (await MailComposer.isAvailableAsync()) {
    const body = `\n\n\nDane na temat twojego urządzenia pomogą nam lepiej zidentyfikować problem. Jeśli nie chcesz ich udostępniać, skasuj je przed wysłaniem wiadomości.\n\n${brand} ${modelName} (${osName} ${osVersion})`

    MailComposer.composeAsync({
      subject: `Feedback aplikacji - ${Platform.OS}`,
      recipients: ['feedback@animalhelper.pl'],
      body,
    })
  }
}
