import React from 'react'
import { Image } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { ScrollView } from '~components'
import { isSmall } from '~helpers'

export const InformationLegalScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.contentContainerStyle}>
      <Image
        source={require('~assets/images/legal.png')}
        style={styles.image}
        resizeMode={isSmall ? 'contain' : 'cover'}
      />
    </ScrollView>
  )
}

const styles = {
  contentContainerStyle: [tw.itemsCenter, tw.flexGrow],
  image: [tw.w11_12, isSmall ? tw._mT10 : tw.mT10],
}
