import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { View, FlatList, Image } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, Card, Text } from '~components'
import { isAlert } from '~helpers'

const ListEmptyComponent = () => {
  return (
    <View style={styles.container}>
      <Text variant={'emptyState'} style={styles.emptyStateText}>
        aktualnie nie posiadasz żadnych zgłoszeń
      </Text>
    </View>
  )
}
const ItemSeparatorComponent = () => {
  return <View style={[tw.h4]} />
}

export const RaiseIssueSummaryScreen = () => {
  const { navigate } = useNavigation()

  const goToScreen = useCallback(
    screenName => {
      navigate(screenName)
    },
    [navigate],
  )

  const DATA = [
    {
      id: '1',
      title: 'lokalizacja:',
      text: 'Miejscowość \nUl. Nazwa Ulicy 41, \n000-00',
      navigateTo: 'SummaryLocation',
    },
    {
      id: '2',
      title: 'rodzaj zdarzenia:',
      text: 'znęcanie się',
      navigateTo: 'SummaryKindIncident',
    },
    {
      id: '3',
      title: 'gatunek, liczba i opis:',
      text: 'pies, 1',
      description: 'Zgłaszający podczas jazdy potrącił psa.',
      navigateTo: 'SummaryInformation',
    },
    {
      id: '4',
      title: 'zdjęcia:',
      text: '2 zdjęcia',
      img: '~assets/images/examplePhoto.png',
      navigateTo: 'SummaryGallery',
    },
  ]

  const keyExtractor = useCallback(item => item.id, [])

  const renderItem = useCallback(({ item }) => {
    return item.navigateTo ? (
      <Card
        onPress={() =>
          item.navigateTo === 'SummaryKindIncident'
            ? isAlert('Nie można tam wejść, ponieważ ten ekran jeszcze nie jest gotowy')
            : goToScreen(item.navigateTo)
        }
      >
        <View style={[tw.mL5, item.img ? styles.image : tw.justifyCenter]}>
          <View style={[tw.pR2, tw.pY4]}>
            <Text variant="small">{item.title}</Text>
            <Text>{item.text}</Text>
            {item.description ? (
              <Text variant="small" style={[tw.mT2]}>
                {item.description}
              </Text>
            ) : (
              false
            )}
          </View>
          {item.img ? (
            <Image
              style={[{ borderRadius: 10 }]}
              source={require('~assets/images/examplePhoto.png')}
              resizeMode="cover"
            />
          ) : (
            false
          )}
        </View>
      </Card>
    ) : (
      <Card style={[tw.pB3]}>
        <View style={[tw.justifyCenter, tw.mL5]}>
          <Text>{item.text}</Text>
          {item.title && <Text variant="text">{item.title}</Text>}
        </View>
      </Card>
    )
  }, [])

  const Footer = () => {
    return (
      <View style={[tw.mB4]}>
        <Text style={[tw.pY5]} variant="text">
          Kliknięcie strzałek pozwala na poprawę lub sprawdzenie informacji i szybki powrót do
          podsumowania.
        </Text>
        <Button
          full
          label="ZGŁOŚ"
          style={[{ backgroundColor: '#00C2FF' }]}
          secondary
          onPress={() => goToScreen('SendVerification')}
        />
      </View>
    )
  }

  return (
    <FlatList
      data={DATA}
      ListEmptyComponent={ListEmptyComponent}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      contentContainerStyle={[tw.minHFull, tw.p4]}
      ItemSeparatorComponent={ItemSeparatorComponent}
      ListFooterComponent={Footer}
      showsVerticalScrollIndicator={false}
    />
  )
}

const styles = {
  container: [tw.flex1, tw.justifyCenter, tw.itemsCenter],
  emptyStateText: [tw.textCenter],
  image: [tw.flexRow, tw.justifyBetween, tw.itemsCenter],
}
