import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigation } from '@react-navigation/native'
import { useCallback, useMemo } from 'react'
import { useForm, Resolver } from 'react-hook-form'
import * as yup from 'yup'
import 'yup-phone'
import yupPassword from 'yup-password'

yupPassword(yup)

type SignUpForm = {
  email: string
  phone: string
  password: string
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  phone: yup.string().phone().required(),
  password: yup.string().password().required(),
})

const resolver: Resolver<SignUpForm> = yupResolver(schema)

export const useSignUpForm = () => {
  const { navigate } = useNavigation()
  const { control, handleSubmit, formState } = useForm<SignUpForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
      phone: '',
    },
  })

  const onValid = useCallback(
    values => {
      // TODO: do not use strings here, or use types
      // TODO: add logic to send verification code
      navigate('AccountVerification')
    },
    [navigate],
  )

  const submit = useMemo(() => handleSubmit(onValid), [handleSubmit, onValid])

  return {
    submit,
    control,
    formState,
  }
}
