import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button, Text } from '~components'
import { Routes } from '~navigation'

import { ImageWrapper } from '~components/ImageWrapper'
import { IncidentSummaryTile } from '~components/IncidentSummaryTile'
import { StatusIndicator } from '~components/StatusIndicator'
import { AbuseTypes } from '~types/AbuseTypes'
import { IncidentTypes } from '~types/IncidentTypes'

const incidentRaised = {
  source: 'zgłoszenie przez formularz',
  date: '01.01.2021',
  time: '12:59',
  currentStatus: ['wysłane'],
  location: 'Miejscowość \nUl. Nazwa Ulicy 41, \n000-00',
  type: IncidentTypes.TRAFFIC_INCIDENT,
  abuseDetails: [AbuseTypes.BEATING],
  species: 'Pies',
  injuredCount: 2,
  images: [
    '~assets/images/examplePhoto.png',
    '~assets/images/examplePhoto.png',
    '~assets/images/examplePhoto.png',
  ],
  description: 'Zgłaszający podczas jazdy potrącił psa.',
  inspectionResult: '',
}

//TODO: to jest potrzebne?
const incidentDone = {
  source: 'zgłoszenie przez formularz',
  date: '01.01.2021',
  time: '12:59',
  currentStatus: ['wysłane', 'przyjęte', 'wykonano inspekcję'],
  location: 'Miejscowość \nUl. Nazwa Ulicy 41, \n000-00',
  type: IncidentTypes.TRAFFIC_INCIDENT,
  abuseDetails: [AbuseTypes.BEATING, AbuseTypes.STARVATION_OR_DEHYTRATION, AbuseTypes.MUTILATION],
  species: 'Pies',
  injuredCount: 2,
  images: ['~assets/images/examplePhoto.png', '~assets/images/examplePhoto.png'],
  description: 'Zgłaszający podczas jazdy potrącił psa.',
  inspectionResult: `Operator dokonał inspekcji na miejscu. Jeżeli jesteś zainteresowany jej wynikiem zadzwoń do organizacji.Pamiętaj jednak, że nasz czas i zasoby ludzkie są mocno ograniczone. Zwierzęta w potrzebie będą Ci wdzięczne, jeżeli pozwolisz nam w tym czasie działać nad kolejnymi zgłoszeniami.`,
}

export const SummaryScreen = () => {
  const { navigate } = useNavigation()
  const goToEditLocationScreen = useCallback(() => navigate(Routes.HistoryEditLocation), [navigate])
  const goToHistorySummaryInformationScreen = useCallback(
    () => navigate(Routes.HistorySummaryInformation),
    [navigate],
  )
  const goToHistorySummaryGalleryScreen = useCallback(
    () => navigate(Routes.HistorySummaryGallery),
    [navigate],
  )

  // utility to make field hidden/visible depending on state, to be removed
  const incident = incidentRaised // | incidentDone
  const noInspectionResult = incident.inspectionResult === ''

  const IncidentSummaryHeader = () => (
    <React.Fragment>
      <View style={styles.leftColumn}>
        <Text variant="small">Podsumowanie zgłoszenia</Text>
        <View>
          <Text variant="small" style={[tw.textBase]}>
            {incident.source}
          </Text>
          <View style={[tw.flexRow, tw.justifyBetween]}>
            <Text variant="small" style={[tw.textBase]}>
              {incident.date}
            </Text>
            <Text variant="small" style={[tw.textBase]}>
              {incident.time}
            </Text>
          </View>
        </View>
      </View>
      <View>
        {['wysłane', 'przyjęte', 'wykonano inspekcję'].map((statusOption, index) => (
          <StatusIndicator
            key={index}
            statusOption={statusOption}
            currentStatus={incident.currentStatus}
          />
        ))}
      </View>
    </React.Fragment>
  )

  const LeftColumn = () => (
    <View style={[tw.w1_4, tw.pR2]}>
      <Text variant="small" style={[tw.pY2]}>
        Zdjęcia
      </Text>
      <View style={[tw.flex, tw.flexRow, tw.flexWrap]}>
        {incident.images.map((image, index) => (
          <View style={[tw.w1_2, index % 2 === 0 ? tw.pR1 : tw.pL1, tw.mB2]} key={index}>
            <ImageWrapper
              imageStyles={styles.image}
              action={noInspectionResult && goToHistorySummaryGalleryScreen}
              actionLabel="Edytuj"
            />
          </View>
        ))}
      </View>
    </View>
  )

  const MiddleColumn = () => (
    <View style={[tw.w1_4, tw.pX2]}>
      <IncidentSummaryTile
        textCentered={false}
        horizontal
        style={[tw.flex1]}
        tileTitle="Lokalizacja"
        action={noInspectionResult && goToEditLocationScreen}
      >
        <Text style={[tw.m2]} variant="small">
          {incident.location}
        </Text>
      </IncidentSummaryTile>
      <IncidentSummaryTile
        horizontal
        style={[tw.pL2, tw.flex0, noInspectionResult ? tw.h16 : tw.h12]}
        tileTitle="Rodzaj zdarzenia"
        action={noInspectionResult && goToHistorySummaryInformationScreen}
      >
        <Text variant="small">{incident.type}</Text>
      </IncidentSummaryTile>

      {incident.type === IncidentTypes.ABUSE ? (
        <IncidentSummaryTile
          horizontal
          style={[tw.pL2, tw.flex1, noInspectionResult ? tw.h40 : '']}
          tileTitle="Szczegóły"
          textCentered={false}
          action={noInspectionResult && goToHistorySummaryInformationScreen}
        >
          <Text style={[tw.pT2]} variant="small">
            {incident.abuseDetails.join(', ')}
          </Text>
        </IncidentSummaryTile>
      ) : null}

      {incident.type === IncidentTypes.ABUSE ? null : (
        <React.Fragment>
          <IncidentSummaryTile
            horizontal
            style={[tw.pL2, noInspectionResult ? tw.h16 : tw.h12]}
            tileTitle="Gatunek"
            action={noInspectionResult && goToHistorySummaryInformationScreen}
          >
            <Text variant="small">Pies</Text>
          </IncidentSummaryTile>
          <IncidentSummaryTile
            horizontal
            style={[tw.pL2, noInspectionResult ? tw.h16 : tw.h12]}
            tileTitle="Liczba zwierząt"
            action={noInspectionResult && goToHistorySummaryInformationScreen}
          >
            <Text variant="small">{incident.injuredCount}</Text>
          </IncidentSummaryTile>
        </React.Fragment>
      )}
    </View>
  )

  const RightColumn = () => (
    <View style={[tw.w2_4, tw.pL2]}>
      <React.Fragment>
        {incident.type === IncidentTypes.ABUSE ? (
          <View style={[tw.flex, tw.flexRow]}>
            <View style={[tw.w1_2, tw.pR2]}>
              <IncidentSummaryTile
                horizontal
                style={[tw.pL2, noInspectionResult ? tw.h16 : tw.h12]}
                tileTitle="Gatunek"
                action={noInspectionResult && goToHistorySummaryInformationScreen}
              >
                <Text variant="small">Pies</Text>
              </IncidentSummaryTile>
            </View>

            <View style={[tw.w1_2, tw.pL2]}>
              <IncidentSummaryTile
                horizontal
                style={[tw.pL2, noInspectionResult ? tw.h16 : tw.h12]}
                tileTitle="Liczba zwierząt"
                action={noInspectionResult && goToHistorySummaryInformationScreen}
              >
                <Text variant="small">{incident.injuredCount}</Text>
              </IncidentSummaryTile>
            </View>
          </View>
        ) : null}
        <IncidentSummaryTile
          textCentered={false}
          tileTitle="Opis"
          action={noInspectionResult && goToHistorySummaryInformationScreen}
          style={[tw.flex1]}
        >
          <Text style={[tw.m2]} variant="small">
            {incident.description}
          </Text>
        </IncidentSummaryTile>
      </React.Fragment>
    </View>
  )

  const InspectionDetails = () => (
    <IncidentSummaryTile style={[tw.p2, tw.flex1]}>
      <View style={[tw.flex, tw.flexRow]}>
        <View style={[tw.w1_2]}>
          <Text>Wynik inpsekcji:</Text>
          <Text variant="small">{incident.inspectionResult}</Text>
        </View>
        <View style={[tw.w1_2, tw.justifyCenter]}>
          <Button
            label="Dzwoń"
            secondary
            style={[tw.mX40, tw.rounded, tw.textWhite, ...styles.callButton]}
          />
        </View>
      </View>
    </IncidentSummaryTile>
  )

  return (
    <View style={[tw.p4, tw.flex1, tw.flex, tw.flexCol]}>
      <View style={styles.headerWrapper}>
        <IncidentSummaryHeader />
      </View>
      <View
        style={[
          styles.incidentDetailsWrapper,
          tw.flex1,
          noInspectionResult ? [{ maxHeight: '496px' }] : '',
        ]}
      >
        <LeftColumn />
        <MiddleColumn />
        <RightColumn />
      </View>

      {!noInspectionResult ? (
        <View style={styles.inspectionDetailsWrapper}>
          <InspectionDetails />
        </View>
      ) : null}
    </View>
  )
}

const styles = {
  headerWrapper: [tw.rounded, tw.bgGray, tw.flexRow, tw.pY4],
  incidentDetailsWrapper: [tw.flexRow, tw.pT1],
  inspectionDetailsWrapper: [tw.mT6, tw.flex, { height: '25%' }],
  leftColumn: [tw.w1_4, tw.mL2, tw.pR16, tw.justifyBetween],
  rightColumn: [tw.w3_4, tw.p2],
  image: [tw.h48, tw.wFull, tw.rounded],
  imageWrapper: [tw.rounded],
  callButton: [{ backgroundColor: '#00C2FF' }],
  halfHeight: [{ height: '50%' }],
}
