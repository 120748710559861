import React from 'react'
import { View, Image } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Text } from './Text'

interface StatusIndicatorProps {
  statusOption: string
  currentStatus: string[]
}

export const StatusIndicator: React.FunctionComponent<StatusIndicatorProps> = ({
  statusOption,
  currentStatus,
}) => {
  return (
    <View style={[tw.flexRow, tw.itemsCenter, tw.pY2]}>
      <View style={[styles.button, currentStatus.includes(statusOption) ? tw.bgMain : tw.bgWhite]}>
        {currentStatus.includes(statusOption) ? (
          <Image source={require('~assets/icons/check.png')} style={styles.checkmark} />
        ) : null}
      </View>
      <Text style={[tw.mL4, tw.textBase]} variant="text">
        {statusOption}
      </Text>
    </View>
  )
}

const styles = {
  button: [tw.w6, tw.h6, tw.rounded, tw.flex, tw.justifyCenter, tw.itemsCenter],
  checkmark: [{ height: 12, width: 14 }],
}
