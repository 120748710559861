import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useMemo } from 'react'
import { useForm, Resolver } from 'react-hook-form'
import * as yup from 'yup'

type DetailsForm = {
  injuredCount: string
  species: string
  description: string
}

const schema = yup.object().shape({
  injuredCount: yup.string().min(1),
  species: yup.string().min(2),
  description: yup.string(),
})

const resolver: Resolver<DetailsForm> = yupResolver(schema)

export const useDetailsForm = () => {
  const { control, handleSubmit, formState } = useForm<DetailsForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      injuredCount: '2',
      species: 'Pies',
      description: 'Zgłaszający podczas jazdy potrącił psa.',
    },
  })

  const onValid = useCallback((values: DetailsForm) => {
    console.log(values)
  }, [])

  const submit = useMemo(() => handleSubmit(onValid), [handleSubmit, onValid])

  return {
    submit,
    control,
    formState,
  }
}
