import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button } from '~components'

import { Card } from './Card'
import { StatusIndicator } from './StatusIndicator'
import { Text } from './Text'

interface HistoryItemProps {
  incidentSource: string
  date: string
  hour: string
  title: string
  currentStatus: string[]
  itemWidth: string
}

export const HistoryItem = (props: HistoryItemProps) => {
  const { navigate } = useNavigation()
  const goToHistorySummary = useCallback(() => navigate('HistorySummary'), [navigate])

  return (
    <Card onPress={goToHistorySummary} style={[...styles.cardWrapper, { width: props.itemWidth }]}>
      <View style={[tw.hAuto, tw.p3, tw.pL4, tw.justifyBetween]}>
        <Text variant="small" style={[tw.textBase]}>
          {props.incidentSource}
        </Text>
        <View style={[tw.flexRow, tw.justifyBetween]}>
          <Text variant="small" style={[tw.textBase]}>
            {props.date}
          </Text>
          <Text variant="small" style={[tw.textBase]}>
            {props.hour}
          </Text>
        </View>
        <Text variant="text" style={[tw.mY3]}>
          {props.title}
        </Text>
        {['wysłane', 'przyjęte', 'wykonano inspekcję'].map((statusOption, index) => (
          <StatusIndicator
            key={index}
            statusOption={statusOption}
            currentStatus={props.currentStatus}
          />
        ))}
      </View>
      <Button onPress={goToHistorySummary} label="wiecej" style={[tw.m4]} />
    </Card>
  )
}

const styles = {
  view: [tw.flexRow, tw.itemsCenter, tw.pY2],
  cardWrapper: [tw.flexCol, tw.rounded, tw.mR6],
  cardHeader: [{ fontSize: '16px' }],
}
