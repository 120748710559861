import { createStackNavigator } from '@react-navigation/stack'
import { getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native'
import React, { useCallback } from 'react'

import { Header } from '~components'
import { DataProvider, PhotosProvider } from '~hooks'
import {
  ChooseFromGalleryScreen,
  ChooseIncidentsScreen,
  Step1Screen,
  Step2Screen,
  Step3Screen,
  TakePhotoScreen,
  PhotoPreviewScreen,
  FormVerificationScreen,
  EnterCodeScreen,
  FeedbackScreen,
  SummaryInformationScreen,
  HistoryAccess,
} from '~screens'
import { SummaryStack } from './SummaryStack'

const Stack = createStackNavigator()

export const RaiseIssueWizardStack = () => {
  const route = useRoute()
  const routeName = getFocusedRouteNameFromRoute(route)

  const headerShown = useCallback(() => {
    if (routeName === 'Feedback') {
      return false
    } else if (routeName === 'Summary') {
      return false
    } else {
      return true
    }
  }, [routeName])

  return (
    <DataProvider>
      <PhotosProvider>
        <Stack.Navigator
          initialRouteName={'Step1'}
          screenOptions={{
            header: props => <Header {...props} iconName="raiseIssue" />,
            title: 'dodaj zdjęcie lub\nfilm do zgłoszenia',
            headerShown: headerShown(),
            gestureEnabled: routeName === 'Feedback' ? false : true,
          }}
        >
          <Stack.Screen name="Step1" component={Step1Screen} />
          <Stack.Screen
            name="ChooseFromGallery"
            component={ChooseFromGalleryScreen}
            options={{
              title: 'wybierz zdjęcia',
            }}
          />
          <Stack.Screen
            name="TakePhoto"
            component={TakePhotoScreen}
            options={{
              title: 'zrób zdjęcie',
            }}
          />
          <Stack.Screen
            name="PhotoPreview"
            component={PhotoPreviewScreen}
            options={{
              title: 'dodaj zdjęcie lub film do zgłoszenia',
            }}
          />
          <Stack.Screen
            name="Step2"
            component={Step2Screen}
            options={{
              title: 'lokalizacja zdarzenia',
            }}
          />
          <Stack.Screen
            name="Step3"
            component={Step3Screen}
            options={{
              title: 'Wybierz rodzaj zdarzenia',
            }}
          />
          <Stack.Screen
            name="ChooseIncident"
            component={ChooseIncidentsScreen}
            options={{
              title: 'Zaznacz rodzaj zdarzenia',
            }}
          />
          <Stack.Screen
            name="EnterInformation"
            component={SummaryInformationScreen}
            options={{
              title: 'wpisz informacje',
            }}
          />
          <Stack.Screen
            name="Summary"
            component={SummaryStack}
            options={{ gestureEnabled: false }}
          />
          <Stack.Screen
            name="SendVerification"
            options={{
              title: 'weryfikacja',
            }}
            component={FormVerificationScreen}
          />
          <Stack.Screen
            name="EnterVerificationCode"
            options={{
              title: 'weryfikacja',
            }}
            component={EnterCodeScreen}
          />
          <Stack.Screen name="Feedback" component={FeedbackScreen} />
          <Stack.Screen
            name="HistoryAccess"
            component={HistoryAccess}
            options={{ title: 'weryfikacja' }}
          />
        </Stack.Navigator>
      </PhotosProvider>
    </DataProvider>
  )
}
