import React, { createContext, useState, useContext } from 'react'

const DataContext = createContext({})

export const DataProvider: React.FC = ({ children }) => {
  const [data, setData] = useState({})

  const setValues: (values: Record<string, unknown>) => void = values => {
    setData(prevData => ({
      ...prevData,
      ...values,
    }))
  }

  return <DataContext.Provider value={{ data, setValues }}>{children}</DataContext.Provider>
}

export const useData = () => useContext(DataContext)
