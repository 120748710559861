import React from 'react'
import { View } from 'react-native'
import { InformationFirstAidScreen } from './InformationFirstAidScreen'
import { InformationPetScreen } from './InformationPetScreen'
import { tw } from 'react-native-tailwindcss'
import { Text, Button } from '~components'

export const InformationAdvicesScreen = () => {
  return (
    <View style={styles.container}>
      <View style={[tw.flexRow]}>
        <View style={styles.column}>
          <Text style={styles.title}>Obserwuj swoje zwierzę</Text>
          <InformationPetScreen />
        </View>
        <View style={styles.column}>
          <Text style={styles.title}>Pierwsza pomoc</Text>
          <InformationFirstAidScreen />
        </View>
        <View style={styles.column}>
          <Text style={styles.title}>Informacje prawne</Text>
          <Button label={'informacje prawne'} full onPress={() => {}} />
        </View>
      </View>
    </View>
  )
}
const styles = {
  container: [tw.flexRow, tw.justifyCenter, tw.itemsCenter, tw.flex1],
  column: [tw.flex1, tw.pX5, tw.pY5],
  title: [tw.mB4],
}
