import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import { Button, Header } from '~components'
import { Routes, NavigatorParams } from './types'
import { RouteParamsMap } from './RouteParamsMap'
import {
  AccountMainScreen,
  SignInScreen,
  SignUpScreen,
  SignInWebScreen,
  SignUpVerificationScreen,
  VerificationScreen,
} from '~screens'
import { isWeb } from '~helpers'

type AccountStackRoutes =
  | Routes.AccountMain
  | Routes.AccountSignIn
  | Routes.AccountSignUp
  | Routes.AccountVerification
type AccountStackParamsMap = Pick<RouteParamsMap, AccountStackRoutes>

export type AccountStackNavParams = NavigatorParams<AccountStackParamsMap, AccountStackRoutes>

const Stack = createStackNavigator<AccountStackParamsMap>()

export const AccountStack = () => (
  <Stack.Navigator
    initialRouteName={Routes.AccountSignIn}
    screenOptions={{
      header: props => <Header {...props} iconName="account" />,
      headerShown: isWeb ? false : true,
    }}
  >
    <Stack.Screen
      name={Routes.AccountSignIn}
      component={isWeb ? SignInWebScreen : SignInScreen}
      options={{
        title: 'zaloguj się',
      }}
    />
    <Stack.Screen
      name={Routes.AccountSignUp}
      component={SignUpScreen}
      options={{
        title: 'zarejestruj się',
      }}
    />
    <Stack.Screen
      name={Routes.AccountVerification}
      options={{
        title: 'weryfikacja',
      }}
      component={isWeb ? VerificationScreen : SignUpVerificationScreen}
    />
    <Stack.Screen
      name={Routes.AccountMain}
      component={AccountMainScreen}
      options={{
        title: 'profil',
        headerRight: () => <Button label="Wyloguj" />,
      }}
    />
  </Stack.Navigator>
)
