import React, { FunctionComponent } from 'react'
import { Platform, ScrollView as RNScrollView, ScrollViewProps } from 'react-native'
import { tw } from 'react-native-tailwindcss'

export const ScrollView: FunctionComponent<ScrollViewProps> = props => {
  return (
    <RNScrollView
      contentContainerStyle={styles.contentContainerStyle}
      style={styles.container}
      keyboardShouldPersistTaps={'always'}
      keyboardDismissMode={Platform.OS === 'android' ? 'on-drag' : 'interactive'}
      showsVerticalScrollIndicator={false}
      {...props}
    />
  )
}

const styles = {
  // container: [tw.pT10],
  contentContainerStyle: [tw.pX4, tw.pT6],
}
