import { BottomTabBarOptions, BottomTabBarProps } from '@react-navigation/bottom-tabs'
import React, { FunctionComponent } from 'react'
import { View, Image } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { isSmall } from '~helpers'
import { Routes } from '../navigation/types'

import { Pressable } from './Pressable'

type Props = BottomTabBarProps<BottomTabBarOptions>

const icons = {
  account: require('~assets/icons/account.png'),
  history: require('~assets/icons/history.png'),
  info: require('~assets/icons/info.png'),
  raiseIssue: require('~assets/icons/raiseIssue.png'),
}

export const TabBar: FunctionComponent<Props> = ({ state, descriptors, navigation }) => {
  const focusedOptions = descriptors[state.routes[state.index].key].options

  if (focusedOptions.tabBarVisible === false) {
    return null
  }

  return (
    <View style={{ flexDirection: 'row', height: isSmall ? 74 : 84, backgroundColor: 'white' }}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key]
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name

        const isFocused = state.index === index

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          })

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name)
          }
        }

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          })
        }

        const icon = {
          [Routes.AccountStack]: icons.account,
          [Routes.InformationStack]: icons.info,
          [Routes.HistoryStack]: icons.history,
          [Routes.RaiseIssueStack]: icons.raiseIssue,
        }[route.name]

        const tintColor = isFocused ? '#FFFC10' : '#000000'

        return (
          <Pressable
            key={route.key}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={[tw.bgTransparent, tw.flex1, tw.hFull, tw.itemsCenter, tw.justifyCenter]}
          >
            <Image
              source={icon}
              style={{
                tintColor,
              }}
            />
          </Pressable>
        )
      })}
    </View>
  )
}
