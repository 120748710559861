import React, { forwardRef, FunctionComponent, ReactElement } from 'react'
import { useController, Control } from 'react-hook-form'
import { TextInput, TextInputProps, View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Input } from './Input'
import { Text } from './Text'

type FieldProps = {
  // TODO: don't know how to fix this @tmrovsky
  control: Control<any>
  name: string
  renderField: (field: any) => ReactElement
  style?: string[]
  inputStyle?: any[]
}

type FieldPropsWithoutRenderField = Omit<FieldProps, 'renderField'>
type FieldPropsWithTextInput = FieldPropsWithoutRenderField & TextInputProps

type IFieldComposition = {
  Phone: typeof PhoneField
  Email: typeof EmailField
  Password: typeof PasswordField
  EmailOrPhone: typeof EmailOrPhoneField
  Text: typeof TextField
}

export const Field: FunctionComponent<FieldProps> & IFieldComposition = ({
  name,
  control,
  renderField,
  style = [],
}) => {
  const { field, fieldState } = useController({
    control,
    name,
  })

  return (
    <View style={[tw.mB4, ...style]}>
      {renderField(field)}
      {fieldState.error && (
        <Text variant="small" style={[tw.textRed200, tw.mT2]}>
          {fieldState.error.message}
        </Text>
      )}
    </View>
  )
}

const PhoneField = forwardRef<TextInput, FieldPropsWithTextInput>(
  ({ control, name = 'phone', style = [], ...textInputProps }, ref) => {
    return (
      <Field
        name={name}
        control={control}
        style={style}
        renderField={field => (
          <Input
            ref={ref}
            placeholder={'numer telefonu'}
            value={field.value}
            onChangeText={field.onChange}
            onBlur={field.onBlur}
            textContentType="telephoneNumber"
            keyboardType="phone-pad"
            {...textInputProps}
          />
        )}
      />
    )
  },
)

const EmailField = forwardRef<TextInput, FieldPropsWithTextInput>(
  ({ control, name = 'email', style = [], ...textInputProps }, ref) => {
    return (
      <Field
        name={name}
        control={control}
        style={style}
        renderField={field => (
          <Input
            ref={ref}
            placeholder={'adres email'}
            value={field.value}
            onChangeText={field.onChange}
            onBlur={field.onBlur}
            textContentType="emailAddress"
            {...textInputProps}
          />
        )}
      />
    )
  },
)

const EmailOrPhoneField = forwardRef<TextInput, FieldPropsWithTextInput>(
  ({ control, name = 'emailOrPhone', style = [], ...textInputProps }, ref) => {
    return (
      <Field
        name={name}
        control={control}
        style={style}
        renderField={field => (
          <Input
            ref={ref}
            placeholder={'adres email lub numer telefonu'}
            value={field.value}
            onChangeText={field.onChange}
            onBlur={field.onBlur}
            {...textInputProps}
          />
        )}
      />
    )
  },
)

const PasswordField = forwardRef<TextInput, FieldPropsWithTextInput>(
  ({ control, name = 'password', style = [], ...textInputProps }, ref) => {
    return (
      <Field
        name={name}
        control={control}
        style={style}
        renderField={field => (
          <Input
            ref={ref}
            placeholder={'hasło'}
            value={field.value}
            onChangeText={field.onChange}
            onBlur={field.onBlur}
            textContentType="password"
            secureTextEntry
            {...textInputProps}
          />
        )}
      />
    )
  },
)

const TextField = forwardRef<TextInput, FieldPropsWithTextInput>(
  ({ control, name, style = [], inputStyle = [], ...textInputProps }, ref) => {
    return (
      <Field
        name={name}
        control={control}
        style={style}
        renderField={field => (
          <Input
            ref={ref}
            value={field.value}
            onChangeText={field.onChange}
            onBlur={field.onBlur}
            style={inputStyle}
            {...textInputProps}
          />
        )}
      />
    )
  },
)

Field.Phone = PhoneField
Field.Email = EmailField
Field.Password = PasswordField
Field.EmailOrPhone = EmailOrPhoneField
Field.Text = TextField
