import {
  getAssetsAsync,
  Asset,
  SortBy,
  getPermissionsAsync,
  requestPermissionsAsync,
  presentPermissionsPickerAsync,
  PermissionResponse,
} from 'expo-media-library'
import { AnimatePresence, View } from 'moti'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Dimensions, FlatList, Image, Linking, StyleSheet } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { useNavigation } from '@react-navigation/native'

import { Button, Pressable, Text, Stepper } from '~components'

const ListHeaderComponent = () => {
  const [permissions, setPermissions] = useState<PermissionResponse>()
  useEffect(() => {
    getPermissionsAsync().then(setPermissions)
  }, [])

  if (!permissions || permissions?.accessPrivileges !== 'limited') {
    return null
  }

  return (
    <Text variant="small" style={[tw.mY4]}>
      Aplikacja Animal Helper uzyskała zgodę na dostęp do wybranej liczby zdjęć i filmów.{' '}
      <Text color="link" variant="small" onPress={presentPermissionsPickerAsync}>
        Wybierz więcej zdjęć
      </Text>{' '}
      lub{' '}
      <Text color="link" variant="small" onPress={Linking.openSettings}>
        zmień ustawienia
      </Text>
    </Text>
  )
}

const Overlay: FunctionComponent<{ selected: number }> = ({ selected }) => {
  return (
    <AnimatePresence>
      {selected !== -1 && (
        <View
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{
            opacity: 0,
          }}
          style={[
            StyleSheet.absoluteFillObject,
            { backgroundColor: '#FFFC107f' },
            tw.roundedLg,
            tw.justifyCenter,
            tw.itemsCenter,
          ]}
        >
          <Text variant="big" color="main">
            {selected + 1}
          </Text>
        </View>
      )}
    </AnimatePresence>
  )
}

const size = (Dimensions.get('screen').width - 3 * 16) / 2

const Photo: FunctionComponent<{
  asset: Asset
  selected: number
  onPress: (asset: Asset) => void
}> = ({ asset, selected, onPress }) => {
  return (
    <Pressable
      style={[tw.bgRed200, tw.roundedLg, tw.relative, { height: size, width: size }]}
      onPress={() => onPress(asset)}
    >
      <Image
        source={{
          uri: asset.uri,
        }}
        style={[
          {
            width: size,
            height: size,
          },
          tw.roundedLg,
        ]}
      />
      <Overlay selected={selected} />
    </Pressable>
  )
}

export const ChooseFromGalleryScreen = () => {
  const [assetItems, setItems] = useState<Asset[]>([])
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([])
  const keyExtractor = useCallback((item, index) => `photo-${index}`, [])
  const { navigate } = useNavigation()

  const toggleSelected = useCallback(
    (asset: Asset) => {
      if (selectedAssets.find(selectedAsset => selectedAsset.id === asset.id)) {
        setSelectedAssets(selectedAssets.filter(selectedAsset => selectedAsset.id !== asset.id))
      } else {
        setSelectedAssets([...selectedAssets, asset])
      }
    },
    [selectedAssets],
  )

  const getAssets = useCallback(async () => {
    const permissions = await getPermissionsAsync()
    if (!permissions.granted) {
      await requestPermissionsAsync()
    }
    const pagedInfo = await getAssetsAsync({
      first: 100,
      sortBy: SortBy.creationTime,
    })
    setItems(pagedInfo.assets)
  }, [])

  useEffect(() => {
    getPermissionsAsync().then(async permissions => {
      if (permissions.canAskAgain) {
        await requestPermissionsAsync()
      }
    })
  })

  useEffect(() => {
    getAssets()
  }, [getAssets])

  const renderItem = useCallback(
    ({ item: asset }) => {
      return (
        <Photo
          asset={asset}
          selected={selectedAssets.findIndex(selectedAsset => selectedAsset.id === asset.id)}
          onPress={toggleSelected}
        />
      )
    },
    [selectedAssets, toggleSelected],
  )

  const goToStep2 = useCallback(() => navigate('Step2'), [navigate])

  return (
    <>
      <Stepper activeIndex={0} />
      <FlatList
        data={assetItems}
        numColumns={2}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListHeaderComponent={ListHeaderComponent}
        columnWrapperStyle={[tw.mB4, tw.w100, tw.justifyBetween]}
        contentContainerStyle={[tw.mX4, tw.pB24]}
        onEndReachedThreshold={0.5}
        initialNumToRender={50}
      />
      <View style={[tw.absolute, tw.bottom0, tw.left0, tw.right0, tw.mB8, tw.mX4]}>
        <Button
          label={`dodaj zdjęci${selectedAssets.length === 1 ? 'e' : 'a'}`}
          disabled={selectedAssets.length === 0}
          onPress={goToStep2}
        />
      </View>
    </>
  )
}
