import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigation } from '@react-navigation/native'
import { useCallback, useMemo } from 'react'
import { useForm, Resolver } from 'react-hook-form'
import * as yup from 'yup'
import 'yup-phone'
import yupPassword from 'yup-password'

import { serverApi } from '~services'

import { LoginDto } from '../commons/src/generated-api'

yupPassword(yup)

type SignInForm = {
  username: string
  password: string
}

// TODO: what about phone?
// const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/

const schema = yup.object().shape({
  // phone: yup.lazy(value =>
  //   !value.match(phoneRegex)
  //     ? yup.string().email('Email must be a valid email.').required('Please enter email or phone.')
  //     : yup.string().phone().required(),
  // ),
  username: yup.string().required(),
  password: yup.string().required(),
})

const resolver: Resolver<SignInForm> = yupResolver(schema)

export const useSignInForm = () => {
  const { navigate } = useNavigation()
  const { control, handleSubmit, formState } = useForm<LoginDto>({
    resolver,
    mode: 'all',
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const onValid = useCallback(
    async values => {
      // TODO: do not use strings here, or use types
      // TODO: add logic to send verification code
      console.log(values)
      try {
        const x = await serverApi.authApi.loginUsingPOST({
          body: values,
        })
        console.log(x)
        navigate('AccountVerification')
      } catch (error) {
        console.log(error)
        console.error('sorry, something went wrong!')
      }
    },
    [navigate],
  )

  const submit = useMemo(() => handleSubmit(onValid), [handleSubmit, onValid])

  return {
    submit,
    control,
    formState,
  }
}
