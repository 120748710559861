import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Platform } from 'react-native'

import { MakeCallScreen } from '~screens'

import { MainNavigator } from './MainNavigator'
import { RaiseIssueWizardStack } from './RaiseIssueWizardStack'

const Stack = createStackNavigator()

export const RootStack = () => {
  return (
    <Stack.Navigator mode="modal" headerMode="none" initialRouteName="Main">
      <Stack.Screen name="Main" component={MainNavigator} />
      <Stack.Screen
        name="RaiseIssueForm"
        component={RaiseIssueWizardStack}
        options={
          Platform.OS === 'ios'
            ? {
                ...TransitionPresets.ModalPresentationIOS,
              }
            : {}
        }
      />
      <Stack.Screen
        name="RaiseIssuePhone"
        component={MakeCallScreen}
        options={
          Platform.OS === 'ios'
            ? {
                ...TransitionPresets.ModalPresentationIOS,
              }
            : {}
        }
      />
    </Stack.Navigator>
  )
}
