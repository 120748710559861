module.exports = {
  theme: {
    extend: {
      colors: {
        transparent: 'transparent',
        main: '#FFFC10',
        black: '#000000',
        white: '#FFFFFF',
        link: '#00C2FF',
        lightGray: '#F9F9F9',
        gray: '#E5E5E5',
        darkGray: '#B7B7B7',
        darkerGray: '#939393',
        green: '#10FF70',
      },
      fontFamily: {
        display: 'Poppins_400Regular',
        displayBold: 'Poppins_600SemiBold',
        note: 'RobotoMono_400Regular',
      },
    },
  },
  variants: {},
  plugins: [],
}
