import { useEffect, useState } from 'react'

import { getMobileOS } from '../helpers/getMobileOS'

export const useDemoApp = () => {
  const [os, setOs] = useState<undefined | string>(undefined)
  useEffect(() => {
    setOs(getMobileOS())
  }, [])

  return os
}
