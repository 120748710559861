import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { tw } from 'react-native-tailwindcss'
import { isWeb } from '~helpers'

import { Button, ScrollView } from '~components'

export const InformationFirstAidScreen = () => {
  const { navigate } = useNavigation()

  const goToInformationPetScreen = useCallback(
    (id: number | undefined) => navigate('PetHelpScreen', { id: id, arr: 1 }),
    [navigate],
  )

  const data = [
    { label: 'krwotok lub krwawienie', id: 0 },
    { label: 'upał', id: 3 },
    { label: 'uwaga upał!', id: 5 },
    { label: 'poparzenia', id: 7 },
    { label: 'odmrożenie', id: 8 },
    { label: 'udar cieplny', id: 9 },
  ]
  return (
    <ScrollView contentContainerStyle={styles.contentContainerStyle}>
      {data?.map((item, index) => (
        <Button
          key={item.label}
          style={styles.space}
          label={item.label}
          full
          big={isWeb ? false : true}
          onPress={() => goToInformationPetScreen(item.id)}
        />
      ))}
    </ScrollView>
  )
}

const styles = {
  contentContainerStyle: !isWeb && [tw.pX4],
  space: [tw.mB4, tw.textCenter],
}
