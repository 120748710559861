import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { View, SafeAreaView } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { isWeb } from '~helpers'

import { Button, ScrollView } from '~components'

export const InformationPetScreen = () => {
  const { navigate } = useNavigation()

  const goToInformationPetScreen = useCallback(
    id => navigate('PetHelpScreen', { id: id, arr: 2 }),
    [navigate],
  )

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <Button
          style={styles.space}
          label="temperatura"
          full
          big={isWeb ? false : true}
          onPress={() => {
            goToInformationPetScreen(0)
          }}
        />
        <View style={styles.containerButtons}>
          <Button
            style={[styles.space, styles.button]}
            label="puls"
            full
            big={isWeb ? false : true}
            onPress={() => {
              goToInformationPetScreen(1)
            }}
          />
          <Button
            style={[styles.space, styles.button]}
            label="oddech"
            full
            big={isWeb ? false : true}
            onPress={() => {
              goToInformationPetScreen(2)
            }}
          />
        </View>
        <Button
          style={styles.space}
          label="błony śluzowe"
          full
          big={isWeb ? false : true}
          onPress={() => goToInformationPetScreen(3)}
        />
        <View style={styles.containerButtons}>
          <Button
            style={[styles.space, styles.button]}
            label="skóra"
            full
            big={isWeb ? false : true}
            onPress={() => goToInformationPetScreen(4)}
          />
          <Button
            style={[styles.space, styles.button]}
            label="oczy"
            full
            big={isWeb ? false : true}
            onPress={() => goToInformationPetScreen(5)}
          />
        </View>
        <View style={styles.containerButtons}>
          <Button
            style={[styles.space, styles.button]}
            label="uszy"
            full
            big={isWeb ? false : true}
            onPress={() => goToInformationPetScreen(6)}
          />
          <Button
            style={[styles.space, styles.button]}
            label="nos"
            full
            big={isWeb ? false : true}
            onPress={() => goToInformationPetScreen(7)}
          />
        </View>
        <Button
          style={styles.space}
          label="klatka piersiowa"
          full
          big={isWeb ? false : true}
          onPress={() => goToInformationPetScreen(8)}
        />
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = {
  container: isWeb ? [] : [tw.justifyCenter, tw.itemsCenter, tw.flex1, tw.pX4],
  space: isWeb ? [tw.mB4] : [tw.mB4, tw.h20],
  button: [{ width: '48%' }],
  containerButtons: [tw.flexRow, tw.justifyBetween],
  contentContainerStyle: isWeb ? [] : [tw.mT4],
}
