import { useNavigation } from '@react-navigation/native'
import React, { MutableRefObject, useCallback, useRef, useState } from 'react'
import { TextInput, View } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Button } from './Button'
import { Field } from './Field'
import { useDetailsForm } from '~hooks'
import { Routes } from '../navigation/types'

import { IncidentSummaryTile } from './IncidentSummaryTile'
import { AbuseTypes } from '~types/AbuseTypes'
import { IncidentTypes } from '~types/IncidentTypes'

const incident = {
  source: 'zgłoszenie przez formularz',
  date: '01.01.2021',
  time: '12:59',
  currentStatus: ['wysłane'],
  location: 'Miejscowość \nUl. Nazwa Ulicy 41, \n000-00',
  type: IncidentTypes.TRAFFIC_INCIDENT,
  species: 'Pies',
  injuredCount: '2',
  images: ['~assets/images/examplePhoto.png', '~assets/images/examplePhoto.png'],
  description: 'Zgłaszający podczas jazdy potrącił psa.',
  inspectionResult: `Operator dokonał inspekcji na miejscu.
  Jeżeli jesteś zainteresowany jej wynikiem zadzwoń do organizacji.
  Pamiętaj jednak, że nasz czas i zasoby ludzkie są mocno ograniczone.
  Zwierzęta w potrzebie będą Ci wdzięczne, jeżeli pozwolisz nam
  w tym czasie działać nad kolejnymi zgłoszeniami.`,
}

interface Options {
  [key: string]: string
}

export const DetailsForm = () => {
  const [selectedIncidentType, setSelectedIncidentType] = useState(incident.type)
  const [selectedAbuseType, setSelectedAbuseType] = useState<string[]>([])
  const injuredCountRef = useRef<TextInput>() as MutableRefObject<TextInput>
  const speciesRef = useRef<TextInput>() as MutableRefObject<TextInput>
  const descriptionRef = useRef<TextInput>() as MutableRefObject<TextInput>

  const { control } = useDetailsForm()

  const { navigate } = useNavigation()
  const goToHistorySummary = useCallback(() => navigate(Routes.HistorySummary), [navigate])

  const renderOptions = useCallback((options: Options, onSelect, compareTo: string | string[]) => {
    return Object.values(options).map((option, index) => {
      const isLastItem = index + 1 === Object.values(options).length
      const isSelected = Array.isArray(compareTo)
        ? compareTo.includes(option)
        : compareTo === option

      return (
        <Button
          key={option}
          label={option}
          secondary={isSelected}
          full
          style={[
            tw.flex1,
            tw.rounded,
            tw.textCenter,
            !isLastItem ? tw.mB2 : '',
            isSelected ? [tw.bgBlack] : '',
          ]}
          onPress={() => onSelect(option)}
        />
      )
    })
  }, [])

  const handleAbuseTypeSelect = (inputType: string) => {
    let newTypes
    if (selectedAbuseType.includes(inputType)) {
      newTypes = selectedAbuseType.filter(type => type !== inputType)
    } else {
      newTypes = selectedAbuseType.concat(inputType)
    }
    setSelectedAbuseType(newTypes)
  }

  const LeftColumn = () => (
    <React.Fragment>
      <View style={[tw.w1_4, tw.pR2, tw.flex, tw.flexCol, ...styles.halfHeight]}>
        <IncidentSummaryTile
          tileTitle="Rodzaj zdarzenia"
          withBackground={false}
          style={[tw.flex1]}
          textCentered={false}
        >
          {renderOptions(IncidentTypes, setSelectedIncidentType, selectedIncidentType)}
        </IncidentSummaryTile>
      </View>
      {selectedIncidentType === IncidentTypes.ABUSE ? (
        <View style={[tw.w1_4, tw.pX2, tw.mB8, tw.flex1]}>
          <IncidentSummaryTile
            tileTitle="Rodzaj znęcania"
            withBackground={false}
            style={[tw.flex1]}
            textCentered={false}
          >
            {renderOptions(AbuseTypes, handleAbuseTypeSelect, selectedAbuseType)}
          </IncidentSummaryTile>
        </View>
      ) : null}
    </React.Fragment>
  )
  const MiddleColumn = () => (
    <View style={[tw.w1_4, tw.pX2, tw.flex, tw.flexCol, ...styles.halfHeight]}>
      <IncidentSummaryTile tileTitle="Liczba zwierząt" style={[tw.h20]} textCentered={false}>
        <Field.Text
          name="injuredCount"
          control={control}
          ref={injuredCountRef}
          style={[tw.p2, tw.hFull]}
          inputStyle={[...styles.input, tw.flex1]}
        />
      </IncidentSummaryTile>

      <IncidentSummaryTile
        tileTitle="Wpisz gatunek zwierzęcia"
        style={[tw.flex1]}
        textCentered={false}
      >
        <Field.Text
          name="species"
          control={control}
          ref={speciesRef}
          multiline={true}
          style={[tw.p2, tw.hFull]}
          inputStyle={[...styles.input, tw.flex1]}
        />
      </IncidentSummaryTile>
    </View>
  )
  const RightColumn = () => (
    <View
      style={[
        selectedIncidentType === IncidentTypes.ABUSE ? tw.w1_4 : tw.w2_4,
        tw.pL2,
        ...styles.halfHeight,
      ]}
    >
      <IncidentSummaryTile textCentered={false} style={[tw.flex1]} tileTitle="Opis zdarzenia">
        <Field.Text
          name="description"
          control={control}
          ref={descriptionRef}
          multiline={true}
          style={[tw.p2, ...styles.input, tw.hFull]}
          inputStyle={[...styles.input, tw.flex1]}
        />
      </IncidentSummaryTile>
    </View>
  )

  return (
    <React.Fragment>
      <View style={[tw.pY4, tw.flexRow, tw.pT1, tw.pT20, tw.flex1]}>
        <LeftColumn />
        <MiddleColumn />
        <RightColumn />
      </View>
      <Button
        label="Wróć do podsumowania zgłoszenia"
        style={[tw.mY4, tw.rounded]}
        onPress={() => {
          // submit here?
          goToHistorySummary()
        }}
      />
    </React.Fragment>
  )
}

const styles = {
  textContainer: [tw.mY5, tw.itemsCenter],
  halfHeight: [{ height: '50%' }],
  input: [{ outline: 'none' }],
}
