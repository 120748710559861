import React, { FC } from 'react'
import { View } from 'react-native'
import { Button } from './Button'
import { Text } from './Text'
import { tw } from 'react-native-tailwindcss'

type WebCardProps = {
  title: string
  description: string
  label: string
  onPress: () => void
  full?: boolean
}

export const WebCard: FC<WebCardProps> = ({ title, description, label, onPress, full = false }) => {
  return (
    <View style={styles.container}>
      <Text>{title}</Text>
      <View style={styles.spacer}>
        <Text variant="small">{description}</Text>
      </View>
      <View style={styles.spacer}>
        <Button label={label} full onPress={onPress} />
      </View>
    </View>
  )
}

const styles = {
  container: [tw.w64, tw.justifyEnd],
  spacer: [{ marginTop: 10 }],
}
