import {
  CommonActions,
  NavigationAction,
  NavigationContainerRef,
  StackActions,
} from '@react-navigation/native'
import React from 'react'

import { RouteParamsMap, Route } from '~navigation'

const _navigatorRef = React.createRef<NavigationContainerRef>()

function getTopLevelNavigatorRef() {
  return _navigatorRef
}

function assertNavigatorReady() {
  if (!_navigatorRef.current) {
    throw new Error('Navigation ref is undefined. Navigation is not possible')
  }
}

function dispatch(action: NavigationAction) {
  assertNavigatorReady()
  _navigatorRef.current?.dispatch(action)
}

function navigate<T extends Route>(routeName: RouteParamsMap[T] extends undefined ? T : never): void
function navigate<T extends Route>(roteName: T, params?: RouteParamsMap[T]): void
function navigate(...args: any[]) {
  assertNavigatorReady()
  dispatch(
    CommonActions.navigate({
      name: args[0],
      params: args[1],
    }),
  )
}

function replace<T extends Route>(routeName: RouteParamsMap[T] extends undefined ? T : never): void
function replace<T extends Route>(roteName: T, params?: RouteParamsMap[T]): void
function replace(...args: any[]) {
  assertNavigatorReady()
  dispatch(StackActions.replace(args[0], args[1]))
}

function pop(n: number) {
  assertNavigatorReady()
  dispatch(StackActions.pop(n))
}

function goBack() {
  assertNavigatorReady()
  dispatch(CommonActions.goBack())
}

function resetNavigation<T extends Route>(
  routeName: RouteParamsMap[T] extends undefined ? T : never,
): void
function resetNavigation<T extends Route>(roteName: T, params?: RouteParamsMap[T]): void
function resetNavigation(...args: any[]) {
  assertNavigatorReady()
  dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name: args[0], params: args[1] }],
    }),
  )
}

export const NavigationService = {
  getTopLevelNavigatorRef,
  goBack,
  pop,
  navigate,
  replace,
  resetNavigation,
}
