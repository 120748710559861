import { createDrawerNavigator, DrawerItemList } from '@react-navigation/drawer'
import React from 'react'
import { NavigationTheme } from './NavigationTheme'

import { RaiseIssueStack } from './RaiseIssueStack'
import { AccountStack } from './AccountStack'
import { InformationStack } from './InformationStack'
import { HistoryStack } from './HistoryStack'
import { Image, View } from 'react-native'
import { SafeAreaView } from 'react-navigation'
import { Logo } from '~components'

const { Navigator, Screen } = createDrawerNavigator()

const menu = [
  {
    name: 'Zgłoś zdarzenie',
    image: require('~assets/icons/raiseIssue.png'),
    stack: RaiseIssueStack,
  },
  {
    name: 'Profil',
    image: require('~assets/icons/account.png'),
    stack: AccountStack,
  },
  {
    name: 'Informacje',
    image: require('~assets/icons/info.png'),
    stack: InformationStack,
  },
  {
    name: 'Historia zgłoszeń',
    image: require('~assets/icons/history.png'),
    stack: HistoryStack,
  },
]

export const MainNavigator = () => {
  return (
    <Navigator
      openByDefault={true}
      drawerType="permanent"
      drawerStyle={{
        backgroundColor: NavigationTheme.colors.primary,
      }}
      drawerContentOptions={{
        activeBackgroundColor: 'white',
        activeTintColor: 'black',
        itemStyle: {
          marginHorizontal: 0,
          paddingLeft: 50,
          height: 70,
          justifyContent: 'center',
          width: '101%',
        },
      }}
      screenOptions={{
        gestureEnabled: false,
        swipeEnabled: false,
      }}
      drawerContent={props => {
        return (
          <SafeAreaView style={{ flex: 1 }}>
            <View
              style={{
                height: 100,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Logo />
            </View>
            <DrawerItemList {...props} />
          </SafeAreaView>
        )
      }}
    >
      {menu.map(item => {
        return (
          <Screen
            key={item.name}
            component={item.stack}
            name={item.name}
            options={{
              drawerIcon: () => (
                <Image source={item.image} style={{ width: 30, height: 30 }} resizeMode="center" />
              ),
            }}
          />
        )
      })}
    </Navigator>
  )
}
