import React from 'react'
import { View } from 'react-native'
import { Button, Input, Text, ScrollView } from '~components'
import { tw } from 'react-native-tailwindcss'

export const HistoryAccess = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.containerBox}>
        <Text variant="big" style={styles.space}>
          dostęp do historii zgłoszeń
        </Text>
        <Text variant="small" style={styles.space}>
          podaj adres e-mail aby otrzymać link weryfikacyjny
        </Text>
        <View style={[tw.w2_3]}>
          <Input style={styles.space} />
          <Text variant="small" style={[...styles.space, tw.textCenter]}>
            wyrażam zgodę na kontakt w razie potrzeby ze strony operatora Animal Helper
          </Text>
        </View>
        <View style={[tw.w1_2]}>
          <Button big full label="zgadzam się" style={[{ backgroundColor: '#10FF70' }]} />
        </View>
      </View>
    </ScrollView>
  )
}

const styles = {
  container: [tw.itemsCenter, tw.justifyCenter, tw.flex1],
  containerBox: [tw.w8_12, tw.itemsCenter],
  space: [tw.mB4],
}
