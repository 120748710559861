import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import React, { useCallback, useEffect } from 'react'
import { tw } from 'react-native-tailwindcss'

import { Button, Text } from '~components'

export const MissedCallScreen = () => {
  const { navigate } = useNavigation()
  const goToMakeCallOtherScreen = useCallback(() => navigate('RaiseIssuePhoneMakeCallOther'), [
    navigate,
  ])
  const goToRaiseIssueForm = useCallback(() => navigate('Step1'), [navigate])

  useEffect(() => {
    alert(
      'Ten ekran pojawia się kiedy nie uda się uzyskać odpowiedzi. W wersji pokazowej aplikacji nie możemy zadzwonić do operatorów.',
    )
  }, [])

  return (
    <View style={styles.container}>
      <View style={styles.containerBox}>
        <Text variant="big" style={styles.space}>
          Brak odpowiedzi ...
        </Text>
        <Text variant="small" style={styles.spaceBig}>
          Przepraszamy, że nie możesz się teraz z nami połączyć, ale obsługujemy właśnie inne
          zgłoszenie.
        </Text>
        <Text style={styles.spaceBig}>Co możesz zrobić?</Text>
        <Button
          full
          big
          label="zadzwoń bezpośrednio do instytucji publicznej"
          onPress={goToMakeCallOtherScreen}
          style={[styles.space, tw.textCenter]}
        />
        <Text variant="small" style={styles.spaceBig}>
          Numery do właściwych dla Twojego regionu rozwiniesz klikając w przycisk powyżej.
        </Text>
        <Button
          full
          big
          label="zgłoś przez formularz"
          onPress={goToRaiseIssueForm}
          style={styles.space}
        />
        <Text variant="small" style={[tw.textCenter]}>
          Kiedy zgłosisz zdarzenie przez nasz formularz, zespół zajmie się nim bezzwłocznie.
        </Text>
      </View>
    </View>
  )
}

const styles = {
  container: [tw.itemsCenter, tw.justifyCenter, tw.flex1],
  containerBox: [tw.w5_12],
  spaceBig: [tw.mB10, tw.textCenter],
  space: [tw.mB4],
}
