import React, { FC } from 'react'
import { tw } from 'react-native-tailwindcss'

import { Button } from './Button'
import { Input } from './Input'
import { ScrollView } from './ScrollView'
import { Text } from './Text'

type VericationProps = {
  description: string
  textButton?: string
  placeholder?: string
  sendAgainButton?: boolean
  onPress?: () => void
  onSubmit?: () => void
}

export const VerificationForm: FC<VericationProps> = ({
  description,
  textButton,
  placeholder,
  sendAgainButton,
  onPress,
  onSubmit,
}) => {
  return (
    <ScrollView
      contentContainerStyle={{
        justifyContent: 'center',
        flexGrow: 1,
        alignItems: 'center',
        paddingHorizontal: 20,
      }}
    >
      <Text style={[tw.textCenter]} variant="small">
        {description}
      </Text>
      <Input
        style={[sendAgainButton ? tw.mY12 : tw.mY6]}
        placeholder={placeholder}
        textAlign="center"
        textContentType="oneTimeCode"
      />
      <Button style={[tw.mB4]} label={textButton} full onPress={onSubmit} />
      {sendAgainButton && <Button label="wyślij kod ponownie" full onPress={onPress} />}
    </ScrollView>
  )
}
