import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { Image, View, TouchableOpacity } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { Button, ScrollView } from '~components'
import { isSmall } from '~helpers'
import { usePhotos } from '~hooks'

export function PhotoPreviewScreen() {
  const { navigate } = useNavigation()
  const { photos, onDeletePhoto } = usePhotos()

  const goToTakePhoto = useCallback(() => {
    navigate('TakePhoto')
  }, [navigate])

  const goToStep2 = useCallback(() => {
    navigate('Step2')
  }, [navigate])

  return (
    <>
      <ScrollView contentContainerStyle={styles.photoPreviewContainer}>
        <View style={[tw.flexRow, tw.justifyBetween, tw.flex1, tw.flexWrap]}>
          {photos.map((item: any) => {
            return (
              <View key={item}>
                <TouchableOpacity onPress={() => onDeletePhoto(item)} style={styles.closeButton}>
                  <Image
                    source={require('~assets/icons/close.png')}
                    style={{ width: 15, height: 15 }}
                  />
                </TouchableOpacity>
                <Image source={{ uri: item }} style={styles.photoImg} />
              </View>
            )
          })}
        </View>
      </ScrollView>
      <View style={[tw.mB10, tw.pX6]}>
        <Button label="dodaj więcej zdjęć" onPress={goToTakePhoto} />
        <Button label="dalej" onPress={goToStep2} style={[tw.mT4]} />
      </View>
    </>
  )
}

const styles = {
  photoPreviewContainer: [tw.justifyCenter, tw.pX6, tw.pB10],
  photoImg: {
    width: isSmall ? 130 : 160,
    height: isSmall ? 240 : 280,
    borderRadius: 10,
    marginTop: 10,
  },
  closeButton: {
    width: 30,
    height: 30,
    backgroundColor: 'yellow',
    borderRadius: 20,
    position: 'absolute',
    zIndex: 2,
    right: -10,
    justifyContent: 'center',
    alignItems: 'center',
  },
}
