export const firstAidScreens = [
  {
    title: 'krwotok lub krwawienie',
    img: require('~assets/images/bleeding.png'),
  },
  {
    title: 'krwotok lub krwawienie',
    img: require('~assets/images/bleeding2.png'),
  },
  {
    title: 'krwotok lub krwawienie',
    img: require('~assets/images/bleeding3.png'),
  },
  {
    title: 'upał',
    img: require('~assets/images/heat.png'),
  },
  {
    title: 'upał',
    img: require('~assets/images/heat2.png'),
  },
  {
    title: 'uwaga upał!',
    img: require('~assets/images/heatAttencion.png'),
  },
  {
    title: 'uwaga upał!',
    img: require('~assets/images/heatAttencion2.png'),
  },
  {
    title: 'poparzenia',
    img: require('~assets/images/burns.png'),
  },
  {
    title: 'odmrożenia',
    img: require('~assets/images/frostbite.png'),
  },
  {
    title: 'udar cieplny',
    img: require('~assets/images/stroke.png'),
  },
  {
    title: 'udar cieplny',
    img: require('~assets/images/stroke2.png'),
  },
  {
    title: 'udar cieplny',
    img: require('~assets/images/stroke3.png'),
  },
]

export const observationScreens = [
  {
    title: 'temperatura',
    img: require('~assets/images/temperature.png'),
    index: 0,
  },
  {
    title: 'puls',
    img: require('~assets/images/pulse.png'),
    index: 1,
  },
  {
    title: 'oddech',
    img: require('~assets/images/breath.png'),
    index: 2,
  },
  {
    title: 'błony śluzowe',
    img: require('~assets/images/mucosal.png'),
    index: 3,
  },
  {
    title: 'skora',
    img: require('~assets/images/skin.png'),
    index: 4,
  },
  {
    title: 'oczy',
    img: require('~assets/images/eyes.png'),
    index: 5,
  },
  {
    title: 'uszy',
    img: require('~assets/images/ears.png'),
    index: 6,
  },
  {
    title: 'nos',
    img: require('~assets/images/nose.png'),
    index: 7,
  },
  {
    title: 'klatka piersiowa',
    img: require('~assets/images/chest.png'),
    index: 8,
  },
]
