import React, { FunctionComponent, useCallback } from 'react'
import { View, Dimensions } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { isWeb, isSmall } from '~helpers'

import { Text } from './Text'

const windowWidth = Dimensions.get('window').width - 380

const Step = ({ children, active = false, isLast = false, isFirst, zIndex }) => {
  return (
    <View style={{ zIndex }}>
      <View
        style={
          isWeb
            ? [
                styles.webStep,
                active ? styles.stepActive : styles.stepInactive,
                isLast ? null : [tw.mR2],
              ]
            : [
                styles.step,
                active ? styles.stepActive : styles.stepInactive,
                isLast ? null : [tw.mR2],
                isFirst ? { width: 60 } : { width: 80 },
              ]
        }
      >
        {isFirst ? null : <View style={styles.rectangleLeft} />}
        <View style={isLast || (!isLast && !isFirst) ? { left: 10 } : { left: 5 }}>
          <Text color={active ? 'gray' : 'darkerGray'}>{children}</Text>
        </View>
      </View>
      {isLast ? null : (
        <View
          style={[
            styles.rectangleRight,
            active ? [isWeb ? tw.bgBlack : tw.bgDarkerGray] : [tw.bgGray],
            isFirst ? { left: isWeb ? '90%' : 38 } : { left: isWeb ? '90%' : 58 },
          ]}
        />
      )}
    </View>
  )
}

type StepperProps = {
  style?: Array<any>
  steps?: number
  activeIndex: number
}

export const Stepper: FunctionComponent<StepperProps> = ({
  style = [],
  steps = 3,
  activeIndex = 0,
}) => {
  const isWebsite = useCallback(
    index => {
      if (isWeb) {
        if (index === 0) {
          return 'dodaj zdjęcia lub filmy do zgłoszenia'
        } else if (index === 1) {
          return 'lokalizacja zdarzenia'
        } else {
          return 'wybierz rodzaj zdarzenia'
        }
      } else {
        return index + 1
      }
    },
    [isWeb],
  )
  return (
    <View style={[styles.container, ...style]}>
      {new Array(steps).fill(null).map((_, index) => {
        const zIndex = steps - index
        return (
          <Step
            isLast={index + 1 === steps}
            active={index === activeIndex}
            isFirst={!index}
            zIndex={zIndex}
            key={index}
          >
            {isWebsite(index)}
          </Step>
        )
      })}
    </View>
  )
}

const styles = {
  container: [tw.flexRow, tw.pY2, tw.justifyAround, { paddingHorizontal: isSmall ? 50 : 90 }],
  step: [{ width: 80, height: 45 }, tw.justifyCenter, tw.itemsCenter, tw.roundedLg],
  webStep: [{ width: windowWidth / 3, height: 65 }, tw.justifyCenter, tw.itemsCenter, tw.roundedLg],
  stepActive: [isWeb ? tw.bgBlack : tw.bgDarkerGray],
  stepInactive: [tw.bgGray],
  rectangleRight: [
    tw.bgDarkerGray,
    {
      transform: [{ rotate: '45deg' }],
      width: isWeb ? 48 : 34,
      height: isWeb ? 48 : 34,
      top: isWeb ? 8 : 5.2,
      position: 'absolute',
      borderRadius: 5,
      zIndex: -1,
    },
  ],
  rectangleLeft: {
    transform: [{ rotate: '45deg' }],
    backgroundColor: 'white',
    width: isWeb ? 57.5 : 37.5,
    height: isWeb ? 57.5 : 37.5,
    top: 3.5,
    right: isWeb ? '95%' : 60,
    position: 'absolute',
    borderRadius: 5,
    zIndex: -1,
  },
}
