import React, { FunctionComponent } from 'react'
import { Image } from 'react-native'

import { isSmall } from '~helpers'

const ratio = isSmall ? 0.75 : 1

type LogoProps = {
  style?: Array<any>
}

export const Logo: FunctionComponent<LogoProps> = ({ style = [] }) => {
  return <Image style={[...styles.logo, ...style]} source={require('~assets/images/logo.png')} />
}

const styles = {
  logo: [{ height: 65 * ratio, width: 186 * ratio }],
}
