import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  FlatList,
  Image,
  NativeScrollEvent,
  NativeSyntheticEvent,
  useWindowDimensions,
  View,
} from 'react-native'
import { CommonActions } from '@react-navigation/routers'
import { CircleButton, ScrollView } from '~components'
import { isWeb } from '~helpers'
import { observationScreens, firstAidScreens } from '../navigation/arrays'
import { tw } from 'react-native-tailwindcss'

type side = 'left' | 'right'

const SlideButton = ({ onPress, side }: { onPress: () => void; side: side }) => {
  return (
    <CircleButton
      tintColor={'black'}
      style={[tw.bgMain, side === 'left' ? tw.mL4 : tw.mR4]}
      iconName={side === 'left' ? 'arrowsLeft' : 'arrowsRight'}
      onPress={onPress}
    />
  )
}

export const PetHelpScreen = ({ route, navigation }) => {
  const { id, arr } = route.params
  const [currentIndex, setCurrentIndex] = useState(id)
  const slidesRef = useRef<FlatList<any>>(null)
  const { width, height } = useWindowDimensions()

  const renderItem = useCallback(
    ({ item, index }: { item: any; index: number }) => {
      return (
        <ScrollView contentContainerStyle={styles.scrollView} key={index}>
          <Image
            source={item.img}
            style={[
              styles.img,
              { width: isWeb ? width - 320 : width },
              isWeb && { height: height / 1.5 },
            ]}
            resizeMode="contain"
          />
        </ScrollView>
      )
    },
    [height, width],
  )

  useEffect(() => {
    setCurrentIndex(id)
    navigation.setOptions({
      title: arr === 1 ? firstAidScreens[id].title : observationScreens[id].title,
    })
  }, [arr, id, navigation])

  useEffect(() => {
    if (isWeb) {
      navigation.dispatch(CommonActions.setParams({ id: currentIndex }))
    }
  }, [currentIndex, navigation])

  const scrollToIndex = ({ index }: { index: number }) => {
    if (isWeb) {
      const x = 1000
      // slidesRef?.current?.scrollTo({ x, animated: true })
    } else {
      slidesRef?.current?.scrollToIndex({ index: currentIndex + 1 })
    }
  }

  const scrollNext = () => {
    if (currentIndex < (arr === 1 ? firstAidScreens.length - 1 : observationScreens.length - 1)) {
      navigation.setOptions({
        title:
          arr === 1
            ? firstAidScreens[currentIndex + 1].title
            : observationScreens[currentIndex + 1].title,
      })
      setCurrentIndex(currentIndex + 1)
      scrollToIndex({ index: currentIndex + 1 })
    } else {
      console.log('end')
    }
  }

  const scrollBack = () => {
    if (currentIndex <= (arr === 1 ? firstAidScreens.length - 1 : observationScreens.length - 1)) {
      navigation.setOptions({
        title:
          arr === 1
            ? firstAidScreens[currentIndex - 1].title
            : observationScreens[currentIndex - 1].title,
      })
      setCurrentIndex(currentIndex - 1)
      scrollToIndex({ index: currentIndex - 1 })
    } else {
      console.log('end')
    }
  }

  const handleMomentumScrollEnd = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const numberOfSlides = arr === 1 ? firstAidScreens.length : observationScreens.length
      const oneSlideWidth = event.nativeEvent.contentSize.width / numberOfSlides
      const index = Math.round(event.nativeEvent.contentOffset.x / oneSlideWidth)
      navigation.setOptions({
        title: arr === 1 ? firstAidScreens[index].title : observationScreens[index].title,
      })
      setCurrentIndex(index)
    },
    [arr, navigation],
  )

  const renderButtons = useMemo(() => {
    if (currentIndex === 0) {
      return (
        <View style={styles.rightButton}>
          <SlideButton onPress={() => scrollNext()} side="right" />
        </View>
      )
    } else if (
      currentIndex === (arr === 1 ? firstAidScreens.length - 1 : observationScreens.length - 1)
    ) {
      return (
        <View style={styles.leftButton}>
          <SlideButton onPress={() => scrollBack()} side="left" />
        </View>
      )
    } else {
      return (
        <View style={styles.twoButtons}>
          <SlideButton onPress={() => scrollBack()} side="left" />
          <SlideButton onPress={() => scrollNext()} side="right" />
        </View>
      )
    }
  }, [arr, currentIndex, scrollBack, scrollNext])

  const onContentSizeChange = useCallback(() => {
    if (currentIndex !== 1 || arr === 2) {
      const wait = new Promise(resolve => setTimeout(resolve, 100))
      wait.then(() => {
        scrollToIndex({ index: id, animated: false })
        setCurrentIndex(id)
      })
    }
  }, [arr, currentIndex, id, scrollToIndex])

  const renderList = () => {
    if (isWeb) {
      const data = arr === 1 ? firstAidScreens : observationScreens
      const item = data[currentIndex]
      return renderItem({ item, index: currentIndex })
    }
    return (
      <FlatList
        data={arr === 1 ? firstAidScreens : observationScreens}
        renderItem={renderItem}
        keyExtractor={(item, index) => `${index}`}
        horizontal
        pagingEnabled
        bounces={false}
        onContentSizeChange={onContentSizeChange}
        showsHorizontalScrollIndicator={false}
        onMomentumScrollEnd={handleMomentumScrollEnd}
        scrollEventThrottle={32}
        ref={slidesRef}
      />
    )
  }

  return (
    <View style={styles.container}>
      {renderList()}
      <View style={styles.arrows}>
        <View>{renderButtons}</View>
      </View>
    </View>
  )
}

const styles = {
  container: [tw.flex1],
  scrollView: [tw.justifyCenter, tw.itemsCenter, tw.flexGrow],
  arrows: [tw.flexShrink],
  img: isWeb && [tw.wFull, tw.hFull],
  twoButtons: [tw.justifyBetween, tw.flexRow, tw.mB4, tw.wFull],
  rightButton: [tw.itemsEnd, tw.wFull, tw.mB4],
  leftButton: [tw.itemsStart, tw.wFull, tw.mB4],
}
