import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { Button, Text } from '~components'
import { SignInScreen } from './SignInScreen'

export const SignInWebScreen = () => {
  const { navigate } = useNavigation()
  const goToSignUpScreen = useCallback(() => navigate('AccountSignUp'), [navigate])

  return (
    <View style={styles.container}>
      <View style={styles.columns}>
        <View style={[styles.column]}>
          <Text style={styles.title}>Zaloguj się</Text>
          <SignInScreen />
        </View>
        <View style={[...styles.column, tw.pL16]}>
          <Text style={styles.title}>Nie masz konta ?</Text>
          <Button label={'zarejestruj się'} full onPress={goToSignUpScreen} />
        </View>
      </View>
    </View>
  )
}

const styles = {
  container: [tw.flexRow, tw.justifyCenter, tw.itemsCenter, tw.flex1],
  columns: [tw.flexRow, tw.wFull, tw.justifyCenter],
  column: [tw.pY5, tw.w4_12],
  title: [tw.mB4],
}
