import React, { useCallback } from 'react'

import { VerificationForm } from '~components'

const info = `podaj kod SMS
lub potwierdź rejestrację
przez e-mail, który wysłaliśmy
 na podany adres`

export const SignUpVerificationScreen = () => {
  const resendVerificationCode = useCallback(() => console.log('resending verification code'), [])
  const submit = useCallback(() => console.log('submitting'), [])

  return (
    <VerificationForm
      description={info}
      placeholder="Kod weryfikacyjny"
      textButton="dalej"
      sendAgainButton
      onPress={resendVerificationCode}
      onSubmit={submit}
    />
  )
}
