import React, { FunctionComponent, useRef } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { useHover } from 'react-native-web-hooks'

import { Pressable } from '~components'

interface SquareButtonProps {
  style?: any[]
  onPress?: any
}

export const SquareButton: FunctionComponent<SquareButtonProps> = ({
  style = [],
  onPress,
  children,
}) => {
  const ref = useRef(null)

  const isHovered = useHover(ref)

  return (
    <View ref={ref} style={[styles.buttonWrapper, ...style, isHovered ? styles.pointer : '']}>
      <Pressable style={styles.buttonInner} onPress={onPress}>
        {children}
      </Pressable>
    </View>
  )
}

const styles = {
  pointer: [{ cursor: 'pointer' }],
  buttonWrapper: [tw.h48, tw.w48, tw.rounded, tw.bgMain],
  buttonInner: [tw.flex, tw.itemsCenter, tw.justifyCenter, tw.flex1],
}
