import { useNavigation } from '@react-navigation/native'
import { Camera, requestPermissionsAsync } from 'expo-camera'
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { View, ImageBackground, Dimensions } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { usePhotos } from '~hooks'

import { Button, Text, Pressable } from '~components'
import { isWeb } from '~helpers'

export const TakePhotoScreen = () => {
  const { navigate } = useNavigation()
  const deviceHeight = Dimensions.get('window').height
  const camera = useRef<Camera>() as MutableRefObject<Camera>
  const [hasPermission, setHasPermission] = useState<boolean | null>(null)
  const [flashMode, setFlashMode] = useState<string>('auto')
  const [isSave, setIsSave] = useState<boolean>(false)
  const [capturedPhoto, setCapturedPhoto] = useState<string | null>(null)
  const { photos, onAddPhoto } = usePhotos()

  const goToPreviewScreen = useCallback(() => {
    navigate('PhotoPreview', { photos, capturedPhoto })
    setIsSave(false)
  }, [navigate, capturedPhoto])

  const goToScreen = useCallback(() => {
    if (capturedPhoto) {
      onAddPhoto(capturedPhoto)
    }
    isWeb ? navigate('Step1', { capturedPhoto }) : goToPreviewScreen()
  }, [isWeb, navigate, capturedPhoto])

  useEffect(() => {
    ;(async () => {
      const { status } = await requestPermissionsAsync()
      setHasPermission(status === 'granted')
    })()
  }, [])

  const takePhoto = async () => {
    const photo: {
      height: number
      uri: string
      width: number
    } = await camera?.current?.takePictureAsync()
    photo && setCapturedPhoto(photo.uri)
    setIsSave(true)
  }

  const retakePhoto = () => {
    setCapturedPhoto(null)
    setIsSave(false)
  }

  if (hasPermission === null) {
    return <View />
  }

  if (hasPermission === false) {
    return <Text>No access to camera</Text>
  }

  if (isSave) {
    return (
      <View style={{ flex: 1, width: '100%', height: '100%' }}>
        <ImageBackground source={{ uri: capturedPhoto }} style={{ flex: 1 }}>
          <View style={[styles.buttonsContainerStyle, { top: deviceHeight - 230 }]}>
            <Button label="powtórz" onPress={retakePhoto} />
            <Button label="dalej" onPress={goToScreen} />
          </View>
        </ImageBackground>
      </View>
    )
  } else {
    return (
      <Camera
        ref={camera}
        style={[tw.flex1, tw.relative]}
        type={Camera.Constants.Type.back}
        flashMode={flashMode}
      >
        <View
          style={[tw.absolute, tw.bottom0, tw.left0, tw.right0, tw.mB10, tw.pX5, tw.itemsCenter]}
        >
          <Pressable
            style={[
              { width: 64, height: 64 },
              tw.roundedFull,
              tw.border2,
              tw.borderMain,
              tw.itemsCenter,
              tw.justifyCenter,
            ]}
            onPress={takePhoto}
          >
            <View style={[{ width: 54, height: 54 }, tw.roundedFull, tw.bgMain]} />
          </Pressable>
        </View>
      </Camera>
    )
  }
}

const styles = {
  buttonsContainerStyle: [tw.flexRow, tw.justifyBetween, tw.pX3, tw.relative],
}
