import * as Linking from 'expo-linking'
import { Platform } from 'react-native'
import { Routes } from './types'

export const LinkingConfiguration = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Main: {
        screens: {
          'Zgłoś zdarzenie': {
            screens: {
              [Routes.RaiseIssueMain]: 'zglos-zdarzenie',
              [Routes.Step1]: 'zglos-zdarzenie/krok-1',
              [Routes.Step2]: 'zglos-zdarzenie/krok-2',
              [Routes.Step3]: 'zglos-zdarzenie/krok-3',
              [Routes.Summary]: 'zglos-zdarzenie/podsumowanie',
              [Routes.RaiseIssuePhoneMakeCall]: 'zglos-zdarzenie/telefonicznie/nie-udalo-sie',
              [Routes.RaiseIssuePhoneMakeCallOther]:
                'zglos-zdarzenie/telefonicznie/inne-instytucje',
            },
          },
          Profil: {
            screens: {
              [Routes.AccountMain]: 'profil',
              [Routes.AccountSignIn]: 'zaloguj-sie',
              [Routes.AccountSignUp]: 'zarejestruj-sie',
              [Routes.AccountVerification]: 'weryfikacja',
            },
          },
          Informacje: {
            screens: {
              [Routes.InformationMain]: 'informacje',
              [Routes.InformationAdvices]: 'informacje/porady',
              [Routes.InformationLegal]: 'informacje/prawo',
              [Routes.PetHelpScreen]: 'informacje/porada',
              [Routes.InformationFirstAid]: 'informacje/pomoc',
              [Routes.InformationPet]: 'informacje/zwierze',
              [Routes.ApplicationCreators]: 'informacje/tworcy-aplikacji',
            },
          },
          'Historia zgłoszeń': {
            screens: {
              [Routes.HistoryMain]: 'zgloszenia',
              [Routes.HistorySummary]: 'zgloszenia/podsumowanie',
              [Routes.HistorySummaryLocation]: 'zgloszenia/podsumowanie/lokalizacja',
              [Routes.HistoryEditLocation]: 'zgloszenia/podsumowanie/edycja-lokalizacji',
              [Routes.HistorySummaryKindIncident]: 'zgloszenia/podsumowanie/rodzaj-zgloszenia',
              [Routes.HistorySummaryInformation]: 'zgloszenia/podsumowanie/informacja',
              [Routes.HistoryChooseIncident]: 'zgloszenia/podsumowanie/incydent',
              [Routes.HistorySummaryGallery]: 'zgloszenia/podsumowanie/zdjecia',
              [Routes.HistoryResultInspection]: 'zgloszenia/podsumowanie/rezultat-inspekcji',
            },
          },
        },
      },
      NotFound: '*',
    },
  },
}
