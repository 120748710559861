import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback } from 'react'
import { View } from 'react-native'
import { tw } from 'react-native-tailwindcss'
import { openURL } from 'expo-linking'

import { Button, WebCard } from '~components'
import { Routes } from '~navigation'
import { sendFeedback } from '~services'

import { InformationStackParamsMap } from '~navigation/InformationStack'

export type InformationMainScreenNavParams = {
  example: string
}

type Props = StackScreenProps<InformationStackParamsMap, Routes.InformationMain>

export const InformationMainScreen: React.FC<Props> = () => {
  const { navigate } = useNavigation()
  const goToApplicationCreatorsScreen = useCallback(() => navigate('ApplicationCreators'), [
    navigate,
  ])
  const goToInformationAdvicesScreen = useCallback(() => navigate('InformationAdvices'), [])
  const goToTermsAndConditions = useCallback(
    () => alert('regulamin aplikacji nie jest jeszcze gotowy'),
    [],
  )

  return (
    <View style={styles.container}>
      <View style={[tw.w6_12]}>
        <View style={styles.row}>
          <WebCard
            title={'Wspomóż nas!'}
            description={'Połącz sie z operatorem i zgłoś zdarzenie.'}
            label="dotacja"
            onPress={() => openURL('https://zrzutka.pl/animalhelper')}
          />
          <WebCard
            title={'Co robić?'}
            description={'Połącz sie z operatorem i zgłoś zdarzenie.'}
            label="porady"
            onPress={goToInformationAdvicesScreen}
          />
        </View>
        <View style={[...styles.row, tw.mT10]}>
          <WebCard
            title={'Zasady'}
            description={'Połącz sie z operatorem i zgłoś zdarzenie.'}
            label="regulamin"
            onPress={goToTermsAndConditions}
          />
          <WebCard
            title={'O nas'}
            description={'Połącz sie z operatorem i zgłoś zdarzenie.'}
            label="twórcy aplikacji"
            onPress={goToApplicationCreatorsScreen}
          />
        </View>
        <View style={[...styles.row, tw.mT10]}>
          <WebCard
            title={'Feedback'}
            description={'Chcesz podzielić się pomysłem? Masz uwagi? Daj nam feedback!'}
            label="feedback"
            onPress={sendFeedback}
            full
          />
        </View>
      </View>
    </View>
  )
}

const styles = {
  container: [tw.flex1, tw.justifyCenter, tw.itemsCenter],
  row: [tw.flexRow, tw.justifyAround],
}
