import React, { useCallback } from 'react'
import { View, FlatList, Dimensions } from 'react-native'
import { tw } from 'react-native-tailwindcss'

import { Text, HistoryItem } from '~components'
import { isWeb } from '~helpers'

const DATA = [
  {
    id: '1',
    incidentSource: 'zgłoszone przez formularz',
    date: '23.09.2021',
    hour: '15:22',
    title: 'zdarzenie drogowe',
    currentStatus: ['wysłane', 'przyjęte'],
  },
  {
    id: '2',
    incidentSource: 'zgłoszone przez formularz',
    date: '20.09.2021',
    hour: '19:10',
    title: 'zdarzenie drogowe',
    currentStatus: ['wysłane'],
  },
  {
    id: '3',
    incidentSource: 'zgłoszone przez formularz',
    date: '12.08.2021',
    hour: '08:30',
    title: 'zdarzenie drogowe',
    currentStatus: ['wysłane'],
  },
  {
    id: '4',
    incidentSource: 'zgłoszone przez formularz',
    date: '02.08.2021',
    hour: '23:05',
    title: 'zdarzenie drogowe',
    currentStatus: ['wysłane'],
  },
]

const MOBILEDATA = [
  {
    id: '1',
    date: '23.09.2021',
    hour: '15:22',
    title: 'zdarzenie drogowe',
    status: ['wysłano'],
  },
  {
    id: '2',
    date: '20.09.2021',
    hour: '19:10',
    title: 'bezpańskie zwierzę',
    status: ['wysłano', 'przyjęte'],
  },
  {
    id: '3',
    date: '12.08.2021',
    hour: '08:30',
    title: 'znęcanie się',
    status: ['wysłano', 'przyjęte', 'wykonano inspekcję'],
  },
  {
    id: '4',
    date: '02.08.2021',
    hour: '23:05',
    title: 'inne',
    status: ['wysłano', 'przyjęte', 'wykonano inspekcję'],
  },
]

const ListEmptyComponent = () => {
  return (
    <View style={styles.container}>
      <Text variant={'emptyState'} style={styles.emptyStateText}>
        Aktualnie nie posiadasz zgłoszeń
      </Text>
    </View>
  )
}

const ItemSeparatorComponent = () => {
  return <View style={styles.itemSeparator} />
}
const { width } = Dimensions.get('window')
const listWidth = width - 320 // 320 = drawer width

export const HistoryMainScreen = () => {
  const keyExtractor = useCallback((item, index) => `item-${index}`, [])

  const calculateColumns = useCallback((): number => {
    return Math.floor(listWidth / 300)
  }, [])

  const renderItem = useCallback(
    ({ item, index }) => {
      return isWeb ? (
        <HistoryItem {...item} itemWidth={listWidth / calculateColumns() - 30} />
      ) : (
        <HistoryItem date={item.date} hour={item.hour} title={item.title} status={item.status} />
      )
    },
    [calculateColumns],
  )

  return (
    <FlatList
      data={isWeb ? DATA : MOBILEDATA}
      ListEmptyComponent={ListEmptyComponent}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      contentContainerStyle={styles.contentContainerStyle}
      ItemSeparatorComponent={ItemSeparatorComponent}
      numColumns={isWeb ? calculateColumns() : undefined}
      ListFooterComponent={isWeb ? <View style={[tw.h6]} /> : null}
      showsVerticalScrollIndicator={false}
    />
  )
}

const styles = {
  container: [tw.flex1, tw.justifyCenter, tw.itemsCenter],
  emptyStateText: [tw.textCenter],
  contentContainerStyle: isWeb ? [tw.flex1, tw.p6] : [tw.minHFull, tw.p4],
  itemSeparator: isWeb ? [tw.w6, tw.h6] : [tw.h4],
}
