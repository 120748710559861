/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";

const BASE_PATH = "";
const defaultFetch: FetchAPI = (_url, init) => fetch(_url, init);

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

function identityWait<T>(milis?: number) {
    return (v: T) => new Promise<T>(resolve => setTimeout(() => resolve(v), milis ?? 0));
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = defaultFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AccountDocument
 */
export interface AccountDocument {
    /**
     * 
     * @type {boolean}
     * @memberof AccountDocument
     */
    accountNonExpired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDocument
     */
    accountNonLocked?: boolean;
    /**
     * 
     * @type {Array<GrantedAuthority>}
     * @memberof AccountDocument
     */
    authorities?: Array<GrantedAuthority>;
    /**
     * 
     * @type {number}
     * @memberof AccountDocument
     */
    createdAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDocument
     */
    credentialsNonExpired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDocument
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    phoneNo?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    provider: AccountDocument_ProviderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountDocument
     */
    roles?: AccountDocument_RolesEnum[];
    /**
     * 
     * @type {number}
     * @memberof AccountDocument
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    username: string;
    /**
     * 
     * @type {number}
     * @memberof AccountDocument
     */
    version: number;
}

/**
 * @export
 * @namespace AccountDocument
 */
/**
 * @export
 * @enum {string}
 */
export enum AccountDocument_ProviderEnum {
	APPLE = 'APPLE' as any,
	FACEBOOK = 'FACEBOOK' as any,
	GOOGLE = 'GOOGLE' as any,
	LOCAL = 'LOCAL' as any
}
/**
 * @export
 * @enum {string}
 */
export enum AccountDocument_RolesEnum {
	ADMIN = 'ROLE_ADMIN' as any,
	OPERATOR = 'ROLE_OPERATOR' as any,
	SUPEROPERATOR = 'ROLE_SUPER_OPERATOR' as any,
	USER = 'ROLE_USER' as any
}

/**
 * 
 * @export
 * @interface AccountSaveCommand
 */
export interface AccountSaveCommand {
    /**
     * 
     * @type {string}
     * @memberof AccountSaveCommand
     */
    accountId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSaveCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSaveCommand
     */
    mobileNo?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSaveCommand
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountSaveCommand
     */
    roles: AccountSaveCommand_RolesEnum[];
    /**
     * 
     * @type {string}
     * @memberof AccountSaveCommand
     */
    username: string;
}

/**
 * @export
 * @namespace AccountSaveCommand
 */
/**
 * @export
 * @enum {string}
 */
export enum AccountSaveCommand_RolesEnum {
	ADMIN = 'ROLE_ADMIN' as any,
	OPERATOR = 'ROLE_OPERATOR' as any,
	SUPEROPERATOR = 'ROLE_SUPER_OPERATOR' as any,
	USER = 'ROLE_USER' as any
}

/**
 * 
 * @export
 * @interface AccountUpdateMyPasswordCommand
 */
export interface AccountUpdateMyPasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateMyPasswordCommand
     */
    currentPassword: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateMyPasswordCommand
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateMyPasswordCommand
     */
    newPasswordRepeated: string;
}
/**
 * 
 * @export
 * @interface AnnonymusReporterContactData
 */
export interface AnnonymusReporterContactData {
    /**
     * 
     * @type {string}
     * @memberof AnnonymusReporterContactData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonymusReporterContactData
     */
    mobileNo?: string;
}
/**
 * 
 * @export
 * @interface AnonymousIncidentReportCommand
 */
export interface AnonymousIncidentReportCommand {
    /**
     * 
     * @type {string}
     * @memberof AnonymousIncidentReportCommand
     */
    acquired: AnonymousIncidentReportCommand_AcquiredEnum;
    /**
     * 
     * @type {ReporterContactData}
     * @memberof AnonymousIncidentReportCommand
     */
    contact?: ReporterContactData;
    /**
     * 
     * @type {IncidentReportDataDto}
     * @memberof AnonymousIncidentReportCommand
     */
    data: IncidentReportDataDto;
}

/**
 * @export
 * @namespace AnonymousIncidentReportCommand
 */
/**
 * @export
 * @enum {string}
 */
export enum AnonymousIncidentReportCommand_AcquiredEnum {
	MAIL = 'MAIL' as any,
	MOBILEAPP = 'MOBILE_APP' as any,
	OTHER = 'OTHER' as any,
	PHONE = 'PHONE' as any,
	WEBAPP = 'WEB_APP' as any
}

/**
 * 
 * @export
 * @interface CurrentUserDto
 */
export interface CurrentUserDto {
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    mobileNo: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CurrentUserDto
     */
    registeredAt: number;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    username: string;
}
/**
 * 
 * @export
 * @interface File2UploadDto
 */
export interface File2UploadDto {
    /**
     * 
     * @type {string}
     * @memberof File2UploadDto
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof File2UploadDto
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof File2UploadDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface FileDocShort
 */
export interface FileDocShort {
    /**
     * 
     * @type {string}
     * @memberof FileDocShort
     */
    authDownloadUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FileDocShort
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof FileDocShort
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof FileDocShort
     */
    originalFilename: string;
    /**
     * 
     * @type {string}
     * @memberof FileDocShort
     */
    resourceUuid: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileDocShort
     */
    tags: Array<string>;
}
/**
 * 
 * @export
 * @interface GeoLocation
 */
export interface GeoLocation {
    /**
     * 
     * @type {string}
     * @memberof GeoLocation
     */
    geoHash?: string;
    /**
     * 
     * @type {GeoPoint}
     * @memberof GeoLocation
     */
    geoPoint?: GeoPoint;
}
/**
 * 
 * @export
 * @interface GeoPoint
 */
export interface GeoPoint {
    /**
     * 
     * @type {number}
     * @memberof GeoPoint
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GeoPoint
     */
    longitude?: number;
}
/**
 * 
 * @export
 * @interface GeoPointDto
 */
export interface GeoPointDto {
    /**
     * 
     * @type {number}
     * @memberof GeoPointDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GeoPointDto
     */
    longitude?: number;
}
/**
 * 
 * @export
 * @interface GrantedAuthority
 */
export interface GrantedAuthority {
    /**
     * 
     * @type {string}
     * @memberof GrantedAuthority
     */
    authority?: string;
}
/**
 * 
 * @export
 * @interface IncidentDocument
 */
export interface IncidentDocument {
    /**
     * 
     * @type {UserRef}
     * @memberof IncidentDocument
     */
    assignedOperator?: UserRef;
    /**
     * 
     * @type {number}
     * @memberof IncidentDocument
     */
    createdAt: number;
    /**
     * 
     * @type {string}
     * @memberof IncidentDocument
     */
    documentId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentDocument
     */
    flags?: Array<string>;
    /**
     * 
     * @type {IncidentReport}
     * @memberof IncidentDocument
     */
    report: IncidentReport;
    /**
     * 
     * @type {string}
     * @memberof IncidentDocument
     */
    status: IncidentDocument_StatusEnum;
    /**
     * 
     * @type {Array<IncidentDocumentStatusChange>}
     * @memberof IncidentDocument
     */
    statusChangeList?: Array<IncidentDocumentStatusChange>;
    /**
     * 
     * @type {number}
     * @memberof IncidentDocument
     */
    updatedAt: number;
    /**
     * 
     * @type {number}
     * @memberof IncidentDocument
     */
    version: number;
}

/**
 * @export
 * @namespace IncidentDocument
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentDocument_StatusEnum {
	ANULOWANY = 'ANULOWANY' as any,
	NOWY = 'NOWY' as any,
	WTOKU = 'W_TOKU' as any,
	ZAMKNIETY = 'ZAMKNIETY' as any
}

/**
 * 
 * @export
 * @interface IncidentDocumentStatusChange
 */
export interface IncidentDocumentStatusChange {
    /**
     * 
     * @type {UserRef}
     * @memberof IncidentDocumentStatusChange
     */
    createdBy: UserRef;
    /**
     * 
     * @type {string}
     * @memberof IncidentDocumentStatusChange
     */
    statusChange: IncidentDocumentStatusChange_StatusChangeEnum;
    /**
     * 
     * @type {string}
     * @memberof IncidentDocumentStatusChange
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof IncidentDocumentStatusChange
     */
    timestamp: number;
}

/**
 * @export
 * @namespace IncidentDocumentStatusChange
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentDocumentStatusChange_StatusChangeEnum {
	ANULOWANY = 'ANULOWANY' as any,
	NOWY = 'NOWY' as any,
	WTOKU = 'W_TOKU' as any,
	ZAMKNIETY = 'ZAMKNIETY' as any
}

/**
 * 
 * @export
 * @interface IncidentOperatorAssignCommand
 */
export interface IncidentOperatorAssignCommand {
    /**
     * 
     * @type {string}
     * @memberof IncidentOperatorAssignCommand
     */
    incidentId: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentOperatorAssignCommand
     */
    operatorId: string;
}
/**
 * 
 * @export
 * @interface IncidentReport
 */
export interface IncidentReport {
    /**
     * 
     * @type {string}
     * @memberof IncidentReport
     */
    acquired?: IncidentReport_AcquiredEnum;
    /**
     * 
     * @type {IncidentReportData}
     * @memberof IncidentReport
     */
    reportData?: IncidentReportData;
    /**
     * 
     * @type {IncidentReporter}
     * @memberof IncidentReport
     */
    reporter?: IncidentReporter;
}

/**
 * @export
 * @namespace IncidentReport
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentReport_AcquiredEnum {
	MAIL = 'MAIL' as any,
	MOBILEAPP = 'MOBILE_APP' as any,
	OTHER = 'OTHER' as any,
	PHONE = 'PHONE' as any,
	WEBAPP = 'WEB_APP' as any
}

/**
 * 
 * @export
 * @interface IncidentReportData
 */
export interface IncidentReportData {
    /**
     * 
     * @type {string}
     * @memberof IncidentReportData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportData
     */
    customIncidentKind?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportData
     */
    customIncidentSubKind?: string;
    /**
     * 
     * @type {Array<FileDocShort>}
     * @memberof IncidentReportData
     */
    files?: Array<FileDocShort>;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportData
     */
    incidentKind?: IncidentReportData_IncidentKindEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentReportData
     */
    incidentSubKindList?: IncidentReportData_IncidentSubKindListEnum[];
    /**
     * 
     * @type {GeoLocation}
     * @memberof IncidentReportData
     */
    location?: GeoLocation;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportData
     */
    noOfAnimals?: string;
}

/**
 * @export
 * @namespace IncidentReportData
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentReportData_IncidentKindEnum {
	BEZPANSKIEZWIERZE = 'BEZPANSKIE_ZWIERZE' as any,
	INNE = 'INNE' as any,
	ZABITEZWIERZE = 'ZABITE_ZWIERZE' as any,
	ZDARZENIEDROGOWE = 'ZDARZENIE_DROGOWE' as any,
	ZNECANIESIE = 'ZNECANIE_SIE' as any
}
/**
 * @export
 * @enum {string}
 */
export enum IncidentReportData_IncidentSubKindListEnum {
	BICIE = 'BICIE' as any,
	GLODZENIEODWODNIENIE = 'GLODZENIE_ODWODNIENIE' as any,
	INNE = 'INNE' as any,
	NIEULECZALNACHOROBA = 'NIEULECZALNA_CHOROBA' as any,
	OKALECZNIE = 'OKALECZNIE' as any,
	PORZUCENIE = 'PORZUCENIE' as any,
	ZLEWARUNKI = 'ZLE_WARUNKI' as any
}

/**
 * 
 * @export
 * @interface IncidentReportDataDto
 */
export interface IncidentReportDataDto {
    /**
     * 
     * @type {string}
     * @memberof IncidentReportDataDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportDataDto
     */
    customIncidentKind?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportDataDto
     */
    customIncidentSubKind?: string;
    /**
     * 
     * @type {Array<File2UploadDto>}
     * @memberof IncidentReportDataDto
     */
    files?: Array<File2UploadDto>;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportDataDto
     */
    incidentKind?: IncidentReportDataDto_IncidentKindEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentReportDataDto
     */
    incidentSubKindList?: Array<string>;
    /**
     * 
     * @type {GeoPointDto}
     * @memberof IncidentReportDataDto
     */
    location?: GeoPointDto;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportDataDto
     */
    noOfAnimals?: string;
}

/**
 * @export
 * @namespace IncidentReportDataDto
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentReportDataDto_IncidentKindEnum {
	BEZPANSKIEZWIERZE = 'BEZPANSKIE_ZWIERZE' as any,
	INNE = 'INNE' as any,
	ZABITEZWIERZE = 'ZABITE_ZWIERZE' as any,
	ZDARZENIEDROGOWE = 'ZDARZENIE_DROGOWE' as any,
	ZNECANIESIE = 'ZNECANIE_SIE' as any
}

/**
 * 
 * @export
 * @interface IncidentReportRegisteredDto
 */
export interface IncidentReportRegisteredDto {
    /**
     * 
     * @type {string}
     * @memberof IncidentReportRegisteredDto
     */
    incidentId: string;
    /**
     * 
     * @type {Array<UploadLinkDto>}
     * @memberof IncidentReportRegisteredDto
     */
    uploadLinks: Array<UploadLinkDto>;
    /**
     * 
     * @type {string}
     * @memberof IncidentReportRegisteredDto
     */
    viewUrl?: string;
}
/**
 * 
 * @export
 * @interface IncidentReporter
 */
export interface IncidentReporter {
    /**
     * 
     * @type {AnnonymusReporterContactData}
     * @memberof IncidentReporter
     */
    contact?: AnnonymusReporterContactData;
    /**
     * 
     * @type {string}
     * @memberof IncidentReporter
     */
    type?: IncidentReporter_TypeEnum;
    /**
     * 
     * @type {UserRef}
     * @memberof IncidentReporter
     */
    user?: UserRef;
}

/**
 * @export
 * @namespace IncidentReporter
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentReporter_TypeEnum {
	ANONYMOUS = 'ANONYMOUS' as any,
	APPUSER = 'APP_USER' as any
}

/**
 * 
 * @export
 * @interface IncidentShortDto
 */
export interface IncidentShortDto {
    /**
     * 
     * @type {number}
     * @memberof IncidentShortDto
     */
    createdAt: number;
    /**
     * 
     * @type {IncidentShortFlags}
     * @memberof IncidentShortDto
     */
    flags: IncidentShortFlags;
    /**
     * 
     * @type {string}
     * @memberof IncidentShortDto
     */
    id: string;
    /**
     * 
     * @type {IncidentReportData}
     * @memberof IncidentShortDto
     */
    reportData: IncidentReportData;
}
/**
 * 
 * @export
 * @interface IncidentShortFlags
 */
export interface IncidentShortFlags {
    /**
     * 
     * @type {boolean}
     * @memberof IncidentShortFlags
     */
    inspekcjaWykonana: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentShortFlags
     */
    przyjete: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentShortFlags
     */
    wyslane: boolean;
}
/**
 * 
 * @export
 * @interface IncidentStatusUpdateCommand
 */
export interface IncidentStatusUpdateCommand {
    /**
     * 
     * @type {string}
     * @memberof IncidentStatusUpdateCommand
     */
    incidentId: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentStatusUpdateCommand
     */
    status: IncidentStatusUpdateCommand_StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IncidentStatusUpdateCommand
     */
    text: string;
}

/**
 * @export
 * @namespace IncidentStatusUpdateCommand
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentStatusUpdateCommand_StatusEnum {
	ANULOWANY = 'ANULOWANY' as any,
	NOWY = 'NOWY' as any,
	WTOKU = 'W_TOKU' as any,
	ZAMKNIETY = 'ZAMKNIETY' as any
}

/**
 * 
 * @export
 * @interface IncidentTaskCreateCommand
 */
export interface IncidentTaskCreateCommand {
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskCreateCommand
     */
    caption: string;
    /**
     * 
     * @type {Array<File2UploadDto>}
     * @memberof IncidentTaskCreateCommand
     */
    files: Array<File2UploadDto>;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskCreateCommand
     */
    incidentId: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskCreateCommand
     */
    status: IncidentTaskCreateCommand_StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskCreateCommand
     */
    text: string;
}

/**
 * @export
 * @namespace IncidentTaskCreateCommand
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentTaskCreateCommand_StatusEnum {
	ANULOWANY = 'ANULOWANY' as any,
	NOWY = 'NOWY' as any,
	WTOKU = 'W_TOKU' as any,
	ZAMKNIETY = 'ZAMKNIETY' as any
}

/**
 * 
 * @export
 * @interface IncidentTaskCreateRespDto
 */
export interface IncidentTaskCreateRespDto {
    /**
     * 
     * @type {IncidentTaskDocument}
     * @memberof IncidentTaskCreateRespDto
     */
    incidentTaskDocument: IncidentTaskDocument;
    /**
     * 
     * @type {Array<UploadLinkDto>}
     * @memberof IncidentTaskCreateRespDto
     */
    uploadLinks: Array<UploadLinkDto>;
}
/**
 * 
 * @export
 * @interface IncidentTaskDocument
 */
export interface IncidentTaskDocument {
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskDocument
     */
    caption: string;
    /**
     * 
     * @type {number}
     * @memberof IncidentTaskDocument
     */
    createdAt: number;
    /**
     * 
     * @type {UserRef}
     * @memberof IncidentTaskDocument
     */
    createdBy: UserRef;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskDocument
     */
    documentId: string;
    /**
     * 
     * @type {Array<IncidentTaskNote>}
     * @memberof IncidentTaskDocument
     */
    notes: Array<IncidentTaskNote>;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskDocument
     */
    status: IncidentTaskDocument_StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof IncidentTaskDocument
     */
    updatedAt: number;
    /**
     * 
     * @type {number}
     * @memberof IncidentTaskDocument
     */
    version: number;
}

/**
 * @export
 * @namespace IncidentTaskDocument
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentTaskDocument_StatusEnum {
	ANULOWANY = 'ANULOWANY' as any,
	NOWY = 'NOWY' as any,
	WTOKU = 'W_TOKU' as any,
	ZAMKNIETY = 'ZAMKNIETY' as any
}

/**
 * 
 * @export
 * @interface IncidentTaskNote
 */
export interface IncidentTaskNote {
    /**
     * 
     * @type {number}
     * @memberof IncidentTaskNote
     */
    createdAt?: number;
    /**
     * 
     * @type {UserRef}
     * @memberof IncidentTaskNote
     */
    createdBy?: UserRef;
    /**
     * 
     * @type {Array<FileDocShort>}
     * @memberof IncidentTaskNote
     */
    files?: Array<FileDocShort>;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskNote
     */
    statusChange?: IncidentTaskNote_StatusChangeEnum;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskNote
     */
    text?: string;
}

/**
 * @export
 * @namespace IncidentTaskNote
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentTaskNote_StatusChangeEnum {
	ANULOWANY = 'ANULOWANY' as any,
	NOWY = 'NOWY' as any,
	WTOKU = 'W_TOKU' as any,
	ZAMKNIETY = 'ZAMKNIETY' as any
}

/**
 * 
 * @export
 * @interface IncidentTaskNoteAddedRespDto
 */
export interface IncidentTaskNoteAddedRespDto {
    /**
     * 
     * @type {IncidentTaskDocument}
     * @memberof IncidentTaskNoteAddedRespDto
     */
    incidentTaskDocument: IncidentTaskDocument;
    /**
     * 
     * @type {Array<UploadLinkDto>}
     * @memberof IncidentTaskNoteAddedRespDto
     */
    uploadLinks: Array<UploadLinkDto>;
}
/**
 * 
 * @export
 * @interface IncidentTaskNoteCreateCommand
 */
export interface IncidentTaskNoteCreateCommand {
    /**
     * 
     * @type {Array<File2UploadDto>}
     * @memberof IncidentTaskNoteCreateCommand
     */
    files: Array<File2UploadDto>;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskNoteCreateCommand
     */
    incidentId: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskNoteCreateCommand
     */
    statusChange?: IncidentTaskNoteCreateCommand_StatusChangeEnum;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskNoteCreateCommand
     */
    taskId: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentTaskNoteCreateCommand
     */
    text: string;
}

/**
 * @export
 * @namespace IncidentTaskNoteCreateCommand
 */
/**
 * @export
 * @enum {string}
 */
export enum IncidentTaskNoteCreateCommand_StatusChangeEnum {
	ANULOWANY = 'ANULOWANY' as any,
	NOWY = 'NOWY' as any,
	WTOKU = 'W_TOKU' as any,
	ZAMKNIETY = 'ZAMKNIETY' as any
}

/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    username: string;
}
/**
 * 
 * @export
 * @interface LoginResp
 */
export interface LoginResp {
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginResp
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LoginResp
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginResp
     */
    tokenExpiresAt?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResp
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface PageDtoAccountDocument
 */
export interface PageDtoAccountDocument {
    /**
     * 
     * @type {Array<AccountDocument>}
     * @memberof PageDtoAccountDocument
     */
    content: Array<AccountDocument>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAccountDocument
     */
    pageIdx: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAccountDocument
     */
    pageLimit: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAccountDocument
     */
    pageSize: number;
}
/**
 * 
 * @export
 * @interface PageDtoIncidentDocument
 */
export interface PageDtoIncidentDocument {
    /**
     * 
     * @type {Array<IncidentDocument>}
     * @memberof PageDtoIncidentDocument
     */
    content: Array<IncidentDocument>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIncidentDocument
     */
    pageIdx: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIncidentDocument
     */
    pageLimit: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIncidentDocument
     */
    pageSize: number;
}
/**
 * 
 * @export
 * @interface PageDtoIncidentShortDto
 */
export interface PageDtoIncidentShortDto {
    /**
     * 
     * @type {Array<IncidentShortDto>}
     * @memberof PageDtoIncidentShortDto
     */
    content: Array<IncidentShortDto>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIncidentShortDto
     */
    pageIdx: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIncidentShortDto
     */
    pageLimit: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIncidentShortDto
     */
    pageSize: number;
}
/**
 * 
 * @export
 * @interface ReporterContactData
 */
export interface ReporterContactData {
    /**
     * 
     * @type {string}
     * @memberof ReporterContactData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ReporterContactData
     */
    mobileNo?: string;
}
/**
 * 
 * @export
 * @interface ResetPassword1Command
 */
export interface ResetPassword1Command {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword1Command
     */
    email: string;
}
/**
 * 
 * @export
 * @interface ResetPassword2Command
 */
export interface ResetPassword2Command {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword2Command
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword2Command
     */
    newPasswordRepeated: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword2Command
     */
    token: string;
}
/**
 * 
 * @export
 * @interface UploadLinkDto
 */
export interface UploadLinkDto {
    /**
     * 
     * @type {string}
     * @memberof UploadLinkDto
     */
    uploadUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UploadLinkDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface UserIncidentReportCommand
 */
export interface UserIncidentReportCommand {
    /**
     * 
     * @type {string}
     * @memberof UserIncidentReportCommand
     */
    acquired: UserIncidentReportCommand_AcquiredEnum;
    /**
     * 
     * @type {IncidentReportDataDto}
     * @memberof UserIncidentReportCommand
     */
    data: IncidentReportDataDto;
}

/**
 * @export
 * @namespace UserIncidentReportCommand
 */
/**
 * @export
 * @enum {string}
 */
export enum UserIncidentReportCommand_AcquiredEnum {
	MAIL = 'MAIL' as any,
	MOBILEAPP = 'MOBILE_APP' as any,
	OTHER = 'OTHER' as any,
	PHONE = 'PHONE' as any,
	WEBAPP = 'WEB_APP' as any
}

/**
 * 
 * @export
 * @interface UserRef
 */
export interface UserRef {
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    userName: string;
}
/**
 * 
 * @export
 * @interface UserRegistrationCommand
 */
export interface UserRegistrationCommand {
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationCommand
     */
    mobileNo?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationCommand
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationCommand
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationCommand
     */
    username: string;
}



export interface IAdminApiImplApi_loadAccountByIdQueryParams {
    // accountId
    accountId: string, 
}




export interface IAdminApiImplApi_loadAccountByIdRequest {
    query: IAdminApiImplApi_loadAccountByIdQueryParams,


	

}


export interface IAdminApiImplApi_queryAccountsQueryParams {
    // name
    name?: string, 
    // pageIdx
    pageIdx?: number, 
    // role
    role?: string, 
    // username
    username?: string, 
}




export interface IAdminApiImplApi_queryAccountsRequest {
    query: IAdminApiImplApi_queryAccountsQueryParams,


	

}






export interface IAdminApiImplApi_saveAccountRequest {


	
    body: AccountSaveCommand,

}




/**
 * AdminApiImplApi - fetch parameter creator
 * @export
 */
export const AdminApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary loadAccountById
         * @param {IAdminApiImplApi_loadAccountByIdRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAccountByIdUsingGET(request: IAdminApiImplApi_loadAccountByIdRequest, options: any = {}): FetchArgs {
            // verify required parameter 'request.query.accountId' is not null or undefined
            if (request.query.accountId === null || request.query.accountId === undefined) {
                throw new RequiredError('request.query.accountId','Required parameter request.query.accountId was null or undefined when calling loadAccountByIdUsingGET.');
            }
	
	
	
	

            const localVarPath = `/api/admin/account/{accountId}`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (request.query.accountId !== undefined) {
                localVarQueryParameter['accountId'] = request.query.accountId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary queryAccounts
         * @param {IAdminApiImplApi_queryAccountsRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryAccountsUsingGET(request: IAdminApiImplApi_queryAccountsRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/admin/account/query`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (request.query.name !== undefined) {
                localVarQueryParameter['name'] = request.query.name;
            }

            if (request.query.pageIdx !== undefined) {
                localVarQueryParameter['pageIdx'] = request.query.pageIdx;
            }

            if (request.query.role !== undefined) {
                localVarQueryParameter['role'] = request.query.role;
            }

            if (request.query.username !== undefined) {
                localVarQueryParameter['username'] = request.query.username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveAccount
         * @param {IAdminApiImplApi_saveAccountRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountUsingPOST(request: IAdminApiImplApi_saveAccountRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/admin/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * AdminApiImplApi - functional programming interface
 * @export
 */
export const AdminApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary loadAccountById
         * @param {IAdminApiImplApi_loadAccountByIdRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAccountByIdUsingGET(request: IAdminApiImplApi_loadAccountByIdRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDocument> {
            const localVarFetchArgs = AdminApiImplApiFetchParamCreator(configuration).loadAccountByIdUsingGET(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary queryAccounts
         * @param {IAdminApiImplApi_queryAccountsRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryAccountsUsingGET(request: IAdminApiImplApi_queryAccountsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageDtoAccountDocument> {
            const localVarFetchArgs = AdminApiImplApiFetchParamCreator(configuration).queryAccountsUsingGET(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary saveAccount
         * @param {IAdminApiImplApi_saveAccountRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountUsingPOST(request: IAdminApiImplApi_saveAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDocument> {
            const localVarFetchArgs = AdminApiImplApiFetchParamCreator(configuration).saveAccountUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * AdminApiImplApi - factory interface
 * @export
 */
export const AdminApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary loadAccountById
         * @param {IAdminApiImplApi_loadAccountByIdRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAccountByIdUsingGET(request: IAdminApiImplApi_loadAccountByIdRequest, options?: any) {
            return AdminApiImplApiFp(configuration).loadAccountByIdUsingGET(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary queryAccounts
         * @param {IAdminApiImplApi_queryAccountsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryAccountsUsingGET(request: IAdminApiImplApi_queryAccountsRequest, options?: any) {
            return AdminApiImplApiFp(configuration).queryAccountsUsingGET(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveAccount
         * @param {IAdminApiImplApi_saveAccountRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountUsingPOST(request: IAdminApiImplApi_saveAccountRequest, options?: any) {
            return AdminApiImplApiFp(configuration).saveAccountUsingPOST(request, options)(fetch, basePath);
        },
    };
};

/**
 * AdminApiImplApi - object-oriented interface
 * @export
 * @class AdminApiImplApi
 * @extends {BaseAPI}
 */
export class AdminApiImplApi extends BaseAPI {
    /**
     * 
     * @summary loadAccountById
     * @param {IAdminApiImplApi_loadAccountByIdRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiImplApi
     */
    public loadAccountByIdUsingGET(request: IAdminApiImplApi_loadAccountByIdRequest, options?: any) {
        return AdminApiImplApiFp(this.configuration).loadAccountByIdUsingGET(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary queryAccounts
     * @param {IAdminApiImplApi_queryAccountsRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiImplApi
     */
    public queryAccountsUsingGET(request: IAdminApiImplApi_queryAccountsRequest, options?: any) {
        return AdminApiImplApiFp(this.configuration).queryAccountsUsingGET(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveAccount
     * @param {IAdminApiImplApi_saveAccountRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiImplApi
     */
    public saveAccountUsingPOST(request: IAdminApiImplApi_saveAccountRequest, options?: any) {
        return AdminApiImplApiFp(this.configuration).saveAccountUsingPOST(request, options)(this.fetch, this.basePath);
    }

}







export interface IAnonymousApiImplApi_createAccountRequest {


	
    body: UserRegistrationCommand,

}




/**
 * AnonymousApiImplApi - fetch parameter creator
 * @export
 */
export const AnonymousApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createAccount
         * @param {IAnonymousApiImplApi_createAccountRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountUsingPOST(request: IAnonymousApiImplApi_createAccountRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/anonymous/createAccount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * AnonymousApiImplApi - functional programming interface
 * @export
 */
export const AnonymousApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createAccount
         * @param {IAnonymousApiImplApi_createAccountRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountUsingPOST(request: IAnonymousApiImplApi_createAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AnonymousApiImplApiFetchParamCreator(configuration).createAccountUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response;
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * AnonymousApiImplApi - factory interface
 * @export
 */
export const AnonymousApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createAccount
         * @param {IAnonymousApiImplApi_createAccountRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountUsingPOST(request: IAnonymousApiImplApi_createAccountRequest, options?: any) {
            return AnonymousApiImplApiFp(configuration).createAccountUsingPOST(request, options)(fetch, basePath);
        },
    };
};

/**
 * AnonymousApiImplApi - object-oriented interface
 * @export
 * @class AnonymousApiImplApi
 * @extends {BaseAPI}
 */
export class AnonymousApiImplApi extends BaseAPI {
    /**
     * 
     * @summary createAccount
     * @param {IAnonymousApiImplApi_createAccountRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnonymousApiImplApi
     */
    public createAccountUsingPOST(request: IAnonymousApiImplApi_createAccountRequest, options?: any) {
        return AnonymousApiImplApiFp(this.configuration).createAccountUsingPOST(request, options)(this.fetch, this.basePath);
    }

}







export interface IAuthApiImplApi_loginRequest {


	
    body: LoginDto,

}






export interface IAuthApiImplApi_regenerateTokenRequest {


	
    body: string,

}






export interface IAuthApiImplApi_resetPassword1Request {


	
    body: ResetPassword1Command,

}






export interface IAuthApiImplApi_resetPassword2Request {


	
    body: ResetPassword2Command,

}




/**
 * AuthApiImplApi - fetch parameter creator
 * @export
 */
export const AuthApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary login
         * @param {IAuthApiImplApi_loginRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST(request: IAuthApiImplApi_loginRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/auth/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary regenerateToken
         * @param {IAuthApiImplApi_regenerateTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateTokenUsingPOST(request: IAuthApiImplApi_regenerateTokenRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/auth/regenerateToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetPassword1
         * @param {IAuthApiImplApi_resetPassword1Request} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword1UsingPOST(request: IAuthApiImplApi_resetPassword1Request, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/auth/resetPassword1`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetPassword2
         * @param {IAuthApiImplApi_resetPassword2Request} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword2UsingPOST(request: IAuthApiImplApi_resetPassword2Request, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/auth/resetPassword2`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * AuthApiImplApi - functional programming interface
 * @export
 */
export const AuthApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary login
         * @param {IAuthApiImplApi_loginRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST(request: IAuthApiImplApi_loginRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoginResp> {
            const localVarFetchArgs = AuthApiImplApiFetchParamCreator(configuration).loginUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary regenerateToken
         * @param {IAuthApiImplApi_regenerateTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateTokenUsingPOST(request: IAuthApiImplApi_regenerateTokenRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoginResp> {
            const localVarFetchArgs = AuthApiImplApiFetchParamCreator(configuration).regenerateTokenUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary resetPassword1
         * @param {IAuthApiImplApi_resetPassword1Request} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword1UsingPOST(request: IAuthApiImplApi_resetPassword1Request, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiImplApiFetchParamCreator(configuration).resetPassword1UsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response;
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary resetPassword2
         * @param {IAuthApiImplApi_resetPassword2Request} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword2UsingPOST(request: IAuthApiImplApi_resetPassword2Request, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiImplApiFetchParamCreator(configuration).resetPassword2UsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response;
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * AuthApiImplApi - factory interface
 * @export
 */
export const AuthApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary login
         * @param {IAuthApiImplApi_loginRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST(request: IAuthApiImplApi_loginRequest, options?: any) {
            return AuthApiImplApiFp(configuration).loginUsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary regenerateToken
         * @param {IAuthApiImplApi_regenerateTokenRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateTokenUsingPOST(request: IAuthApiImplApi_regenerateTokenRequest, options?: any) {
            return AuthApiImplApiFp(configuration).regenerateTokenUsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetPassword1
         * @param {IAuthApiImplApi_resetPassword1Request} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword1UsingPOST(request: IAuthApiImplApi_resetPassword1Request, options?: any) {
            return AuthApiImplApiFp(configuration).resetPassword1UsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetPassword2
         * @param {IAuthApiImplApi_resetPassword2Request} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword2UsingPOST(request: IAuthApiImplApi_resetPassword2Request, options?: any) {
            return AuthApiImplApiFp(configuration).resetPassword2UsingPOST(request, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApiImplApi - object-oriented interface
 * @export
 * @class AuthApiImplApi
 * @extends {BaseAPI}
 */
export class AuthApiImplApi extends BaseAPI {
    /**
     * 
     * @summary login
     * @param {IAuthApiImplApi_loginRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiImplApi
     */
    public loginUsingPOST(request: IAuthApiImplApi_loginRequest, options?: any) {
        return AuthApiImplApiFp(this.configuration).loginUsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary regenerateToken
     * @param {IAuthApiImplApi_regenerateTokenRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiImplApi
     */
    public regenerateTokenUsingPOST(request: IAuthApiImplApi_regenerateTokenRequest, options?: any) {
        return AuthApiImplApiFp(this.configuration).regenerateTokenUsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetPassword1
     * @param {IAuthApiImplApi_resetPassword1Request} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiImplApi
     */
    public resetPassword1UsingPOST(request: IAuthApiImplApi_resetPassword1Request, options?: any) {
        return AuthApiImplApiFp(this.configuration).resetPassword1UsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetPassword2
     * @param {IAuthApiImplApi_resetPassword2Request} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiImplApi
     */
    public resetPassword2UsingPOST(request: IAuthApiImplApi_resetPassword2Request, options?: any) {
        return AuthApiImplApiFp(this.configuration).resetPassword2UsingPOST(request, options)(this.fetch, this.basePath);
    }

}







export interface IAuthenticatedApiImplApi_changeMyPasswordRequest {


	
    body: AccountUpdateMyPasswordCommand,

}






export interface IAuthenticatedApiImplApi_currentUserRequest {


	

}




/**
 * AuthenticatedApiImplApi - fetch parameter creator
 * @export
 */
export const AuthenticatedApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changeMyPassword
         * @param {IAuthenticatedApiImplApi_changeMyPasswordRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMyPasswordUsingPOST(request: IAuthenticatedApiImplApi_changeMyPasswordRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/authenticated/changeMyPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary currentUser
         * @param {IAuthenticatedApiImplApi_currentUserRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserUsingGET(request: IAuthenticatedApiImplApi_currentUserRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/authenticated/current`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * AuthenticatedApiImplApi - functional programming interface
 * @export
 */
export const AuthenticatedApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changeMyPassword
         * @param {IAuthenticatedApiImplApi_changeMyPasswordRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMyPasswordUsingPOST(request: IAuthenticatedApiImplApi_changeMyPasswordRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticatedApiImplApiFetchParamCreator(configuration).changeMyPasswordUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response;
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary currentUser
         * @param {IAuthenticatedApiImplApi_currentUserRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserUsingGET(request: IAuthenticatedApiImplApi_currentUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrentUserDto> {
            const localVarFetchArgs = AuthenticatedApiImplApiFetchParamCreator(configuration).currentUserUsingGET(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * AuthenticatedApiImplApi - factory interface
 * @export
 */
export const AuthenticatedApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary changeMyPassword
         * @param {IAuthenticatedApiImplApi_changeMyPasswordRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMyPasswordUsingPOST(request: IAuthenticatedApiImplApi_changeMyPasswordRequest, options?: any) {
            return AuthenticatedApiImplApiFp(configuration).changeMyPasswordUsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary currentUser
         * @param {IAuthenticatedApiImplApi_currentUserRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserUsingGET(request: IAuthenticatedApiImplApi_currentUserRequest, options?: any) {
            return AuthenticatedApiImplApiFp(configuration).currentUserUsingGET(request, options)(fetch, basePath);
        },
    };
};

/**
 * AuthenticatedApiImplApi - object-oriented interface
 * @export
 * @class AuthenticatedApiImplApi
 * @extends {BaseAPI}
 */
export class AuthenticatedApiImplApi extends BaseAPI {
    /**
     * 
     * @summary changeMyPassword
     * @param {IAuthenticatedApiImplApi_changeMyPasswordRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedApiImplApi
     */
    public changeMyPasswordUsingPOST(request: IAuthenticatedApiImplApi_changeMyPasswordRequest, options?: any) {
        return AuthenticatedApiImplApiFp(this.configuration).changeMyPasswordUsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary currentUser
     * @param {IAuthenticatedApiImplApi_currentUserRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedApiImplApi
     */
    public currentUserUsingGET(request: IAuthenticatedApiImplApi_currentUserRequest, options?: any) {
        return AuthenticatedApiImplApiFp(this.configuration).currentUserUsingGET(request, options)(this.fetch, this.basePath);
    }

}







export interface IIncidentAnonymousApiImplApi_reportRequest {


	
    body: AnonymousIncidentReportCommand,

}





export interface IIncidentAnonymousApiImplApi_viewPathParams {
    // accessToken
    accessToken: string, 
}    

export interface IIncidentAnonymousApiImplApi_viewRequest {


	

    path: IIncidentAnonymousApiImplApi_viewPathParams,
}




/**
 * IncidentAnonymousApiImplApi - fetch parameter creator
 * @export
 */
export const IncidentAnonymousApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary report
         * @param {IIncidentAnonymousApiImplApi_reportRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUsingPOST(request: IIncidentAnonymousApiImplApi_reportRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/anonymous/incident/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary view
         * @param {IIncidentAnonymousApiImplApi_viewRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewUsingGET(request: IIncidentAnonymousApiImplApi_viewRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/anonymous/incident/view/{accessToken}`
                .replace(`{${"accessToken"}}`, encodeURIComponent(String(request.path.accessToken)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * IncidentAnonymousApiImplApi - functional programming interface
 * @export
 */
export const IncidentAnonymousApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary report
         * @param {IIncidentAnonymousApiImplApi_reportRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUsingPOST(request: IIncidentAnonymousApiImplApi_reportRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentReportRegisteredDto> {
            const localVarFetchArgs = IncidentAnonymousApiImplApiFetchParamCreator(configuration).reportUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary view
         * @param {IIncidentAnonymousApiImplApi_viewRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewUsingGET(request: IIncidentAnonymousApiImplApi_viewRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentShortDto> {
            const localVarFetchArgs = IncidentAnonymousApiImplApiFetchParamCreator(configuration).viewUsingGET(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * IncidentAnonymousApiImplApi - factory interface
 * @export
 */
export const IncidentAnonymousApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary report
         * @param {IIncidentAnonymousApiImplApi_reportRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUsingPOST(request: IIncidentAnonymousApiImplApi_reportRequest, options?: any) {
            return IncidentAnonymousApiImplApiFp(configuration).reportUsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary view
         * @param {IIncidentAnonymousApiImplApi_viewRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewUsingGET(request: IIncidentAnonymousApiImplApi_viewRequest, options?: any) {
            return IncidentAnonymousApiImplApiFp(configuration).viewUsingGET(request, options)(fetch, basePath);
        },
    };
};

/**
 * IncidentAnonymousApiImplApi - object-oriented interface
 * @export
 * @class IncidentAnonymousApiImplApi
 * @extends {BaseAPI}
 */
export class IncidentAnonymousApiImplApi extends BaseAPI {
    /**
     * 
     * @summary report
     * @param {IIncidentAnonymousApiImplApi_reportRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentAnonymousApiImplApi
     */
    public reportUsingPOST(request: IIncidentAnonymousApiImplApi_reportRequest, options?: any) {
        return IncidentAnonymousApiImplApiFp(this.configuration).reportUsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary view
     * @param {IIncidentAnonymousApiImplApi_viewRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentAnonymousApiImplApi
     */
    public viewUsingGET(request: IIncidentAnonymousApiImplApi_viewRequest, options?: any) {
        return IncidentAnonymousApiImplApiFp(this.configuration).viewUsingGET(request, options)(this.fetch, this.basePath);
    }

}







export interface IIncidentOperatorApiImplApi_addNoteRequest {


	
    body: IncidentTaskNoteCreateCommand,

}






export interface IIncidentOperatorApiImplApi_addTaskRequest {


	
    body: IncidentTaskCreateCommand,

}






export interface IIncidentOperatorApiImplApi_changeStatusRequest {


	
    body: IncidentStatusUpdateCommand,

}





export interface IIncidentOperatorApiImplApi_incidentPathParams {
    // incidentId
    incidentId: string, 
}    

export interface IIncidentOperatorApiImplApi_incidentRequest {


	

    path: IIncidentOperatorApiImplApi_incidentPathParams,
}


export interface IIncidentOperatorApiImplApi_listQueryParams {
    // assignedOperator
    assignedOperator?: string, 
    // latitude
    latitude?: number, 
    // longitude
    longitude?: number, 
    // noOperatorAssigned
    noOperatorAssigned?: boolean, 
    // pageIdx
    pageIdx?: number, 
    // radiusInM
    radiusInM?: number, 
    // reportedBy
    reportedBy?: string, 
    // status
    status?: string, 
}




export interface IIncidentOperatorApiImplApi_listRequest {
    query: IIncidentOperatorApiImplApi_listQueryParams,


	

}





export interface IIncidentOperatorApiImplApi_loadTasksPathParams {
    // incidentId
    incidentId: string, 
}    

export interface IIncidentOperatorApiImplApi_loadTasksRequest {


	

    path: IIncidentOperatorApiImplApi_loadTasksPathParams,
}






export interface IIncidentOperatorApiImplApi_registerRequest {


	
    body: AnonymousIncidentReportCommand,

}





export interface IIncidentOperatorApiImplApi_toogleInspectionPathParams {
    // incidentId
    incidentId: string, 
}    

export interface IIncidentOperatorApiImplApi_toogleInspectionRequest {


	

    path: IIncidentOperatorApiImplApi_toogleInspectionPathParams,
}




/**
 * IncidentOperatorApiImplApi - fetch parameter creator
 * @export
 */
export const IncidentOperatorApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addNote
         * @param {IIncidentOperatorApiImplApi_addNoteRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNoteUsingPOST(request: IIncidentOperatorApiImplApi_addNoteRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/operator/incident/addNote`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addTask
         * @param {IIncidentOperatorApiImplApi_addTaskRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskUsingPOST(request: IIncidentOperatorApiImplApi_addTaskRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/operator/incident/addTask`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changeStatus
         * @param {IIncidentOperatorApiImplApi_changeStatusRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST(request: IIncidentOperatorApiImplApi_changeStatusRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/operator/incident/changeStatus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary incident
         * @param {IIncidentOperatorApiImplApi_incidentRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incidentUsingGET(request: IIncidentOperatorApiImplApi_incidentRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/operator/incident/{incidentId}`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(request.path.incidentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {IIncidentOperatorApiImplApi_listRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET(request: IIncidentOperatorApiImplApi_listRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/operator/incident/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (request.query.assignedOperator !== undefined) {
                localVarQueryParameter['assignedOperator'] = request.query.assignedOperator;
            }

            if (request.query.latitude !== undefined) {
                localVarQueryParameter['latitude'] = request.query.latitude;
            }

            if (request.query.longitude !== undefined) {
                localVarQueryParameter['longitude'] = request.query.longitude;
            }

            if (request.query.noOperatorAssigned !== undefined) {
                localVarQueryParameter['noOperatorAssigned'] = request.query.noOperatorAssigned;
            }

            if (request.query.pageIdx !== undefined) {
                localVarQueryParameter['pageIdx'] = request.query.pageIdx;
            }

            if (request.query.radiusInM !== undefined) {
                localVarQueryParameter['radiusInM'] = request.query.radiusInM;
            }

            if (request.query.reportedBy !== undefined) {
                localVarQueryParameter['reportedBy'] = request.query.reportedBy;
            }

            if (request.query.status !== undefined) {
                localVarQueryParameter['status'] = request.query.status;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loadTasks
         * @param {IIncidentOperatorApiImplApi_loadTasksRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTasksUsingGET(request: IIncidentOperatorApiImplApi_loadTasksRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/operator/incident/{incidentId}/task/list`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(request.path.incidentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register
         * @param {IIncidentOperatorApiImplApi_registerRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUsingPOST(request: IIncidentOperatorApiImplApi_registerRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/operator/incident/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary toogleInspection
         * @param {IIncidentOperatorApiImplApi_toogleInspectionRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toogleInspectionUsingPOST(request: IIncidentOperatorApiImplApi_toogleInspectionRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/operator/incident/{incidentId}/toogleInspection`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(request.path.incidentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * IncidentOperatorApiImplApi - functional programming interface
 * @export
 */
export const IncidentOperatorApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addNote
         * @param {IIncidentOperatorApiImplApi_addNoteRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNoteUsingPOST(request: IIncidentOperatorApiImplApi_addNoteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentTaskNoteAddedRespDto> {
            const localVarFetchArgs = IncidentOperatorApiImplApiFetchParamCreator(configuration).addNoteUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary addTask
         * @param {IIncidentOperatorApiImplApi_addTaskRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskUsingPOST(request: IIncidentOperatorApiImplApi_addTaskRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentTaskCreateRespDto> {
            const localVarFetchArgs = IncidentOperatorApiImplApiFetchParamCreator(configuration).addTaskUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary changeStatus
         * @param {IIncidentOperatorApiImplApi_changeStatusRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST(request: IIncidentOperatorApiImplApi_changeStatusRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentDocument> {
            const localVarFetchArgs = IncidentOperatorApiImplApiFetchParamCreator(configuration).changeStatusUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary incident
         * @param {IIncidentOperatorApiImplApi_incidentRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incidentUsingGET(request: IIncidentOperatorApiImplApi_incidentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentDocument> {
            const localVarFetchArgs = IncidentOperatorApiImplApiFetchParamCreator(configuration).incidentUsingGET(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary list
         * @param {IIncidentOperatorApiImplApi_listRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET(request: IIncidentOperatorApiImplApi_listRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageDtoIncidentDocument> {
            const localVarFetchArgs = IncidentOperatorApiImplApiFetchParamCreator(configuration).listUsingGET(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary loadTasks
         * @param {IIncidentOperatorApiImplApi_loadTasksRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTasksUsingGET(request: IIncidentOperatorApiImplApi_loadTasksRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IncidentTaskDocument>> {
            const localVarFetchArgs = IncidentOperatorApiImplApiFetchParamCreator(configuration).loadTasksUsingGET(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary register
         * @param {IIncidentOperatorApiImplApi_registerRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUsingPOST(request: IIncidentOperatorApiImplApi_registerRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentReportRegisteredDto> {
            const localVarFetchArgs = IncidentOperatorApiImplApiFetchParamCreator(configuration).registerUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary toogleInspection
         * @param {IIncidentOperatorApiImplApi_toogleInspectionRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toogleInspectionUsingPOST(request: IIncidentOperatorApiImplApi_toogleInspectionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentDocument> {
            const localVarFetchArgs = IncidentOperatorApiImplApiFetchParamCreator(configuration).toogleInspectionUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * IncidentOperatorApiImplApi - factory interface
 * @export
 */
export const IncidentOperatorApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addNote
         * @param {IIncidentOperatorApiImplApi_addNoteRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNoteUsingPOST(request: IIncidentOperatorApiImplApi_addNoteRequest, options?: any) {
            return IncidentOperatorApiImplApiFp(configuration).addNoteUsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary addTask
         * @param {IIncidentOperatorApiImplApi_addTaskRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskUsingPOST(request: IIncidentOperatorApiImplApi_addTaskRequest, options?: any) {
            return IncidentOperatorApiImplApiFp(configuration).addTaskUsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary changeStatus
         * @param {IIncidentOperatorApiImplApi_changeStatusRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST(request: IIncidentOperatorApiImplApi_changeStatusRequest, options?: any) {
            return IncidentOperatorApiImplApiFp(configuration).changeStatusUsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary incident
         * @param {IIncidentOperatorApiImplApi_incidentRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incidentUsingGET(request: IIncidentOperatorApiImplApi_incidentRequest, options?: any) {
            return IncidentOperatorApiImplApiFp(configuration).incidentUsingGET(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {IIncidentOperatorApiImplApi_listRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET(request: IIncidentOperatorApiImplApi_listRequest, options?: any) {
            return IncidentOperatorApiImplApiFp(configuration).listUsingGET(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary loadTasks
         * @param {IIncidentOperatorApiImplApi_loadTasksRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTasksUsingGET(request: IIncidentOperatorApiImplApi_loadTasksRequest, options?: any) {
            return IncidentOperatorApiImplApiFp(configuration).loadTasksUsingGET(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary register
         * @param {IIncidentOperatorApiImplApi_registerRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUsingPOST(request: IIncidentOperatorApiImplApi_registerRequest, options?: any) {
            return IncidentOperatorApiImplApiFp(configuration).registerUsingPOST(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary toogleInspection
         * @param {IIncidentOperatorApiImplApi_toogleInspectionRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toogleInspectionUsingPOST(request: IIncidentOperatorApiImplApi_toogleInspectionRequest, options?: any) {
            return IncidentOperatorApiImplApiFp(configuration).toogleInspectionUsingPOST(request, options)(fetch, basePath);
        },
    };
};

/**
 * IncidentOperatorApiImplApi - object-oriented interface
 * @export
 * @class IncidentOperatorApiImplApi
 * @extends {BaseAPI}
 */
export class IncidentOperatorApiImplApi extends BaseAPI {
    /**
     * 
     * @summary addNote
     * @param {IIncidentOperatorApiImplApi_addNoteRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentOperatorApiImplApi
     */
    public addNoteUsingPOST(request: IIncidentOperatorApiImplApi_addNoteRequest, options?: any) {
        return IncidentOperatorApiImplApiFp(this.configuration).addNoteUsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary addTask
     * @param {IIncidentOperatorApiImplApi_addTaskRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentOperatorApiImplApi
     */
    public addTaskUsingPOST(request: IIncidentOperatorApiImplApi_addTaskRequest, options?: any) {
        return IncidentOperatorApiImplApiFp(this.configuration).addTaskUsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary changeStatus
     * @param {IIncidentOperatorApiImplApi_changeStatusRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentOperatorApiImplApi
     */
    public changeStatusUsingPOST(request: IIncidentOperatorApiImplApi_changeStatusRequest, options?: any) {
        return IncidentOperatorApiImplApiFp(this.configuration).changeStatusUsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary incident
     * @param {IIncidentOperatorApiImplApi_incidentRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentOperatorApiImplApi
     */
    public incidentUsingGET(request: IIncidentOperatorApiImplApi_incidentRequest, options?: any) {
        return IncidentOperatorApiImplApiFp(this.configuration).incidentUsingGET(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {IIncidentOperatorApiImplApi_listRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentOperatorApiImplApi
     */
    public listUsingGET(request: IIncidentOperatorApiImplApi_listRequest, options?: any) {
        return IncidentOperatorApiImplApiFp(this.configuration).listUsingGET(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary loadTasks
     * @param {IIncidentOperatorApiImplApi_loadTasksRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentOperatorApiImplApi
     */
    public loadTasksUsingGET(request: IIncidentOperatorApiImplApi_loadTasksRequest, options?: any) {
        return IncidentOperatorApiImplApiFp(this.configuration).loadTasksUsingGET(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary register
     * @param {IIncidentOperatorApiImplApi_registerRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentOperatorApiImplApi
     */
    public registerUsingPOST(request: IIncidentOperatorApiImplApi_registerRequest, options?: any) {
        return IncidentOperatorApiImplApiFp(this.configuration).registerUsingPOST(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary toogleInspection
     * @param {IIncidentOperatorApiImplApi_toogleInspectionRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentOperatorApiImplApi
     */
    public toogleInspectionUsingPOST(request: IIncidentOperatorApiImplApi_toogleInspectionRequest, options?: any) {
        return IncidentOperatorApiImplApiFp(this.configuration).toogleInspectionUsingPOST(request, options)(this.fetch, this.basePath);
    }

}







export interface IIncidentSuperOperatorApiImplApi_assignOperatorRequest {


	
    body: IncidentOperatorAssignCommand,

}




/**
 * IncidentSuperOperatorApiImplApi - fetch parameter creator
 * @export
 */
export const IncidentSuperOperatorApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary assignOperator
         * @param {IIncidentSuperOperatorApiImplApi_assignOperatorRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignOperatorUsingPOST(request: IIncidentSuperOperatorApiImplApi_assignOperatorRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/superOperator/incident/assignOperator`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * IncidentSuperOperatorApiImplApi - functional programming interface
 * @export
 */
export const IncidentSuperOperatorApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary assignOperator
         * @param {IIncidentSuperOperatorApiImplApi_assignOperatorRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignOperatorUsingPOST(request: IIncidentSuperOperatorApiImplApi_assignOperatorRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentDocument> {
            const localVarFetchArgs = IncidentSuperOperatorApiImplApiFetchParamCreator(configuration).assignOperatorUsingPOST(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * IncidentSuperOperatorApiImplApi - factory interface
 * @export
 */
export const IncidentSuperOperatorApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary assignOperator
         * @param {IIncidentSuperOperatorApiImplApi_assignOperatorRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignOperatorUsingPOST(request: IIncidentSuperOperatorApiImplApi_assignOperatorRequest, options?: any) {
            return IncidentSuperOperatorApiImplApiFp(configuration).assignOperatorUsingPOST(request, options)(fetch, basePath);
        },
    };
};

/**
 * IncidentSuperOperatorApiImplApi - object-oriented interface
 * @export
 * @class IncidentSuperOperatorApiImplApi
 * @extends {BaseAPI}
 */
export class IncidentSuperOperatorApiImplApi extends BaseAPI {
    /**
     * 
     * @summary assignOperator
     * @param {IIncidentSuperOperatorApiImplApi_assignOperatorRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentSuperOperatorApiImplApi
     */
    public assignOperatorUsingPOST(request: IIncidentSuperOperatorApiImplApi_assignOperatorRequest, options?: any) {
        return IncidentSuperOperatorApiImplApiFp(this.configuration).assignOperatorUsingPOST(request, options)(this.fetch, this.basePath);
    }

}



export interface IIncidentUserApiImplApi_listQueryParams {
    // pageIdx
    pageIdx?: number, 
}




export interface IIncidentUserApiImplApi_listRequest {
    query: IIncidentUserApiImplApi_listQueryParams,


	

}






export interface IIncidentUserApiImplApi_reportRequest {


	
    body: UserIncidentReportCommand,

}





export interface IIncidentUserApiImplApi_viewPathParams {
    // incidentId
    incidentId: string, 
}    

export interface IIncidentUserApiImplApi_viewRequest {


	

    path: IIncidentUserApiImplApi_viewPathParams,
}




/**
 * IncidentUserApiImplApi - fetch parameter creator
 * @export
 */
export const IncidentUserApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list
         * @param {IIncidentUserApiImplApi_listRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET1(request: IIncidentUserApiImplApi_listRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/user/incident/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (request.query.pageIdx !== undefined) {
                localVarQueryParameter['pageIdx'] = request.query.pageIdx;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary report
         * @param {IIncidentUserApiImplApi_reportRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUsingPOST1(request: IIncidentUserApiImplApi_reportRequest, options: any = {}): FetchArgs {
	
            // verify required parameter 'request.body' is not null or undefined
            if (request.body === null || request.body === undefined) {
                throw new RequiredError('request.body','Required parameter request.body was null or undefined when calling request.body.');
            }
	
	
	

            const localVarPath = `/api/user/incident/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof request.body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(request.body || {}) : (request.body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary view
         * @param {IIncidentUserApiImplApi_viewRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewUsingGET1(request: IIncidentUserApiImplApi_viewRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/user/incident/{incidentId}`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(request.path.incidentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * IncidentUserApiImplApi - functional programming interface
 * @export
 */
export const IncidentUserApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list
         * @param {IIncidentUserApiImplApi_listRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET1(request: IIncidentUserApiImplApi_listRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageDtoIncidentShortDto> {
            const localVarFetchArgs = IncidentUserApiImplApiFetchParamCreator(configuration).listUsingGET1(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary report
         * @param {IIncidentUserApiImplApi_reportRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUsingPOST1(request: IIncidentUserApiImplApi_reportRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentReportRegisteredDto> {
            const localVarFetchArgs = IncidentUserApiImplApiFetchParamCreator(configuration).reportUsingPOST1(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
        /**
         * 
         * @summary view
         * @param {IIncidentUserApiImplApi_viewRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewUsingGET1(request: IIncidentUserApiImplApi_viewRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IncidentShortDto> {
            const localVarFetchArgs = IncidentUserApiImplApiFetchParamCreator(configuration).viewUsingGET1(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * IncidentUserApiImplApi - factory interface
 * @export
 */
export const IncidentUserApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary list
         * @param {IIncidentUserApiImplApi_listRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET1(request: IIncidentUserApiImplApi_listRequest, options?: any) {
            return IncidentUserApiImplApiFp(configuration).listUsingGET1(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary report
         * @param {IIncidentUserApiImplApi_reportRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUsingPOST1(request: IIncidentUserApiImplApi_reportRequest, options?: any) {
            return IncidentUserApiImplApiFp(configuration).reportUsingPOST1(request, options)(fetch, basePath);
        },
        /**
         * 
         * @summary view
         * @param {IIncidentUserApiImplApi_viewRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewUsingGET1(request: IIncidentUserApiImplApi_viewRequest, options?: any) {
            return IncidentUserApiImplApiFp(configuration).viewUsingGET1(request, options)(fetch, basePath);
        },
    };
};

/**
 * IncidentUserApiImplApi - object-oriented interface
 * @export
 * @class IncidentUserApiImplApi
 * @extends {BaseAPI}
 */
export class IncidentUserApiImplApi extends BaseAPI {
    /**
     * 
     * @summary list
     * @param {IIncidentUserApiImplApi_listRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentUserApiImplApi
     */
    public listUsingGET1(request: IIncidentUserApiImplApi_listRequest, options?: any) {
        return IncidentUserApiImplApiFp(this.configuration).listUsingGET1(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary report
     * @param {IIncidentUserApiImplApi_reportRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentUserApiImplApi
     */
    public reportUsingPOST1(request: IIncidentUserApiImplApi_reportRequest, options?: any) {
        return IncidentUserApiImplApiFp(this.configuration).reportUsingPOST1(request, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary view
     * @param {IIncidentUserApiImplApi_viewRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentUserApiImplApi
     */
    public viewUsingGET1(request: IIncidentUserApiImplApi_viewRequest, options?: any) {
        return IncidentUserApiImplApiFp(this.configuration).viewUsingGET1(request, options)(this.fetch, this.basePath);
    }

}







export interface ISuperOperatorApiImplApi_listRequest {


	

}




/**
 * SuperOperatorApiImplApi - fetch parameter creator
 * @export
 */
export const SuperOperatorApiImplApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list
         * @param {ISuperOperatorApiImplApi_listRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET2(request: ISuperOperatorApiImplApi_listRequest, options: any = {}): FetchArgs {
	
	
	
	

            const localVarPath = `/api/superOperator/operator/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    } 
};

/**
 * SuperOperatorApiImplApi - functional programming interface
 * @export
 */
export const SuperOperatorApiImplApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list
         * @param {ISuperOperatorApiImplApi_listRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET2(request: ISuperOperatorApiImplApi_listRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AccountDocument>> {
            const localVarFetchArgs = SuperOperatorApiImplApiFetchParamCreator(configuration).listUsingGET2(request, options);
            return (fetch?: FetchAPI, basePath?: string) => {
                const path = basePath ?? configuration?.basePath ?? BASE_PATH;
                const fetchFn = fetch ?? defaultFetch;
                return fetchFn(path + localVarFetchArgs.url, localVarFetchArgs.options)
                    .then(identityWait(configuration?.debug?.apiDelay))
                    .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    });
            };
        },
    }
};

/**
 * SuperOperatorApiImplApi - factory interface
 * @export
 */
export const SuperOperatorApiImplApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary list
         * @param {ISuperOperatorApiImplApi_listRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET2(request: ISuperOperatorApiImplApi_listRequest, options?: any) {
            return SuperOperatorApiImplApiFp(configuration).listUsingGET2(request, options)(fetch, basePath);
        },
    };
};

/**
 * SuperOperatorApiImplApi - object-oriented interface
 * @export
 * @class SuperOperatorApiImplApi
 * @extends {BaseAPI}
 */
export class SuperOperatorApiImplApi extends BaseAPI {
    /**
     * 
     * @summary list
     * @param {ISuperOperatorApiImplApi_listRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuperOperatorApiImplApi
     */
    public listUsingGET2(request: ISuperOperatorApiImplApi_listRequest, options?: any) {
        return SuperOperatorApiImplApiFp(this.configuration).listUsingGET2(request, options)(this.fetch, this.basePath);
    }

}

